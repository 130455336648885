import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import HeroImgQueen from '../../Assets/Images/hero-bg.jpg';
import HeroImgDavid from '../../Assets/Images/david.jpeg';
// import HeroImgColdplay from "../../Assets/Images/coldplay-hero.webp";
import HeroImgColdplay from '../../Assets/Images/coldplay3.0.jpg';
import { useContext } from 'react';
import { LanguageContext } from '../../Context/language';
import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';

const Hero: React.FC = (): JSX.Element => {
	const { language } = useContext(LanguageContext);
	const AutoplaySlider = withAutoplay(AwesomeSlider);
	const navigate = useNavigate();

	return (
		<div className="hero" id="hero">
			<AutoplaySlider
				play={true}
				cancelOnInteraction={true} // should stop playing on user interaction
				interval={6000}
				className="hero"
				animation="cubeAnimation"
			>
				{/* <div
					className="hero__content"
					style={{
						background: `linear-gradient(110deg, rgba(23, 136, 141, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
            rgba(160, 78, 49, 0.85) 85%), url(${HeroImgQueen}) no-repeat center center/cover`,
					}}
				>
					<div className="hero__copy">
						<h1>{parse(`${language?.heroHeadings?.heroOne}`)}</h1>
					</div>
					<div className="hero__button-wrapper">
						<a href="http://app.staccs.com">
							<button
								className="hero__cta-button"
								style={{
									fontFamily: 'Poppins',
								}}
							>
								{language.discover}
							</button>
						</a>
					</div>
				</div> */}
				<div
					className="hero__content"
					style={{
						background: `linear-gradient(110deg, rgba(160, 78, 49, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
            rgba(160, 78, 49, 0.85) 85%), url(${HeroImgDavid}) no-repeat center center/cover`,
					}}
				>
					<div className="hero__copy">
						<h1>{parse(`${language?.heroHeadings?.heroTwo}`)}</h1>
					</div>
					<div className="hero__button-wrapper">
						<a href="http://app.staccs.com">
							;
							<button
								className="hero__cta-button"
								style={{
									fontFamily: 'Poppins',
								}}
							>
								{language.discover}
							</button>
						</a>
					</div>
				</div>
				<div
					className="hero__content"
					style={{
						background: `linear-gradient(110deg, rgba(66, 225, 66, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
            rgba(217, 69, 69, 0.85) 85%), url(${HeroImgColdplay}) no-repeat center center/cover`,
					}}
				>
					<div className="hero__copy">
						<h1>{parse(`${language?.heroHeadings?.heroThree}`)}</h1>
					</div>
					<div className="hero__button-wrapper">
						<a href="http://app.staccs.com">
							<button
								className="hero__cta-button"
								style={{
									fontFamily: 'Poppins',
								}}
							>
								{language.discover}
							</button>
						</a>
					</div>
				</div>
			</AutoplaySlider>
		</div>
	);
};

export default Hero;
