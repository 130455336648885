const Terms: React.FC = (): JSX.Element => {
	return (
		<main
			style={{
				paddingTop: '120px',
				paddingBottom: '80px',
				color: 'black',
				maxWidth: '600px',
				// fontSize: '12px',
				margin: 'auto',
				textAlign: 'left',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<h1 style={{ textAlign: 'center', color: '#121212', fontSize: '24px' }}>
				Terms &amp; Conditions
			</h1>
			<p>
				<strong>Last updated</strong>: 21 March 2023
			</p>
			<p>
				<strong>Name: </strong>World of Staccs AB
			</p>
			<p>
				<strong>Registration number:</strong> 559135-5473
			</p>
			<p>
				<strong>Address</strong>: Livdjursgatan 4, 121 62 Johanneshov
				(Stockholm), Sweden
			</p>
			<p>
				<strong>Support email:</strong>
				<span style={{ color: '#0b4cb4' }}>support@staccs.com</span>
			</p>
			<p>
				By accessing the website{' '}
				<a href="https://staccs.com/" target="_blank" rel="noopener">
					<span style={{ color: '#0b4cb4' }}>https://staccs.com</span>/
				</a>{' '}
				or{' '}
				<a href="https://app.staccs.com/" target="_blank" rel="noopener">
					<span style={{ color: '#0b4cb4' }}>https://app.staccs.com/</span>
				</a>{' '}
				or Staccs TV (“Site”) and/or our mobile application (“Application”)
				(individually and collectively the “<strong>Platform</strong>”) and
				using the services (together with the Site and/or the Application,
				hereinafter referred to as the “<strong>Service</strong>”) offered by
				World of Staccs AB (“<strong>Staccs</strong>”, “<strong>we</strong>”, or
				“<strong>us</strong>”) you are agreeing to the legally binding rules
				described below (the “<strong>Terms</strong>”). You are also agreeing to
				our{' '}
				<a href="https://staccs.com/privacy" target="_blank" rel="noopener">
					<span style={{ color: '#0b4cb4' }}>Privacy Policy</span>
				</a>{' '}
				and Cookie Policy regarding security, confidentiality, and what we do
				with the data you provide us or is collected by us. The Privacy Policy
				and Cookie Policy constitute an integral part of the Terms.
			</p>
			<p>
				The Application is offered via (i) the Android Market and on other
				Android applications stores (the "<strong>Android Store</strong>"), (ii)
				the Apple App Store through the iTunes Store ("
				<strong>Apple Store</strong>"), and/or (iii) any other similar
				application store(s) owned and operated by third parties where the
				Service is made available by us ("<strong>Other Stores</strong>").The
				Android Store, the Apple Store, and the Other Stores shall be referred
				to thereafter as the "<strong>Store(s)</strong>".These Terms are a
				legally binding agreement between you and us, and not with any
				third-party Store(s) or third-party platform distributors or any of
				their affiliates.
			</p>
			<p>
				We may make changes to these Terms at any time. Any changes will be
				effective immediately when we post a revised version of these Terms on
				the Site. The Terms were last updated on the date above stated and apply
				to all purchases made on or after this date.
			</p>
			<p>
				You understand and agree that to use the Service you must be 18 years of
				age or older or have your parent or guardian’s consent to sign up for a
				subscription.
			</p>
			<p>
				1.&nbsp; <strong>Description of the Service</strong>
			</p>
			<p>
				1.&nbsp; Once you have accessed the Service via the Site and/or
				downloaded the Application and via a Store or when using the Platform
				for the ﬁrst time, you will be asked to sign up for an account (or for
				an account with the Store or the third-party platform through which the
				Platform is made available to you) or to login with your existing
				account, as applicable. In order to create an account, you must enter
				your payment information (unless subscribing to a free trial offer or
				the “Member” tier) and select a subscription plan ("Subscription").
			</p>
			<p>
				2.&nbsp; If you have created an account with free access to the service
				for a certain limited period of time (“Free Trial Period”) and provided
				payment details, this free access to the Service will allow you to
				stream and watch a limited number of full length long-form videos
				available for streaming during such Free Trial Period. Unless you cancel
				your subscription before the Free Trial Period ends, once the Free Trial
				Period ends, you will be charged the applicable subscription price for
				the subscription period (each, a "Subscription Period") that you
				selected when you created your account, which will give you access to
				the Service and more speciﬁcally, to streaming of the full catalogue of
				long-form videos available on the Service in your country.
			</p>
			<p>
				3.&nbsp; The Subscription shall remain effective and you shall pay the
				applicable amount indicated up until (i) you cancel your Subscription as
				set forth below in Section 5, or (ii) this Agreement is terminated by
				Staccs in accordance with this Agreement. Notwithstanding the foregoing,
				if you access the Service using a promo code or gift, your Subscription
				shall remain effective until the applicable Subscription Period expires,
				or this Agreement is terminated by Staccs in accordance with this
				Agreement.
			</p>
			<p>
				4.&nbsp; When you have decided to create an account and sign up for a
				subscription, you will be asked to enter your email address, card
				details. Please read our Privacy Policy regarding security,
				confidentiality, and what we do with the data you provide us.
			</p>
			<p>
				5.&nbsp; It is your obligation to make sure that your viewing device and
				system meet the applicable minimum technical requirements (which can be
				found{' '}
				<a
					href="https://support.staccs.com/en/articles/5908134-devices-apps"
					target="_blank"
					rel="noopener"
				>
					here)
				</a>
				. Please make sure that your web browser version and operating system is
				always up to date.
			</p>
			<p>
				6.&nbsp; The Service is currently available for use within Sweden,
				Norway, Denmark, Finland, Iceland, Estonia, Latvia and Lithuania.
			</p>
			<p>
				2.&nbsp; <strong>Use of the Service on your Devices</strong>
			</p>
			<p>
				1.&nbsp; The Service is intended for private use by individuals on
				mobile phones, tablets and computers (collectively “
				<strong>Device(s)</strong>”). In addition to the specific Device(s) that
				you own or control and on which you have the Platform or with which you
				access the Service via the Site, the Service may also be accessible
				through other Devices that you own or control, subject to the terms of
				use of the applicable Store ("
				<strong>Store Terms and Conditions"),</strong> and provided that you may
				be able to access the Service only from one (1) Device at a time.
				Although the Service may be accessible through another device that you
				own or control, the Platform and the Service may not have been designed
				for such a device, and therefore the quality of the Platform and the
				Service may be altered, and some features may not be available.
			</p>
			<p>
				2.&nbsp; When using the Service for the ﬁrst time on any such other
				Devices that you own or control, you will be required to download the
				Platform on such device from the Store (or when applicable, to
				synchronize such device with your computer in accordance with the
				applicable Store Terms and Conditions), unless you access the Service
				via the Site. You will also be required to authenticate yourself by
				providing your account information or via any other authentication
				method provided by Staccs, if any. Once you have accessed the Service
				through a Device, such Device may be automatically granted access to the
				Service thereafter without the need to re-enter such information. If one
				of your Devices is accessing the Service while you are trying to access
				the Service with another Device, you may be asked to select the Device
				through which you wish to access the Service and the other active
				session will be automatically closed.
			</p>
			<p>
				3.&nbsp; <strong>Eligibility</strong>
			</p>
			<p>
				1.&nbsp; The Service is only available for individuals aged 18 years or
				older. If you are 18 years old or older but have not reached the age of
				maturity in the jurisdiction where you reside, please review this
				Agreement with your parent or guardian. By signing up for an account
				with the Service or by using the Service, you represent and warrant that
				you are aged 18 years or older, and that you agree to be bound by the
				terms and conditions of this Agreement. If you are under 18, you must
				have a parent or legal guardian involved with your use of the Shop.
			</p>
			<p>
				4.&nbsp; <strong>Payment</strong>
			</p>
			<p>
				1.&nbsp; You agree to pay Staccs or the Store, as applicable, the
				Subscription fees applicable to the Subscription Period you select for
				the Service, plus any applicable taxes.
			</p>
			<p>
				2.&nbsp; If paid to Staccs, you agree to use our payment solution
				service, currently provided by{' '}
				<a href="https://stripe.com/" target="_blank" rel="noopener">
					Stripe
				</a>
				<span style={{ color: '#ff0000' }}>. </span>We accept all major debit
				and credit cards for payments.
			</p>
			<p>
				3.&nbsp; The currency per Subscription may vary depending on your
				country.
			</p>
			<p>
				4.&nbsp; Any local tax, inclusive but not limited to VAT, might be
				applied to the purchase in accordance with applicable law of the country
				where you reside.
			</p>
			<p>
				5.&nbsp; Upon purchase of a recurring Subscription, the applicable
				Subscription fees and taxes will be charged to your account on a
				recurrent basis, unless and until you cancel your Subscription by
				accessing your account (www.staccs.com/account) and modifying the
				"Settings" for such account.
			</p>
			<p>
				6.&nbsp; Upon purchase of a recurring Subscription through a Store, the
				applicable Subscription fees and taxes will be charged to your account
				with such Store on a recurrent basis, unless and until you deactivate
				the auto-renewal option. In order to deactivate the auto-renewal option,
				you must access your account settings or must do as otherwise provided
				in the Store Terms and Conditions. Unless you turn off the auto-renewal
				option within the timeframe required by the Store Terms and Conditions,
				your Subscription to the Service will be renewed successively for the
				same Subscription Period and at the same price.
			</p>
			<p>
				5.&nbsp; <strong>Cancellation &amp; Refund Policy</strong>
			</p>
			<p>
				1.&nbsp; Under the{' '}
				<a
					href="https://www.konsumentverket.se/languages/english-engelska/"
					target="_blank"
					rel="noopener"
				>
					Consumer Protection Act
				</a>
				, private individuals have, as a general rule, the right to cancel an
				order by notifying our customer support at{' '}
				<a href="mailto:support@staccs.com" target="_blank" rel="noopener">
					support@staccs.com
				</a>{' '}
				within 14 days of signing the agreement or receiving the order. However,
				if you choose to use your right to cancel your order, we will deduct the
				amount equal to the number of days you have been able to access the
				Service, based on the first date when you logged into the Service. For
				example, if you logged into the Service the same day as you placed your
				order, then you cancel your order after 3 days, we will deduct 3/30 of
				your monthly subscription fee. For annual subscription the example will
				be, you logged into the Service the same day as you placed your order,
				then you cancel your order after 3 days, we will deduct 3/360 of your
				annual subscription fee.However, the user does not have the
				aforementioned right of withdrawal in respect of the Service if the
				electronic provision of the Service is begun with the user’s consent
				before the end of the withdrawal period.
			</p>
			<p>
				2.&nbsp; Unless your Subscription has been purchased for a certain
				pre-paid period, your payment to us or the Store through which you
				purchased the Subscription will automatically renew at the end of the
				applicable subscription period, unless you cancel your Subscription
				before the end of the then-current subscription period by accessing your{' '}
				<a href="http://www.staccs.com/account" target="_blank" rel="noopener">
					account page
				</a>{' '}
				if you signed up for the Subscription directly through Staccs, or if you
				purchased the Subscription through a Store, by canceling the
				Subscription through such Store. The cancellation will take effect the
				day after the last day of the current subscription period, and you will
				no longer have access to the Service. We do not provide refunds or
				credits for any partial subscription periods.
			</p>
			<p>
				3.&nbsp; We can’t refund payments for Subscriptions made through a
				Store. You can contact the Store directly for refund requests. (See
				their details on your{' '}
				<a href="http://www.staccs.com/account" target="_blank" rel="noopener">
					account page
				</a>
				, under your subscription plan.)
			</p>
			<p>
				4. We reserve the right to cancel your Subscription and suspend your
				access to the Service if we do not receive payment from you on time and
				in accordance with your plan.
			</p>
			<p>
				6.&nbsp; <strong>Rules of Conduct</strong>
			</p>
			<p>1.&nbsp; You agree and warrant that you will not:</p>
			<ul>
				<li>
					alter, change, copy, reproduce, record, transfer, perform, display,
					transmit or make available any part of the Service, including the
					Staccs TV app, to the public (including but not limited to displaying
					the Service, or any content of the Service, at private gatherings or
					in bars, restaurants and other public places) or otherwise use the
					Service in any way that is not explicitly permitted under the Terms or
					applicable law, or otherwise constitutes an infringement of any part
					of the Service;
				</li>
				<li>
					bypass any security measures, engage in any systematic reading or
					listening, use any kind of software or device to “crawl” or spider any
					part of the Service, reverse engineer (or similar actions) any aspect
					of the Service to access source codes, algorithms and similar, or
					otherwise interfere with the security or functionality of the Service;
				</li>
				<li>
					use the Service for commercial purposes, for example by selling,
					reselling or sublicensing any part of the Service or by publishing on
					the Site announcements or advertisement of products or services,
					marketing campaigns, contests, lottery or pyramid schemes;
				</li>
				<li>
					<span style={{ color: '#111111' }}>
						not to sign up for an account on behalf of someone else or of any
						group or entity;
					</span>
				</li>
				<li>
					remove or alter any copyright notices or similar that is included in
					any part of the Service;
				</li>
				<li>
					collect information about other users of the Service for example for
					the purpose of sending spam or junk mails or selling such information;
				</li>
				<li>
					pretend to be someone else when creating an account, signing up for a
					Subscription, or otherwise using the Service;
				</li>
				<li>
					When applicable, you agree to comply with the Store Terms and
					Conditions and/or the terms and conditions of the third-party platform
					through which the Platform and the Service are made available to you;
				</li>
				<li>
					comply with all applicable federal, provincial, state and local laws
					and regulations when using the Platform, the Service and the content
					available through the Service.
				</li>
			</ul>
			<p>
				2. When using the Service, for example viewing long-form videos via the
				Service (including public chats), you warrant and agree that you will
				not publish or otherwise make available anything that:
			</p>
			<ul>
				<li>
					is false, misleading, harassing, offensive, inappropriate, repulsive
					or contains pornographic or threatening content;
				</li>
				<li>
					constitutes illegal activity or incitement or otherwise violates the
					rights of others, including but not limited to intellectual property
					rights and privacy rights;
				</li>
				<li>
					contains spam, viruses, corrupted data, malicious software or other
					programs that may harm computers or other property;
				</li>
				<li>
					in our discretion, contravenes the content or purposes of the Terms.
				</li>
			</ul>
			<p>
				1.&nbsp; <strong>Account Security</strong>
			</p>
			<p>
				1.&nbsp; You agree to maintain the conﬁdentiality and security of your
				account data, including but not limited to your password and
				identiﬁcation information. You are solely responsible for restricting
				access to your account and to your Devices.
			</p>
			<p>
				2.&nbsp; By granting access to the Service on a Device then any person
				accessing such Device will have access to your account data. You are
				entirely responsible for all activities that take place using your
				account and your Devices, and you agree to immediately notify Staccs of
				any unauthorized use of your account or any other breach of security.
			</p>
			<p>
				2.&nbsp;{' '}
				<strong>Advertising, Third-Party Materials, Third-Party Website</strong>
			</p>
			<p>
				1.&nbsp; We may, at our sole discretion, insert advertising, sponsorship
				and promotion within the Service, including but not limited to,
				pre-roll, mid-roll and/or post- roll advertisements, advertising
				banners, push notiﬁcations and links to third- party websites, as well
				as any other form of advertisement and promotion. In addition, you may
				access the Service through websites or platforms operated by third
				parties. You acknowledge and agree that we are not responsible for
				examining or evaluating the content or accuracy of any such third-party
				material or websites. Links to and material from any third-party
				websites does not automatically imply approval or endorsement thereof by
				us.
			</p>
			<p>
				2.&nbsp; Except as provided in our{' '}
				<a href="https://staccs.com/privacy" target="_blank" rel="noopener">
					<span style={{ color: '#0b4cb4' }}>Privacy Policy</span>
				</a>
				, we do not assume and will not have any liability or responsibility for
				any third-party material or websites, or for any other material,
				products or services of third parties. You also agree and acknowledge
				that you may be subject to other terms and conditions from third parties
				when you use such third parties' material, products, services, software,
				websites or portals, including but not limited to, the Store Terms and
				Conditions, and that the present Agreement may no longer apply.
			</p>
			<p>
				3.&nbsp; <strong>Content Availability</strong>
			</p>
			<p>
				1.&nbsp; You hereby acknowledge and agree that the content that is
				available through the Service may vary depending on the country
				associated with the IP address of the Device used to access the
				Service.&nbsp;
			</p>
			<p>
				2.&nbsp; You acknowledge and agree that the trailers, samples and
				extracts of video materials that may be made available via the Service
				are only to serve as an example of the content available on the Service,
				and that Staccs does not represent or guarantee that such videos are
				and/or will be made available in the Service in your country.
			</p>
			<p>
				3.&nbsp; The descriptions and information, such as genres, categories,
				and the like, are provided for convenience only, and you acknowledge and
				agree that Staccs does not guarantee their accuracy.
			</p>
			<p>
				4.&nbsp; We reserve the right to change, suspend, remove, or disable
				access to any and all content available in the Service and the pricing
				and the availability of any and all Subscriptions to the Service, at any
				time without notice, subject to the provisions of Sections 1, 2, 3, 4
				and 5. Staccs will notify users a minimum 30 days in advance of due
				pricing changes.
			</p>
			<p>
				5.&nbsp; We shall not be liable for the removal of or disabling of
				access to any such product, material or content. For greater certainty,
				a modiﬁcation to the pricing after your purchase will not affect such
				purchase.
			</p>
			<p>
				4.&nbsp; <strong>Intellectual Property Rights</strong>
			</p>
			<p>
				1.&nbsp; The content included in the Service is protected in various
				ways, for example copyrights, trademarks and trade secrets. We and our
				licensors hold all copyrights, neighboring rights and all other rights
				in and to all material and content available on the Platform, the Site
				and all other parts of the Service.
			</p>
			<p>
				2.&nbsp; By creating an account and/or signing up for a Subscription, we
				only grant you a license to view long-form video content on the Service.
				You understand and agree that when you use the Service you are not
				acquiring any ownership or other interest of the rights and material
				included in the Service.
			</p>
			<p>
				5.&nbsp; <strong>Copyright Claims</strong>
			</p>
			<p>
				1.&nbsp; &nbsp; It is of the highest importance for us to obey all
				relevant copyright laws and will review all claims of copyright
				infringement received and remove content deemed to have been posted or
				distributed in violation of any such laws. To make a claim, please
				provide us with the following:
			</p>
			<p>
				&nbsp;(i) A physical or electronic signature of the copyright owner or
				the person authorized to act on its behalf;
			</p>
			<p>
				&nbsp;(ii) A description of the copyrighted work claimed to have been
				infringed;
			</p>
			<p>
				&nbsp;(iii) A description of the infringing material and information
				reasonably sufﬁcient to permit us to locate the material;
			</p>
			<p>
				&nbsp;(iv)Your contact information, including your address, telephone
				number, and email;
			</p>
			<p>
				&nbsp;(v) A statement by you that you have a good faith belief that use
				of the material in the manner complained of is not authorized by the
				copyright owner, its agent, or the law; and;
			</p>
			<p>
				&nbsp;(vi) A statement that the information in the notiﬁcation is
				accurate, and, under the pains and penalties of perjury, that you are
				authorized to act on behalf of the copyright owner.
			</p>
			<p>
				2.&nbsp; Claims can be sent to [support@staccs.com] or Copyright Claims,
				World of Staccs AB at the address stated above.
			</p>
			<p>
				6.&nbsp; <strong>Offensive Materials</strong>
			</p>
			<p>
				1.&nbsp; You understand that by using the Service, you may come across
				content that may be considered offensive, vulgar, indecent, or otherwise
				objectionable, which content may or may not be identiﬁed as having
				explicit language. Your use of the Platform and the Service is at your
				sole risk and we shall not be liable to you for content that may be
				found to be offensive, vulgar, indecent, or otherwise objectionable.
			</p>
			<p>
				7.&nbsp; <strong>Disclaimer of Warranties</strong>
			</p>
			<p>
				1.&nbsp; You understand and agree that we only make the long-form videos
				accessible through the Service and the Platform.
			</p>
			<p>
				2.&nbsp; We will work hard to ensure that the Site and other parts of
				the Service are available and operational. However, without prejudice to
				your rights pursuant to applicable mandatory consumers laws, you
				understand and agree that the Site, the Platform and all other parts of
				the Service are provided “as is”. This means that we do not warrant:
			</p>
			<ul>
				<li>
					that the result of using the Service will meet your expectations or
					requirements;
				</li>
				<li>
					that the performance and features in relation to a specific content
					will be identical to the performances and features in relation to
					other content or that are generally displayed on the Site or, for
					example in demos or samples; or
				</li>
				<li>
					that the Service will always function without any errors or defaults.
				</li>
			</ul>
			<p>
				1.&nbsp; We take no responsibility for the performance of your hardware,
				software and network connection. Our ability to provide you with the
				Service depends on whether your viewing device and system meet the
				applicable minimum technical requirements (which can be found{' '}
				<a
					href="https://support.staccs.com/en/articles/5908134-devices-apps"
					target="_blank"
					rel="noopener"
				>
					here
				</a>
				). All long-form videos are streamed using [HSL or DASH] which means
				that the quality of the video will depend on your individual network
				connection. In the event that you are dissatisfied with the Service due
				to errors or defaults, you understand and agree that you have to
				cooperate with us, to the extent reasonably possible and necessary, to
				determine whether your hardware, software and/or network connection is
				the cause of such errors or defaults.
			</p>
			<p>
				2.&nbsp; <strong>Limitations of Liability</strong>
			</p>
			<p>
				1.&nbsp; Nothing in the Terms shall affect your legal rights as a
				consumer or exclude or limit any liability that cannot legally be
				excluded or limited.
			</p>
			<p>
				2.&nbsp; You understand and agree that the long-form videos available on
				the Service and the Platform will include artistic performances and that
				we have no responsibility with respect to the content or quality of the
				performances, except providing the streaming of the performance.
			</p>
			<p>
				3.&nbsp; We are not responsible for the actions or information of third
				parties, including performers or the livestream audience, and you
				release us from any claims and damages in connection with any claim you
				have against any such third parties.
			</p>
			<p>
				4.&nbsp; Our liability is limited to the price you have paid for the
				Subscription.
			</p>
			<p>
				3.&nbsp; <strong>Feedback &amp; Reviews</strong>
			</p>
			<p>
				1.&nbsp; We may provide you with the opportunity to post your comments
				and/or reviews on the Service or to otherwise submit information to us.
				You hereby agree to grant to us a perpetual, worldwide and royalty-free
				license to display, repost, reproduce, distribute, modify, and/or
				display and use generally any and all comments and/or reviews (including
				your name, image and/or likeness when applicable) that you may have
				posted or otherwise provided to us. You hereby waive any and all
				so-called "moral rights" that you may have in and to such comments
				and/or reviews. You also agree to complete and execute any documentation
				that we may deem necessary to give full effect to this grant of rights,
				authorization and waiver.
			</p>
			<p>
				4.&nbsp; <strong>Changes to the Service</strong>
			</p>
			<p>
				1.&nbsp; Unless otherwise prohibited under a mandatory law of a member
				state of the European Union or any other jurisdiction, we reserve the
				right to change, modify or cancel, temporarily or permanently, all or
				certain functions and features of the Service at any time. For example,
				changes to Service may be necessary in order to adapt the content on the
				Site to new technical environments or to an increased number of users,
				or for other important operational reasons.
			</p>
			<p>
				5.&nbsp; <strong>Termination of the Service</strong>
			</p>
			<p>
				1.&nbsp; We shall have the right, at its sole discretion, to modify,
				suspend, remove, discontinue or disable access to the Platform and/or
				the Service (or any part, feature or content thereof) at any time and
				for whatever reason, without notice. We shall not be liable to you or to
				any third party for exercising such rights.
			</p>
			<p>
				2.&nbsp; If you are unable to use the Service as a result of us
				exercising any of the above-mentioned rights, any request for refunds
				shall be made to us or the Store or the third-party platform through
				which the Platform and the Service were made available to you, as
				applicable, in accordance with this Agreement or the Store Terms and
				Conditions or the terms and conditions of the relevant third-party
				platform, as applicable.
			</p>
			<p>
				3.&nbsp; We reserve the right to cancel any purchases made by you, as
				well as suspending your access to public chats within the Service, with
				immediate effect, if you violate the rules of conduct or otherwise
				breach your obligations under the Terms, including but not limited to
				providing us with incorrect personal information or purchasing
				Subscription(s) for commercial purposes.
			</p>
			<p>
				4.&nbsp; In the event that a purchase is canceled as a result of a
				breach by you of the Terms, we reserve the right to not refund amounts
				that you have already paid, to the fullest extent permitted by law.
			</p>
			<p>
				6.&nbsp; <strong>Personal data</strong>
			</p>
			<p>
				1.&nbsp; To provide the Service we will need to collect personal data.
				You can find more information in our
				<a href="https://staccs.com/privacy" target="_blank" rel="noopener">
					Privacy Policy
				</a>{' '}
				on how we process your personal data.
			</p>
			<p>
				7.&nbsp; <strong>Assignment</strong>
			</p>
			<p>
				1.&nbsp; We have the right to transfer the Terms, in whole or partially,
				and are entitled to delegate, in whole or partially, its obligations
				under the Terms. You are prohibited from transferring the Terms, in
				whole or partially, and are not allowed to transfer or sublicense your
				rights under the Terms to a third party.
			</p>
			<p>
				8.&nbsp; <strong>Complaints</strong>
			</p>
			<p>
				1.&nbsp; Please contact our support (
				<a href="mailto:support@staccs.com" target="_blank" rel="noopener">
					support@staccs.com
				</a>
				) if you are dissatisfied with the Service. If we are unable to find a
				solution, you can turn to your local consumer ombudsman or the European
				Union web-based platform for online dispute resolution (website:
				<a
					href="http://ec.europa.eu/consumers/odr"
					target="_blank"
					rel="noopener"
				>
					<span style={{ color: '#0b4cb4' }}>
						http://ec.europa.eu/consumers/odr
					</span>
				</a>
				). In Sweden, you may turn directly to the Swedish National Board for
				Consumer Disputes (Sw. Allmänna Reklamationsnämnden – ARN) (email:
				<span style={{ color: '#0b4cb4' }}> arn@arn.se</span>).
			</p>
			<p>
				9.&nbsp; <strong>Disputes</strong>
			</p>
			<p>
				1.&nbsp; Any dispute regarding the Terms shall be finally settled by
				Swedish courts, with the District Court of Stockholm as first instance.
				We reserve the right to bring motions for interim measures or otherwise
				initiate legal actions or proceedings in other jurisdictions.
			</p>
			<p>
				10.<strong>Applicable law</strong>
			</p>
			<p>
				<span style={{ color: '#111111' }}>1.</span>
				<span style={{ color: '#111111' }}>&nbsp; </span>
				<span style={{ color: '#111111' }}>
					Unless otherwise required by mandatory law of a member state in the
					European Union or any other jurisdiction, these Terms shall be
					governed by and interpreted in accordance with the laws of Sweden,
					without regard to its conflict of law principles.
				</span>
			</p>
			<p>ƒ</p>
		</main>
	);
};

export default Terms;
