import React from 'react';
import CtaButton from '../../Buttons/CTA/CtaButton';
import { useContext } from 'react';
import { LanguageContext } from '../../../Context/language';

interface FeatureProps {
	feature: {
		heading: string;
		text: string;
		image: string;
	};
}

const Feature: React.FC<FeatureProps> = ({ feature }): JSX.Element => {
	const { language } = useContext(LanguageContext);

	return (
		<article className="feature">
			<div className="feature__text-content">
				<h2 className="feature__heading">{feature.heading}</h2>
				<p className="feature__text">{feature.text}</p>
				<CtaButton
					link="/register"
					text={language.signUp}
					bgColor="#ffd85b"
					internLink={true}
					boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
				/>
			</div>
			<img src={feature.image} alt="" loading="lazy" className="feature__img" />
		</article>
	);
};

export default Feature;
