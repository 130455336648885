import { useEffect, useState, useContext } from 'react';
import Loader from '../../Components/Loader/Loader';
import { LanguageContext } from '../../Context/language';
import { PlansContext } from '../../Context/PlansContext';
import { RegisterContext } from '../../Context/RegisterContext';
import PriceCard from './PriceCard/PriceCard';
import axios from 'axios';
import { api_url } from '../../Constants/constants';
import { useLocation } from 'react-router-dom';
import { TempAuthContext } from '../../Context/tempAuthContext';

interface RegistrationStep2Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step2Loaded: boolean;
  setStep2Loaded: React.Dispatch<React.SetStateAction<boolean>>;
  tempCustomerId: string;
  tempStripeId: string;
}

interface LocationState {
  se?: {
    checkoutInfo?: string;
  }
  en?: {
    checkoutInfo?: string;
  }
  discountCoupon?: string;
  discountPrice?: string;
}

const RegistrationStep2: React.FC<RegistrationStep2Props> = ({
  setStep,
  step,
  step2Loaded,
  setStep2Loaded,
  tempCustomerId,
  tempStripeId
}): JSX.Element => {
  const { formData, setFormData, setPaymentIntentClientSecret, createGoogleUser } = useContext(RegisterContext)
  const { language } = useContext(LanguageContext)
  const { signIn, stripeCustomerId } = useContext(TempAuthContext)
  const { prices, partnerPrice, collabPrices, setStripeCustomerBalance, stripeCustomerBalance } = useContext(PlansContext)
  const [showError, toggleShowError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [loadingDiscount, setLoadingDiscount] = useState<boolean>(false)
  const [checkoutLang, setCheckoutLang] = useState<string>('se');
  const locale = localStorage.getItem("locale");

  const location = useLocation()
  const locationState: LocationState = location.state

  useEffect(() => {
    if (locale !== 'se') {
      setCheckoutLang('en');
    }

    if (locationState?.discountCoupon && stripeCustomerBalance === 0) {
      setLoadingDiscount(true);
      let currency: string;
      if (locale === "se") {
        currency = "sek";
      } else if (locale === "no") {
        currency = "nok";
      } else if (locale === "dk") {
        currency = "dkk";
      } else if (locale === "fi" || locale === "ee" || locale === "lv" || locale === "lt" || locale === "ax") {
        currency = "eur";
      }
      let couponId = locationState?.discountCoupon;
      let discountPrice = locationState?.discountPrice;
      let stripeId = tempStripeId || stripeCustomerId;

      const giveCustomerDiscount = async () => {
        const createCoupon = {
          method: 'post',
          url: `${api_url}/api/stripe/coupon`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            stripeId,
            couponId,
            currency,
            discountPrice
          })
        };
        const response = await axios(createCoupon);
        if (response.status === 200) {
          setStripeCustomerBalance(response?.data?.amount_off);
          setLoadingDiscount(false);
        }
      }
      giveCustomerDiscount();
    }
  }, [])

  useEffect(() => {
    if (!step2Loaded) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'signup_step_2',
      })
      setStep2Loaded(true)
    }
  }, [])

  useEffect(() => {
    if (partnerPrice) {
      setLoading(false)
      setFormData({
        ...formData,
        priceId: partnerPrice.id,
        amount: partnerPrice.unit_amount,
        currency: partnerPrice.currency,
        interval: partnerPrice?.recurring?.interval,
        recurring: partnerPrice?.recurring,
        freeTrialDays: partnerPrice?.recurring?.trial_period_days ? partnerPrice?.recurring?.trial_period_days : 0,
      })
    } else if (collabPrices?.length > 0) {
      setLoading(false)
    } else if (collabPrices?.length === 0 && !partnerPrice) {
      setLoading(false)
    }
  }, [collabPrices, partnerPrice])

  const createStripePaymentIntent = async () => {
    const priceId = formData.priceId;
    const customerId = tempCustomerId;
    const stripeId = tempStripeId || stripeCustomerId;
    const currency = formData.currency
    const unit_amount = formData.amount

    var config = {
      method: 'post',
      url: `${api_url}/api/signup/payment-intent`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        customerId,
        stripeId,
        priceId,
        amount: unit_amount,
        currency,
      }),
    }
    const intentResponse = await axios(config)
    setPaymentIntentClientSecret(intentResponse.data.client_secret)
  }

  const onClickNext = async () => {
    setDisableButton(true);
    let priceId = formData.priceId
    let customerId = tempCustomerId;
    let stripeId = tempStripeId || stripeCustomerId;
    let currency = formData.currency

    let selectedPrice;

    const findDefaultPrice = prices.find((price) => price.id === formData.priceId)
    const findCollabPrice = collabPrices.find((price) => price.id === formData.priceId)

    if (findDefaultPrice)
      selectedPrice = findDefaultPrice;
    else if (findCollabPrice)
      selectedPrice = findCollabPrice;
    else if (partnerPrice.id === formData.priceId)
      selectedPrice = partnerPrice
    else
      return

    if (!formData.priceId) {
      toggleShowError(true)
      return
    }
    if (selectedPrice?.nickname === 'Member' || partnerPrice?.recurring?.trial_period_days === 365) {
      try {
        var config = {
          method: 'post',
          url: `${api_url}/api/signup/createfreemium`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            customerId,
            stripeId,
            priceId,
            currency,
          }),
        }
        const response = await axios(config)

        if (response.status === 200) {
          if (!createGoogleUser) {
            signIn(formData.email, formData.password)
          }
          setStep(4)
        }
      } catch (error) {
        console.log(error)
      }
    } else if (selectedPrice?.nickname === '72h') {
      await createStripePaymentIntent()
      setStep(3)
    } else {
      try {
        var config = {
          method: 'post',
          url: `${api_url}/api/signup/createsubscription`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            customerId,
            stripeId,
            priceId,
            currency,
          }),
        }
        const response = await axios(config)

        if (response.status === 200) {
          setPaymentIntentClientSecret(response.data.clientSecret)
          setStep(3)
        }
      } catch (error) {
        console.log(error)
      }
      setStep(3)
    }
  }

  const onClickOption = (price: StripePrice): void => {
    setFormData({
      ...formData,
      priceId: price.id,
      amount: price.unit_amount,
      currency: price.currency,
      interval: price?.recurring?.interval,
      recurring: price?.recurring,
      freeTrialDays: price?.recurring?.trial_period_days ? price?.recurring?.trial_period_days : 0,
    })

    if (showError) {
      toggleShowError(false)
    }
  }

  if (loading || loadingDiscount) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className="registration__form-wrapper">
        <h2 className="section-heading--light">{language.pickPlan}</h2>
        <span className="registration__step-span">
          {language.step} {step} / 3
        </span>
        {/* PLANS */}
        <div className="registration__plans-wrapper">
          {locationState && locationState[checkoutLang]?.checkoutInfo ? (
            <span className="registration__step-span" style={{ fontSize: "11pt", maxWidth: "200px" }}>{locationState[checkoutLang]?.checkoutInfo}</span>
          ) : null}
          {/* PLANS LIST */}
          {partnerPrice ? (
            <PriceCard key={partnerPrice.id} price={partnerPrice} onClickOption={onClickOption} />
          ) : collabPrices.length > 0 ? (
            collabPrices?.map((price: StripePrice) => (
              <PriceCard key={price.id} price={price} onClickOption={onClickOption} />
            ))
          ) : (
            prices?.map((price: StripePrice) => (
              <PriceCard key={price.id} price={price} onClickOption={onClickOption} />
            ))
          )}
          {/* ERROR MESSAGE */}
          {showError && <p className="registration__error-message">Please select a plan </p>}
        </div>
        {/* SUBMIT */}
        <button
          disabled={!formData.priceId || disableButton}
          className="registration__submit-btn"
          onClick={onClickNext}
        >
          {disableButton ? (
            <div className="loading__spinner"></div>
          ) : (
            language.nextButton
          )}
        </button>
      </div>
    )
  }
}


export default RegistrationStep2;
