import React from "react";

export default function Banner({ item }) {
  return (
    <div
      style={{
        width: "100%",
        height: "200px",

        overflowY: "hidden",
        backgroundColor: "grey",
      }}
    >
      <img style={{ opacity: 0.1 }} src={item.imgUrl} />
    </div>
  );
}
