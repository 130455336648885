import { useContext } from "react";
import { LanguageContext } from "../Context/language";
// import profileImg from "./saulGoodman2.jpg";

const About: React.FC = (): JSX.Element => {
  const { language } = useContext(LanguageContext);

  return (
    <main className="main">
      <article className="about section">
        <h1 className="about__heading page-heading">
          {language.footerCompany1}
        </h1>
        <h2 className="about__sub-heading">{language.aboutHeadline1}</h2>
        <p className="about__text">{language.about1}</p>
        <h2 className="about__sub-heading">{language.aboutHeadline2}</h2>
        <p className="about__text">{language.about2}</p>
        <h2 className="about__sub-heading">{language.aboutHeadline3}</h2>
        <p className="about__text">{language.about3}</p>

        {/* ABOUT EMPLOYEE LIST USE FOR LATER, YES? */}

        {/* <h2 className="about__sub-heading" style={{ textAlign: "center" }}>VI PÅ STACCS</h2>
        <hr />
        <ul className="about__employee-list">
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              boss
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Mastermind
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Glädjespridaren
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Westeeeroess
            </p>
          </li>
        </ul>
        <ul className="about__employee-list">
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              boss
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Mastermind
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Glädjespridaren
            </p>
          </li>
          <li className="about_employee-item">
            <img src={profileImg} alt="" className="employee-img">
            </img>
            <p className="employee-text">
              Westeeeroess
            </p>
          </li>
        </ul> */}
      </article>
    </main>
  );
};

export default About;
