import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { api_url } from '../../../Constants/constants';
import { LanguageContext } from '../../../Context/language';
import { UpgradeContext } from '../../../Context/UpgradeContext';
import { TempAuthContext } from '../../../Context/tempAuthContext';

// COMPONENTS
import CheckoutForm from '../../Register/CheckoutForm';
import AddPaymentMethod from './AddPaymentMethod';
import Loader from '../../../Components/Loader/Loader';
import CtaButton from '../../../Components/Buttons/CTA/CtaButton';
import ConfirmUpgrade from './ConfirmUpgrade';
import PaymentComplete from '../../../Components/PaymentComplete/PaymentComplete';
import { PlansContext } from '../../../Context/PlansContext';

const stripePromise = loadStripe('pk_live_kYGub1xSzIrHvx9ljFYCVnbB');

interface LocationState {
	price?: StripePrice;
}

const UpgradePayment: React.FC = (): JSX.Element => {
	const { accessToken, role, user, userProfile, getAccount } =
		useContext(TempAuthContext);
	const { setPriceId, setRecurring, paymentMethodId } =
		useContext(UpgradeContext);
	const { language } = useContext(LanguageContext);
	const { stripeCustomerBalance, partnerPrice } = useContext(PlansContext);

	const [intentClientSecret, setIntentClientSecret] = useState(null);
	const [finishedPayment, setFinishedPayment] = useState(0);
	const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
	const [readyForCheckout, setReadyForCheckout] = useState(false);
	const [loadingConfirmation, setLoadingConfirmation] = useState(false);
	const [error, setError] = useState('');
	const locale = window.localStorage.getItem('locale');

	const location = useLocation();
	const locationState: LocationState = location.state;
	const price = locationState as StripePrice;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setPriceId(price.id);
		setRecurring(price.recurring);
		setReadyForCheckout(price.nickname === '72h' || role === 'FREE');
		const prepareComponent = async () => {
			await preparePayment();
		};
		prepareComponent();
	}, []);

	const createStripePaymentIntent = async () => {
		const config = {
			method: 'post',
			url: `${api_url}/api/signup/payment-intent`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				priceId: price.id,
				currency: price.currency.toLowerCase(),
				amount: price.unit_amount,
				customerId: user.profile.customerId,
				stripeId: user.stripeId,
			}),
		};
		const intentResponse = await axios(config);
		setIntentClientSecret(intentResponse.data.client_secret);
	};

	const createStripeSetupIntent = async () => {
		const config = {
			method: 'post',
			url: `${api_url}/api/upgrade/setup-intent`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				priceId: price.id,
			}),
		};
		const intentResponse = await axios(config);
		setIntentClientSecret(intentResponse.data.client_secret);
	};

	const updateStripeSubscription = async () => {
		setLoadingConfirmation(true);

		try {
			const config = {
				method: 'post',
				url: `${api_url}/api/upgrade/subscription/${user.subscription.id}`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: JSON.stringify({
					priceId: price.id,
					customerId: user.profile.customerId,
					stripeId: user.stripeId,
					paymentMethodId,
				}),
			};
			const updateResponse = await axios(config);

			if (updateResponse.status === 200) {
				setLoadingConfirmation(false);
				setFinishedPayment(4);
			}
		} catch (error) {
			console.log(error);
			setError('please try again later or contact our support at...');
		}
	};

	const createStripeSubscription = async () => {
		const config = {
			method: 'post',
			url: `${api_url}/api/signup/createsubscription`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				priceId: price.id,
				stripeId: user.stripeId,
				customerId: userProfile.customerId,
				currency: price.currency.toLowerCase(),
			}),
		};
		const intentResponse = await axios(config);
		setIntentClientSecret(intentResponse.data.clientSecret);
	};

	const preparePayment = async () => {
		switch (true) {
			case price.nickname === 'Member' ||
				price.recurring.trial_period_days === 365:
				try {
					var config = {
						method: 'post',
						url: `${api_url}/api/signup/createfreemium`,
						headers: {
							'Content-Type': 'application/json',
						},
						data: JSON.stringify({
							priceId: price.id,
							currency: price.currency,
							customerId: userProfile.customerId,
							stripeId: user.stripeId,
						}),
					};
					const response = await axios(config);
					if (response.status === 200) {
						setFinishedPayment(4);
					} else {
						console.log(error);
						setError('please try again later or contact our support at...');
					}
				} catch (error) {
					console.log(error);
					setError('please try again later or contact our support at...');
				}
				break;

			case price.nickname === '72h':
				try {
					await createStripePaymentIntent();
				} catch (error) {
					console.log(error);
					setError('please try again later or contact our support at...');
				}
				break;
			default:
				if (role === 'FREE') {
					try {
						await createStripeSubscription();
					} catch (error) {
						console.log(error);
						setError('please try again later or contact our support at...');
					}
				} else {
					try {
						// await updateStripeSubscription()
						await createStripeSetupIntent();
					} catch (error) {
						console.log(error);
						setError('please try again later or contact our support at...');
					}
				}
				break;
		}
	};

	if (finishedPayment) {
		getAccount(accessToken);
		return (
			<div className="discount">
				<PaymentComplete
					buttonLink="/account"
					internLink={true}
					buttonText={
						locale === 'se' ? 'Tillbaka till konto' : 'Back to account'
					}
				/>
			</div>
		);
	}

	if (error.length > 0) {
		return (
			<div className="upgrade__form-wrapper bg-dark-matte">
				<div className="upgrade__pay-content">
					<h2 className="section-heading--light">Something went wrong</h2>
					<p style={{ marginBottom: '2rem', fontSize: '14px' }}>{error}</p>
					<CtaButton internLink link="/account" text="Back to account" />
				</div>
			</div>
		);
	}

	return (
		<div className="upgrade__form-wrapper bg-dark-matte">
			{!intentClientSecret ? (
				<Loader />
			) : (
				<Elements
					stripe={stripePromise}
					options={{
						clientSecret: intentClientSecret,
						appearance: { theme: 'night' },
					}}
				>
					<div className="upgrade__pay-content">
						<h2 className="section-heading--light">{language.step3Headline}</h2>

						<p
							style={{
								fontSize: '16px',
								textAlign: 'left',
								marginBottom: '1rem',
							}}
						>
							{language.step3row1}
							{price?.nickname === 'yearly' ||
							price.recurring.interval === 'year'
								? language.step3year
								: price?.nickname === 'Monthly' ||
								  price.recurring.interval === 'month'
								? language.step3month
								: language.step3hours}

							{partnerPrice && stripeCustomerBalance > 0 ? (
								<>
									<span style={{ color: '#85d990', fontSize: '16px' }}>
										{(price?.unit_amount - stripeCustomerBalance) / 100 +
											' ' +
											price?.currency?.toUpperCase()}
									</span>

									<p style={{ fontSize: '16px', textAlign: 'left' }}>
										{locale === 'se'
											? 'Följande period dras '
											: 'Following period we will charge '}
										<span style={{ color: '#85d990', fontSize: '16px' }}>
											{price?.unit_amount / 100 +
												' ' +
												price?.currency?.toUpperCase()}
										</span>
									</p>
								</>
							) : (
								<span style={{ color: '#85d990', fontSize: '16px' }}>
									{price?.unit_amount / 100 +
										' ' +
										price?.currency?.toUpperCase()}
									.
								</span>
							)}
						</p>

						{readyForCheckout ? (
							<CheckoutForm setStep={setFinishedPayment} />
						) : paymentMethodAdded ? (
							<ConfirmUpgrade
								updateStripeSubscription={updateStripeSubscription}
								loadingConfirmation={loadingConfirmation}
							/>
						) : (
							<AddPaymentMethod
								setPaymentMethodAdded={setPaymentMethodAdded}
								intentClientSecret={intentClientSecret}
							/>
						)}
					</div>
				</Elements>
			)}
		</div>
	);
};

export default UpgradePayment;
