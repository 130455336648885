import React, { useContext } from "react";
import PropTypes from "prop-types";

// import Hls from "https://cdn.jsdelivr.net/npm/hls.js@latest";

interface ArtistTitlesProps {
  artistCollab: ArtistCollab;
  artistCollabLanguage: string;
}

/**
 * Renders Artist Titles section for artist collaboration pages
 * @param   {Object}  artistCollab  Artist collab object(LangContext)
 * @returns {HTMLElement}
 */
const ArtistTitles: React.FC<ArtistTitlesProps> = ({
  artistCollab,
  artistCollabLanguage,
}): JSX.Element => {
  return (
    <section
      className="presentation"
      style={{ backgroundColor: `${artistCollab?.backgroundColor}` }}
    >
      {artistCollab?.titles?.map((title) => (
        <div key={title.title} className="presentation__wrapper title">
          <div className="presentation__text">
            <h2
              className="presentation__heading"
              style={{ color: `${artistCollab?.buttonColor}` }}
            >
              {title?.title}
            </h2>
            <p
              className="presentation__p"
              style={{ color: `${artistCollab?.bodyFontColor}` }}
            >
              {title?.texts?.[artistCollabLanguage]?.description}
            </p>
          </div>

          <video
            className="presentation__title-trailer"
            id="video"
            src={artistCollab?.trailer}
            controls
          ></video>
        </div>
      ))}
    </section>
  );
};

// Plz finish me
// ArtistTitles.propTypes = {
//   artistCollab: PropTypes.object.isRequired,
//   artistCollabLanguage: PropTypes.string,
// };

export default ArtistTitles;
