import CtaButton from '../../../Buttons/CTA/CtaButton';
import USPsList from './USPsList/USPsList';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../Context/language';
import { Navigate, useNavigate } from 'react-router-dom';
import { PlansContext } from '../../../../Context/PlansContext';
import { TempAuthContext } from '../../../../Context/tempAuthContext';
import { flexbox } from '@mui/system';

const PlanCard = ({
	price,
	upgrade = false,
	partnerName = null,
}): JSX.Element => {
	const { language } = useContext(LanguageContext);
	const { stripeCustomerBalance } = useContext(PlansContext);
	const { user } = useContext(TempAuthContext);
	const [currentMembership, setCurrentMembership] = useState<boolean>(false);
	const [buttonLink, setButtonLink] = useState('');
	let locale = window.localStorage.getItem('locale');
	const navigate = useNavigate();

	useEffect(() => {
		if (upgrade) {
			setButtonLink('/upgrade/pay');
		} else if (user?.paymentIntents?.length > 0) {
			setButtonLink('/account/checkout');
		} else {
			setButtonLink('/register');
		}
	}, [buttonLink]);

	return (
		<div
			style={{
				border:
					price?.id === user?.subscription?.plan?.id
						? '1px solid #ffc65a'
						: 'null',
			}}
			className={
				price?.id === user?.subscription?.plan?.id
					? 'pricing__plan-card--no-hover'
					: 'pricing__plan-card'
			}
			role={'button'}
			onClick={() =>
				navigate(buttonLink, {
					state: price,
				})
			}
			tabIndex={0}
			onKeyDown={(e) => {
				e.key === 'Enter' &&
					navigate(buttonLink, {
						state: price,
					});
			}}
		>
			{/* Stripe values in EN, translated if location SE */}
			{locale === 'se' ? (
				<h3 className="plan-card__heading">
					{partnerName
						? partnerName
						: price?.nickname === 'Monthly'
						? 'Månad'
						: price?.nickname === 'Yearly'
						? 'År'
						: price?.nickname === 'Member'
						? 'Medlem'
						: price?.nickname}
				</h3>
			) : (
				<h3 className="plan-card__heading">{partnerName || price?.nickname}</h3>
			)}

			{price?.nickname === 'Member' ? (
				<USPsList USPs={language.USPs.freemium} />
			) : price?.recurring.interval === 'month' ||
			  price?.nickname === 'Monthly' ? (
				<USPsList USPs={language.USPs.monthlySub} />
			) : price?.nickname === 'Yearly' ? (
				<USPsList USPs={language.USPs.yearlySub} />
			) : price?.recurring.trial_period_days === 365 ||
			  price.recurring.interval === 'year' ? (
				<USPsList USPs={language.USPs.yearlySub} />
			) : (
				<USPsList USPs={language.USPs.freemium} />
			)}
			{/* Spacer to align card prices and buttons */}
			<div style={{ flex: 1 }}></div>

			{price?.nickname !== 'Member' && price?.nickname !== '72h' ? (
				<>
					{stripeCustomerBalance !== 0 ? (
						<>
							<p
								className="plan-card__price"
								style={{
									marginBottom: '-25px',
									marginTop: '0px',
									color: 'grey',
									textDecoration: 'line-through',
								}}
							>
								{price?.unit_amount / 100}{' '}
								{price?.currency?.toLocaleUpperCase()}
							</p>
							<p className="plan-card__price">
								{price?.unit_amount / 100 - stripeCustomerBalance / 100}{' '}
								{price?.currency?.toLocaleUpperCase()}
							</p>
						</>
					) : price?.recurring.trial_period_days === 365 ? (
						<>
							<p
								className="plan-card__price"
								style={{ marginBottom: '25px', fontSize: '22px' }}
							>
								{price?.recurring.trial_period_days}{' '}
								{locale === 'se' ? 'dagar provperiod' : 'days trial'}
							</p>
						</>
					) : (
						<>
							<p className="plan-card__price" style={{ marginBottom: '25px' }}>
								{price?.unit_amount / 100}{' '}
								{price?.currency?.toLocaleUpperCase()}
							</p>
						</>
					)}
				</>
			) : (
				<>
					{locale === 'se' ? (
						<>
							{price?.nickname === 'Member' ? (
								<>
									<p className="plan-card__price">{'Gratis'}</p>
									<p className="member-price-sub-text">
										{`${
											price?.unit_amount / 100
										} ${price?.currency.toLocaleUpperCase()} per månad efter 1 år`}
									</p>
								</>
							) : (
								<>
									<p className="plan-card__price">
										{price?.unit_amount / 100}{' '}
										{price?.currency?.toLocaleUpperCase()}
									</p>
									<p className="member-price-sub-text">
										{'Få rabatt på våra paket'}
									</p>
								</>
							)}
						</>
					) : (
						<div>
							{price?.nickname === 'Member' ? (
								<>
									<p className="member-price-text">{'Free'}</p>
									<p className="member-price-sub-text">
										{`${
											price?.unit_amount / 100
										} ${price?.currency.toLocaleUpperCase()} per month after 1 year`}
									</p>
								</>
							) : (
								<>
									<p className="member-price-text">
										{price?.unit_amount / 100}{' '}
										{price?.currency?.toLocaleUpperCase()}
									</p>
									<p className="member-price-sub-text">
										{'Get discount on subscriptions'}
									</p>
								</>
							)}
						</div>
					)}
				</>
			)}
			<div className="cta-button--no-hover">
				<span style={{ fontSize: '1.6rem' }}>{language.ctaButton}</span>
			</div>
		</div>
	);
};

export default PlanCard;
