import React, { useContext, useEffect, useState } from 'react'
import { TempAuthContext } from '../../Context/tempAuthContext';
import backgroundImage from "../../Assets/Images/gridnewland2.0.jpg";
import { DialogContext } from '../../Context/DialogContext';
import { PlansContext } from '../../Context/PlansContext';
import PlanCard from '../../Components/Pricing/PlanList/PlanCard/PlanCard';
import { api_url } from '../../Constants/constants';
import Loader from '../../Components/Loader/Loader';
import PaymentComplete from '../../Components/PaymentComplete/PaymentComplete';

const Discount: React.FC = (): JSX.Element => {
    const [pricesToRender, setPricesToRender] = useState<any>([])
    const { stripeCustomerId, user, getAccount } = useContext(TempAuthContext);
    const { setView, toggleSignupDialogOpen } = useContext(DialogContext);
    const { prices, setStripeCustomerBalance, stripeCustomerBalance, stripePricesLoading } = useContext(PlansContext);
    const [showPrices, setShowPrices] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [countryForPrice, setCountryForPrice] = useState<string>("");
    const [error, setError] = useState<string>("");
    const accessToken = localStorage.getItem("access_token");
    const locale = window.localStorage.getItem("locale");

    const discountPrice = {
        se: {
            yearly: 599,
            monthly: 89,
            discountPrice: 59,
        },
        dk: {
            yearly: 499,
            monthly: 79,
            discountPrice: 49,
        },
        no: {
            yearly: 599,
            monthly: 89,
            discountPrice: 59,
        },
        eu: {
            yearly: 59.90,
            monthly: 8.99,
            discountPrice: 5.99,
        }
    }

    useEffect(() => {
        if (!user && accessToken) {
            getAccount(accessToken);
        }
        if (locale === "se") {
            setCountryForPrice("se");
        } else if (locale === "no") {
            setCountryForPrice("no");
        } else if (locale === "dk") {
            setCountryForPrice("dk");
        } else {
            setCountryForPrice("eu");
        }
        setPricesToRender(prices.filter(price => price.nickname !== 'Member' && price.nickname !== '72h'))
        if (prices.length > 0) {
            setLoading(false);
        }
    }, [setStripeCustomerBalance, stripeCustomerBalance, stripeCustomerId, stripePricesLoading, prices, locale])

    const openSignInModal = () => {
        toggleSignupDialogOpen(true);
        setView("SIGNIN");
    };

    const clickHandler = () => {
        setLoading(true);
        if (user?.paymentIntents?.length > 0) {
            fetch(`${api_url}/api/stripe/balance-customer`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    customerId: stripeCustomerId,
                    paymentIntent: user.paymentIntents[0]
                }),
            }).then((res) => {
                if (res.status === 201) {
                    getCustomerBalance();
                } else if (res.status === 200) {
                    getCustomerBalance();
                } else {
                    setError("You do not meet the requirements for this discount");
                    setLoading(false);
                }
            }).catch((err) => {
                setError("You do not meet the requirements for this discount");
                setLoading(false);
                console.log(err);
            })
        } else {
            setError("You do not meet the requirements for this discount");
            setLoading(false);
        }
    }
    const getCustomerBalance = () => {
        const getCustomer = async () => {
            if (!stripeCustomerId) return;
            try {
                const customer = await fetch(`${api_url}/api/stripe/stripe-customer/${stripeCustomerId}`)
                const customerRes = await customer.json()
                if (customerRes.balance) {
                    setStripeCustomerBalance(customerRes.balance);
                    setShowPrices(true);
                } else {
                    setError("Something went wrong..")
                }
                setLoading(false);
            } catch (error) {
                console.error(
                    `Error ${error.name} when fetching prices in PlansContext. Error Message: ${error.message}`
                )
            }
        }
        getCustomer();
    }

    if (loading) {
        return (
            <div className='discount bg-dark-matte' style={{ backgroundColor: "#121212" }}>
                <Loader />
            </div>
        )
    } else if (!showPrices && !loading) {
        return (
            <>
                <div
                    className="discount"
                    style={{
                        backgroundImage: `url(${backgroundImage
                            })`,
                    }}
                >
                    <div className="discount-content">
                        <h1 className="hero__heading">
                            {locale === "se" ? "Du har fått en exklusiv rabatt" : "You have been granted an exclusive discount"}
                        </h1>
                        <p className='discount-text'>
                            {locale === "se" ? "Du får hela värdet av ditt köp avdraget om du uppgraderar till ett av våra abbonemang." : "You get the full value of your purchase deducted if you upgrade to one of our subscriptions."}
                        </p>
                        <div className='discount-content__subscription'>
                            <div className='discount-box'>
                                <h2 className='discount-sub__heading'>
                                    {locale === "se" ? "Månad" : "Monthly"}
                                </h2>
                                <p className='discount-text-small'>
                                    {locale === "se" ? "Väljer du månadsabonnemang får du första månaden för " : "Pick monthly subscription you get first month for "}
                                    {discountPrice[countryForPrice].monthly - discountPrice[countryForPrice].discountPrice} {prices[0].currency}
                                </p>
                            </div>
                            <div className='discount-box'>
                                <h2 className='discount-sub__heading'>
                                    {locale === "se" ? "År" : "Yearly"}
                                </h2>
                                <p className='discount-text-small'>
                                    {locale === "se" ? "Väljer du årsabonnemang får du hela året för " : "Pick yearly subscription you get the whole year for "}
                                    {discountPrice[countryForPrice].yearly - discountPrice[countryForPrice].discountPrice} {prices[0].currency}
                                </p>
                            </div>
                        </div>
                        {accessToken ? (
                            <p className='discount-text'>
                                {locale === "se" ? "Vänligen klicka nedan för att använda detta erbjudande." : "Please click below to claim this discount."}
                            </p>
                        ) : (
                            <p className='discount-text'>
                                {locale === "se" ? "Vänligen logga in för att använda detta erbjudande." : "Please sign in to claim this discount."}
                            </p>
                        )}

                        {accessToken ? (
                            <>
                                {error ? (
                                    <p className='discount-text' style={{ marginTop: "10px", color: "#ea3342" }}>{error}</p>
                                ) : (
                                    <button className="cta-button"
                                        style={{ backgroundColor: "#FFC65A", fontSize: "18px", fontWeight: "bold", marginTop: "40px" }}
                                        onClick={() => clickHandler()}>
                                        {locale === "se" ? "Använd erbjudandet" : "Claim discount"}
                                    </button>
                                )}
                            </>
                        ) : (
                            <button
                                onClick={() => openSignInModal()}
                                className="cta-button"
                                style={{ backgroundColor: "#FFC65A", fontSize: "18px", fontWeight: "bold", marginTop: "40px" }}
                            >
                                {locale === "se" ? "Logga in" : "Sign in"}
                            </button>
                        )}
                    </div>
                </div>
            </>
        );
    } else if (showPrices && !loading) {
        return (
            <div
                className="discount"
                style={{
                    gap: "25px",
                    backgroundImage: `url(${backgroundImage
                        })`,
                }}
            >
                {pricesToRender?.length > 0 ? (
                    <>
                        {pricesToRender?.map((price: StripePrice) => (
                            <PlanCard key={price.id} price={price} />
                        ))}
                    </>
                ) : (
                    null
                )}
            </div>
        )
    }
}
export default Discount;
