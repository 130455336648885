import { useState, useRef, useEffect, useContext, SetStateAction } from 'react';
import videojs from 'video.js';
import axios from 'axios';
import VideoPlayer from './video-player/VideoPlayer';
import { TempAuthContext } from '../../../../../Context/tempAuthContext';
import { DialogContext } from '../../../../../Context/DialogContext';
import Loader from '../../../../Loader/Loader';

const PlayScreen = ({
	contentType,
	showVideo,
	toggleShowVideo,
	setAutoPlayingStaccsTV,
}) => {
	const { accessToken } = useContext(TempAuthContext);
	const { selectedTitleId } = useContext(DialogContext);

	const [title, setTitle] = useState({});
	const [signedUrl, setSignedUrl] = useState(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [videoOptions, setVideoOptions] = useState({
		autoplay: true,
		controls: false,
		responsive: true,
		fluid: true,
	});

	const playerRef = useRef(null);

	const api = 'https://devapi.svc.staccs.se/api';
	const fetchTitleData = async (
		titleId: string,
		accessToken: string
	): Promise<any> => {
		const config = {
			method: 'get',
			url: `${api}/video/url?id=${titleId}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		};
		const response = await axios(config);
		setLoading(false);
		return response.data;
	};

	useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'play_discovery_video',
		});

		const getTitleData = async (): Promise<any> => {
			const titleData = await fetchTitleData(selectedTitleId, accessToken);
			setSignedUrl(titleData.url);
			setTitle(titleData);
		};

		getTitleData();
	}, []);

	useEffect(() => {
		if (signedUrl) {
			setVideoOptions((prev) => ({
				...prev,
				sources: [{ src: signedUrl, type: 'application/x-mpegURL' }],
			}));
		}
	}, [signedUrl]);

	const handlePlayerReady = (player): void => {
		playerRef.current = player;

		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
			console.log('NO STACCS TV');
			setAutoPlayingStaccsTV(false);
		});
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				gap: '1rem',
				alignItems: 'center',
				backgroundColor: 'black',
				maxWidth: '400px',
				aspectRatio: '3 / 2',
			}}
		>
			{loading ? (
				<Loader showText={false} />
			) : (
				<div className="video-player__wrapper">
					<VideoPlayer
						toggleShowVideo={toggleShowVideo}
						options={videoOptions}
						onReady={handlePlayerReady}
						title={title}
						setAutoPlayingStaccsTV={setAutoPlayingStaccsTV}
					/>
				</div>
			)}
		</div>
	);
};

export default PlayScreen;
