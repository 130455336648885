import { useState } from "react";
import parse from "html-react-parser";

interface FAQprops {
  faq: {
    question: string;
    answer: string;
  };
}

const FAQ: React.FC<FAQprops> = ({ faq }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="FAQs__box"
      onClick={() => setShow((prevState) => !prevState)}
    >
      <div className="FAQs__question">
        <h3>{faq?.question}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="30px"
          viewBox="0 0 24 24"
          width="30px"
          fill="#000000"
          className={
            show
              ? "xOpen svgclose FAQs__expand-icon"
              : "xClose svgclose FAQs__expand-icon"
          }
        >
          <path d="M0 0h24v24H0V0z" className="pathfill" fill="none" />
          <path
            className="pathclose"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
          />
        </svg>
      </div>
      {show && (
        <div className="FAQs__answer ModalOpen">
          <p>{parse(`${faq?.answer}`)}</p>
        </div>
      )}
    </div>
  );
};

export default FAQ;
