import { useNavigate } from "react-router-dom";

interface CtaButtonProps {
  link: string;
  text: string;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  boxShadow?: string;
  params?: string; 
  internLink: boolean;
}

const CtaButton: React.FC<CtaButtonProps> = ({
  link,
  text,
  textColor = "black",
  bgColor = "white",
  borderColor = "transparent",
  boxShadow,
  params,
  internLink,
}): JSX.Element => {
  const navigate = useNavigate();

  if (internLink) {
    const submit = (
      event: React.MouseEvent,
      link: string,
      params: string
    ): void => {
      event.preventDefault();

      navigate(link, {
        state: params,
      });
    };
    return (
      <a
        onKeyDown={(e) => {
          e.key === "Enter" &&
            navigate(link, {
              state: params,
            });
        }}
        tabIndex={0}
        className="cta-button"
        style={{
          background: bgColor,
          color: textColor,
          borderColor: borderColor,
          boxShadow: boxShadow,
        }}
        onClick={(e) => submit(e, link, params)}
      >
        {text}
      </a>
    );
  } else {
    // external link
    return (
      <a
        tabIndex={0}
        href={link}
        className="cta-button"
        style={{
          background: bgColor,
          color: textColor,
          borderColor: borderColor,
          boxShadow: boxShadow,
        }}
        onClick={(e) => e}
      >
        {text}
      </a>
    );
  }
};

export default CtaButton;
