import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../../Context/language'

interface ConfirmUpgradeProps {
  updateStripeSubscription: () => Promise<void>
  loadingConfirmation: boolean
}

const ConfirmUpgrade: React.FC<ConfirmUpgradeProps> = ({
  updateStripeSubscription,
  loadingConfirmation,
}) => {
  const { language } = useContext(LanguageContext)
  const [error, setError] = useState<string>('')

  return (
    <div>
      <button className="registration__submit-btn" onClick={updateStripeSubscription}>
        {loadingConfirmation ? <div className="loading__spinner"></div> : language.confirmUpgrade}
      </button>
      {error && <p className="upgrade__error-message">{error}</p>}
    </div>
  )
}

export default ConfirmUpgrade
