// import { parse } from "node:path/win32";
// import { parse } from "node:path/win32";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../Context/language";
import { TempAuthContext } from "../../Context/tempAuthContext";
import { PlansContext } from "../../Context/PlansContext";
import PlanList from "./PlanList/PlanList";
import parse from "html-react-parser";
import UpgradePlanList from "./PlanList/UpgradePlanList";
import { userInfo } from "os";

const Pricing: React.FC = (): JSX.Element => {
  const { language } = useContext(LanguageContext);
  const { accessToken } = useContext(TempAuthContext);
  const { prices } = useContext(PlansContext);

  return (
    <section className="section pricing bg-dark-matte" id="pricing">
      {/* <h2 className="pricing-heading" id="pricing-anchor">
        {parse(`${language?.headline}`)}
      </h2> */}
      <div className="pricing__plans-wrapper">
        {accessToken ? (
          <>
            <h2
              className="pricing-heading"
              id="pricing-anchor"
              style={{ width: "100%" }}
            >
              {parse(`${language?.upgradeHeadline}`)}
            </h2>
            <p className="upgrade-subheading">{language?.upgradeSubheading}</p>
            <UpgradePlanList />
          </>
        ) : (
          <>
            <h2 className="pricing-heading" id="pricing-anchor">
              {parse(`${language?.headline}`)}
            </h2>
            <PlanList />
          </>
        )}
      </div>
    </section>
  );
};

export default Pricing;
