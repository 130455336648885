import { useContext } from "react";
import { LanguageContext } from "../../Context/language";
import CtaButton from "../Buttons/CTA/CtaButton";
import ucIcon from "../../Assets/Images/handshake.png";

const UserCentric = () => {
  const { language } = useContext(LanguageContext);

  return (
    <section className="section bg-light user-centric" id="user-centric">
      <div className="user-centric__content-wrapper">
        <div style={{width: "300px", display: "flex", alignItems: "center"}}>
          <img src={ucIcon} alt="" />
        </div>
        <div className="user-centric__text-content-wrapper">
          <h2>{language.aboutHeadline3}</h2>
          <p className="feature__text">{language.about3}</p>
        </div>
      </div>
      {/* <CtaButton link="usercentric" text={language.tellMeMore} internLink={true} boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"/> */}
    </section>
  );
};

export default UserCentric;
