import React, { useEffect, useState, useContext } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { RegisterContext } from '../../Context/RegisterContext'
import { LanguageContext } from '../../Context/language'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { PlansContext } from '../../Context/PlansContext'
const stripePromise = loadStripe('pk_live_kYGub1xSzIrHvx9ljFYCVnbB')

interface RegistrationStep3Props {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  step3Loaded: boolean
  setStep3Loaded: React.Dispatch<React.SetStateAction<boolean>>
}

const RegistrationStep3: React.FC<RegistrationStep3Props> = ({
  setStep,
  step,
  step3Loaded,
  setStep3Loaded,
}): JSX.Element => {
  const { formData, paymentIntentClientSecret } = useContext(RegisterContext)
  const { language } = useContext(LanguageContext)
  const { partnerPrice, stripeCustomerBalance } = useContext(PlansContext)
  const locale = localStorage.getItem("locale")

  useEffect(() => {
    if (!step3Loaded) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'signup_step_3',
      })

      setStep3Loaded(true)
    }
  }, [])

  const onClickBack = () => {
    setStep(2)
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: paymentIntentClientSecret, appearance: { theme: 'night' } }}
    >
      <div className="registration__form-wrapper payment">
        <h2 className="section-heading--light">{language.step3Headline}</h2>
        <span className="registration__step-span">
          {language.step} {step} / 3
        </span>

        {partnerPrice && stripeCustomerBalance > 0 ? (
          <>
            <p style={{ fontSize: '16px', textAlign: 'left' }}>
              {language.step3row1}
              {formData.interval === 'year'
                ? language.step3year
                : formData.interval === 'month'
                  ? language.step3month
                  : language.step3hours}
              <span style={{ color: '#85d990', fontSize: '16px' }}>
                {(formData?.amount - stripeCustomerBalance) / 100 + ' ' + formData?.currency?.toUpperCase()}
              </span>
            </p>
            <p style={{ fontSize: '16px', textAlign: 'left' }}>{locale === "se" ? "Följande period dras " : "Following period we will charge "}
              <span style={{ color: '#85d990', fontSize: '16px' }}>
                {formData?.amount / 100 + ' ' + formData?.currency?.toUpperCase()}
              </span>
            </p>
          </>
        ) : (
          <p style={{ fontSize: '16px', textAlign: 'left' }}>
            {language.step3row1}
            {formData.interval === 'year'
              ? language.step3year
              : formData.interval === 'month'
                ? language.step3month
                : language.step3hours}
            <span style={{ color: '#85d990', fontSize: '16px' }}>
              {formData?.amount / 100 + ' ' + formData?.currency?.toUpperCase()}
            </span>
          </p>
        )}

        <CheckoutForm setStep={setStep} />

        <button
          className="registration__back-btn"
          onClick={onClickBack}
          aria-label="Go back"
          role="button"
          tabIndex={0}
        >
          <div className="registration__back-btn-arrow"></div>
        </button>
      </div>
    </Elements>
  )
}

export default RegistrationStep3
