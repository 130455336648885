import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { api_url } from '../../Constants/constants'
import { TempAuthContext } from '../../Context/tempAuthContext'
import AddPaymentMethod from '../Upgrade/Pay/AddPaymentMethod'

const stripePromise = loadStripe('pk_live_kYGub1xSzIrHvx9ljFYCVnbB')

const AddCard: React.FC = (): JSX.Element => {
  const { getAccount, accessToken } = useContext<any>(TempAuthContext)
  const [intentClientSecret, setIntentClientSecret] = useState(null)
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false)

  const navigate = useNavigate()
  const locale = window.localStorage.getItem('locale')

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    createStripeSetupIntent()
  }, [])

  useEffect(() => {
    if (paymentMethodAdded) {
      getAccount(accessToken)
      navigate('/account', { replace: true })
    }
  }, [paymentMethodAdded])

  const createStripeSetupIntent = async () => {
    const config = {
      method: 'post',
      url: `${api_url}/api/upgrade/setup-intent`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const intentResponse = await axios(config)
    setIntentClientSecret(intentResponse.data.client_secret)
  }

  return (
    <div className="add-card">
      <div className="add-card__navbar">
        <button className="add-card__navbar__back-button" onClick={() => navigate(-1)}>
          {locale === 'se' ? 'Tillbaka till konto' : 'Back to account'}
        </button>
      </div>
      <div className="add-card__content">
        {paymentMethodAdded ? (
          <div> great success</div>
        ) : !intentClientSecret ? (
          <Loader />
        ) : (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: intentClientSecret, appearance: { theme: 'flat' } }}
          >
            <h2 className="add-card__content__heading">
              {' '}
              {locale === 'se' ? 'Betalmetod' : 'Payment method'}
            </h2>
            <AddPaymentMethod
              setPaymentMethodAdded={setPaymentMethodAdded}
              intentClientSecret={intentClientSecret}
              endText={false}
            />
          </Elements>
        )}
      </div>
    </div>
  )
}

export default AddCard
