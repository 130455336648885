import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Register/CheckoutForm";
import { useLocation } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { RegisterContext } from "../../Context/RegisterContext";
import { useContext, useEffect, useState } from "react";
import { api_url } from "../../Constants/constants";
import axios from 'axios';
import Loader from "../../Components/Loader/Loader";
import { TempAuthContext } from "../../Context/tempAuthContext";
import { LanguageContext } from "../../Context/language";
import { PlansContext } from "../../Context/PlansContext";
import PaymentComplete from "../../Components/PaymentComplete/PaymentComplete";
const stripePromise = loadStripe('pk_live_kYGub1xSzIrHvx9ljFYCVnbB')

interface LocationState {
    price?: StripePrice
}

const DiscountCheckout: React.FC = (): JSX.Element => {
    const { paymentIntentClientSecret, setPaymentIntentClientSecret } = useContext(RegisterContext);
    const { stripeCustomerId, userProfile } = useContext(TempAuthContext);
    const { language } = useContext(LanguageContext);
    const { stripeCustomerBalance } = useContext(PlansContext);
    // checkoutForm use number, luring for code
    const [paymentComplete, setPaymentComplete] = useState(0);
    const location = useLocation()
    const locationState: LocationState = location.state
    const price = locationState as StripePrice

    useEffect(() => {
        const createSubscription = async () => {
            let priceId = price.id;
            let customerId = userProfile.customerId
            let currency = price.currency;
            let stripeId = stripeCustomerId;
            try {
                var config = {
                    method: 'post',
                    url: `${api_url}/api/signup/createsubscription`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({
                        customerId,
                        stripeId,
                        priceId,
                        currency
                    }),
                }
                const response = await axios(config)

                if (response.status === 200) {
                    setPaymentIntentClientSecret(response.data.clientSecret)
                }
            } catch (error) {
                console.log(error)
            }
        }
        createSubscription();
    }, [stripeCustomerBalance])

    useEffect(() => {
        if (paymentComplete === 4) {
            fetch(`${api_url}/api/stripe/customer-payment-intent/${userProfile.customerId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                },
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [paymentComplete])

    if (paymentComplete === 4) {
        return (
            <PaymentComplete buttonLink="https://app.staccs.com" internLink={false} buttonText={language.streamNow}/>
        )
    }
    return (
        <div className="discount bg-dark-matte">
            {!paymentIntentClientSecret ? (
                <Loader />
            ) : (
                <div style={{maxWidth: "500px", margin: "auto", minHeight: "70vh", display: "flex", alignItems: "center"}}>
                    <Elements
                        stripe={stripePromise}
                        options={{ clientSecret: paymentIntentClientSecret, appearance: { theme: 'night' } }}
                    >
                        <div className="upgrade__pay-content" style={{maxWidth: "95%", margin: "auto"}}>
                            <h2 className="section-heading--light" style={{margin: "0" }}>{language.step3Headline}</h2>

                            <p style={{ fontSize: '16px', textAlign: 'center', marginBottom: "20px"}}>
                        {language.step3row1}
                        {price?.nickname === 'Yearly'
                            ? language.step3year
                            : price?.nickname === 'Monthly'
                                ? language.step3month
                                : language.step3hours}
                        {price.unit_amount / 100 + stripeCustomerBalance / 100} {price?.currency?.toLocaleUpperCase()}
                    </p>

                            <CheckoutForm setStep={setPaymentComplete}/>
                        </div>
                    </Elements>
                </div>
            )}
        </div>
    )
}

export default DiscountCheckout;