import React, { useState, useRef, useEffect, useContext } from 'react';
import { TempAuthContext } from '../../../Context/tempAuthContext';
import { useParams } from 'react-router-dom';
import videojs from 'video.js';
import axios from 'axios';

import VideoPlayer from './video-player/VideoPlayer';

const PlayScreen = () => {
	const { slug } = useParams();
	const [title, setTitle] = useState({});
	const [signedUrl, setSignedUrl] = useState(null);
	const playerRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const { accessToken } = useContext(TempAuthContext);

	const [videoOptions, setVideoOptions] = useState({
		autoplay: true,
		controls: true,
		responsive: true,
		fluid: true,
	});

	const fetchTitleData = async (titleId) => {
		const config = {
			method: 'get',
			url: `https://devapi.svc.staccs.se/api/video/url?id=${titleId}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		};
		const response = await axios(config);
		setLoading(false);
		return response.data;
	};

	useEffect(() => {
		const getTitleData = async () => {
			const titleData = await fetchTitleData(slug);
			setSignedUrl(titleData.url);
			setTitle(titleData);
		};

		if (accessToken) {
			getTitleData();
		}
	}, [accessToken]);

	useEffect(() => {
		if (signedUrl) {
			setVideoOptions((prev) => ({
				...prev,
				sources: [{ src: signedUrl, type: 'application/x-mpegURL' }],
			}));
		}
	}, [signedUrl]);

	const handlePlayerReady = (player) => {
		playerRef.current = player;

		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
		});
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				alignItems: 'center',
				backgroundColor: 'black',
				height: '100vh',
			}}
		>
			{loading ? (
				<p>loading video</p>
			) : (
				<VideoPlayer options={videoOptions} onReady={handlePlayerReady} />
			)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					alignItems: 'flex-start',
					textAlign: 'left',
					padding: '1rem 2rem',
					backgroundColor: 'rgba(20,20,20,1)',
				}}
			>
				<h1>
					{title.name} - {title.artistName}
				</h1>
				<p>{title.description}</p>
			</div>
		</div>
	);
};

export default PlayScreen;
