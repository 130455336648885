import React, { useContext, useEffect, useState } from 'react';
import {
	useStripe,
	useElements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { LanguageContext } from '../../../Context/language';
import { UpgradeContext } from '../../../Context/UpgradeContext';
import { TempAuthContext } from '../../../Context/tempAuthContext';
import axios from 'axios';
import stripeImg from '../../../Assets/Images/blurple.svg';
import { api_url } from '../../../Constants/constants';

const AddPaymentMethodToCustomer = async (
	paymentMethodId: string,
	stripeId: string
) => {
	const config = {
		method: 'post',
		url: `${api_url}/api/account/payment-method`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: JSON.stringify({
			paymentMethodId,
			stripeId,
		}),
	};
	const response = await axios(config);
	return response.status;
};

interface AddPaymentMethodProps {
	setStep?: React.Dispatch<React.SetStateAction<number>>;
	intentClientSecret: string;
	setPaymentMethodAdded: React.Dispatch<React.SetStateAction<boolean>>;
	endText?: boolean;
}

const AddPaymentMethod: React.FC<AddPaymentMethodProps> = ({
	setStep,
	intentClientSecret,
	setPaymentMethodAdded,
	endText = true,
}) => {
	const { user } = useContext(TempAuthContext);
	const { setSetupConfirmed, setPaymentMethodId } = useContext(UpgradeContext);
	const [inactiveButton, setInactiveButton] = useState<boolean>(false);
	const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const locale = window.localStorage.getItem('locale');

	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = intentClientSecret;

		if (!clientSecret) {
			return;
		}
	}, [stripe]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setError('');

		if (!stripe || !elements) {
			return;
		}

		setInactiveButton(true);
		setLoadingPayment(true);
		confirmSetupIntent();
	};

	const confirmSetupIntent = async () => {
		const payload = await stripe.confirmSetup({
			elements,
			redirect: 'if_required',
		});

		if (payload.error) {
			setError(`Payment failed: ${payload.error.message}`);
			setInactiveButton(false);
		} else {
			const paymentAddedStatus = await AddPaymentMethodToCustomer(
				payload.setupIntent.payment_method as string,
				user.stripeId
			);
			if (paymentAddedStatus === 200) {
				setPaymentMethodId(payload.setupIntent.payment_method as string);
				setSetupConfirmed(true);
				setPaymentMethodAdded(true);
			} else {
				setError(`Payment failed: ${payload.error.message}`);
			}
		}
		setInactiveButton(false);
		setLoadingPayment(false);
	};

	const handleChange = async (event: any) => {
		setError(event.error ? event.error.message : '');
		setLoadingPayment(false);
	};

	return (
		<fieldset>
			<form className="checkout-form" onSubmit={handleSubmit}>
				<div className="payment-element-wrapper">
					<PaymentElement
						onChange={handleChange}
						options={{
							wallets: { applePay: 'never', googlePay: 'never' },
						}}
					/>
				</div>
				<img
					style={{
						maxWidth: 120,
					}}
					src={stripeImg}
					alt="powered by stripe"
				/>
				<p className="end-text">
					{endText
						? 'Before we can give you access you need to provide a credit card for us to charge for your subscription. If requiered, you will have to identify yourself through your bank.'
						: null}
				</p>

				<button
					className="registration__submit-btn"
					type="submit"
					disabled={!stripe || inactiveButton}
				>
					{loadingPayment ? (
						<div className="loading__spinner"></div>
					) : locale === 'se' ? (
						'Lägg till betalmetod'
					) : (
						'Add payment method'
					)}
				</button>
				{error && <p className="upgrade__error-message">{error}</p>}
			</form>
		</fieldset>
	);
};

export default AddPaymentMethod;
