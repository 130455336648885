import React, { useEffect, useContext } from "react";
import { RegisterContext } from "../../Context/RegisterContext";
import parse from "html-react-parser";
import { LanguageContext } from "../../Context/language";
import { useLocation, useNavigate } from "react-router-dom";
import { TempAuthContext } from "../../Context/tempAuthContext";

interface RegistrationStep4Props {
  step4Loaded: boolean;
  setStep4Loaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LocationState {
  nickname?: string;
}

const RegistrationStep4: React.FC<RegistrationStep4Props> = ({
  step4Loaded,
  setStep4Loaded,
}): JSX.Element => {
  // CONTEXT
  const { formData } = useContext(RegisterContext);
  const { language } = useContext(LanguageContext);
  const { getNewAccessToken } = useContext(TempAuthContext);
  const locale = localStorage.getItem("locale")
  const refreshToken = localStorage.getItem("refresh_token")
  const location = useLocation()
  const locationState: LocationState = location.state
  const navigate = useNavigate();

  useEffect(() => {
    var o = (window.tdl = window.tdl || []);
    o.convert({ amount: "2", adv_sub: formData.uuid });

    if (!step4Loaded) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "complete_registration",
      });

      setStep4Loaded(true);
    }
  }, []);

  const handleClick = () => {
    getNewAccessToken(refreshToken);
    navigate("/discovery");
  }

  return (
    <div className="registration__form-wrapper">
      <div className="registration__welcome-info">
        <h2 style={{ marginBottom: "5px" }} className="section-heading--light">{language.step4Headline}</h2>
        {formData?.priceId === "price_1LpvE0FJBmxpVNzMrsquIYjT" || formData?.priceId === "price_1LziufFJBmxpVNzMINThILNh" || locationState?.nickname === "Member" ? (
          <>
          <p className="registration__welcome-info-text" style={{marginBottom: "20px"}}>
            {language.step4row1}
            <br />
            <br />
            {locale === "se" ? (
              <>
                {parse(`Klicka nedan för att se vårat Discovery flöde med allt medlemsinnehåll.`)}
              </>
            ) : (
              <>
                {parse(`Click below to see our Discovery mode with all member content.`)}
              </>
            )}
          </p>
            <button
              onClick={() => handleClick()}
              className="cta-button"
              style={{
                backgroundColor: '#FFC65A',
                fontFamily: 'Poppins',
                fontSize: "17px",
                margin: "auto",
                width: "250px",
              }}
              >
              {language.discover}
            </button>
            <br/>
              </>
        ) : (
          <>
            <p className="registration__welcome-info-text">
              {language.step4row1}
              <br />
              <br />
              {parse(`${language.step4row2}`)}
            </p>
            <div className="registration__app-links">
              <a
                href="https://apps.apple.com/us/app/staccs/id1566144790"
                target="_blank"
                rel="noreferrer"
                className="registration__app-link"
              >
                <img
                  src="/images/appstore.svg"
                  alt={language.footerDownload}
                  className="registration__app-link-icon"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.staccs"
                target="_blank"
                rel="noreferrer"
                className="registration__app-link"
              >
                <img
                  src="/images/googleplay.png"
                  alt={language.footerDownload}
                  className="registration__app-link-icon"
                />
              </a>
            </div>
          </>
        )}
        <p className="registration__welcome-info-footer">
          {language.step4row3} <br />
          Staccs
        </p>
      </div>
    </div>
  );
};

export default RegistrationStep4;
