import { useContext } from 'react';
import Feature from './Feature/Feature';
import setlistImg from '../../Assets/Images/setlist.png';
import bigScreenImg from '../../Assets/Images/Larsa.png';
import supportImg from '../../Assets/Images/support.jpg';
import { LanguageContext } from '../../Context/language';

const Features: React.FC = (): JSX.Element => {
	const { language } = useContext(LanguageContext);

	const features = [
		{
			heading: language.bigScreen,
			text: language.bigScreenSub,
			image: bigScreenImg,
		},
		{
			heading: language.setlist,
			text: language.setlistSub,
			image: setlistImg,
		},
		{
			heading: language.originals,
			text: language.originalsSub,
			image: supportImg,
		},
	];

	return (
		<section className="section features" id="features">
			{features.map((feature, index) => (
				<Feature key={index} feature={feature} />
			))}
		</section>
	);
};

export default Features;
