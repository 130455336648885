import { useContext, useEffect } from 'react';
import Navbar from './navbar/Navbar';
import Content from './content/Content';
import { HeaderContext } from '../../Context/HeaderContext';
import Footer from '../Footer/Footer';

function Discovery() {
	const { setHeaderDisplayed } = useContext(HeaderContext);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setHeaderDisplayed('discover');

		return () => setHeaderDisplayed('landing');
	}, []);

	return (
		<div className="discovery">
			{/* <Navbar /> */}
			<Content />
		</div>
	);
}

export default Discovery;
