import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import { RegisterContext } from '../../Context/RegisterContext';
import { LanguageContext } from '../../Context/language';
import { api_url } from '../../Constants/constants';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { GoogleLogin } from '../../Utils/helpers/GoogleOauth2';
import { HeaderContext } from '../../Context/HeaderContext';

interface RegistrationStep1Props {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	step: number;
	setStep1Complete: React.Dispatch<React.SetStateAction<boolean>>;
	step1Complete: boolean;
	setStep1Loaded: React.Dispatch<React.SetStateAction<boolean>>;
	step1Loaded: boolean;
	setTempCustomerId: React.Dispatch<React.SetStateAction<string>>;
	setTempStripeId: React.Dispatch<React.SetStateAction<string>>;
}

interface LocationState {
	priceId?: string;
	nickname?: string;
}

const RegistrationStep1: React.FC<RegistrationStep1Props> = ({
	setStep,
	step,
	setStep1Complete,
	step1Complete,
	step1Loaded,
	setStep1Loaded,
	setTempCustomerId,
	setTempStripeId,
}): JSX.Element => {
	const {
		formData,
		setFormData,
		termsAccepted,
		setTermsAccepted,
		createGoogleUser,
	} = useContext(RegisterContext);
	const { setHeaderDisplayed } = useContext(HeaderContext);
	const { signIn, setAccessToken, setRefreshToken, setOldToken } =
		useContext(TempAuthContext);
	const { name, email, password } = formData;
	const { language } = useContext(LanguageContext);
	const [disableButton, setDisableButton] = useState<boolean>(false);
	const [showEmailError, toggleEmailError] = useState<boolean>(false);
	const [showPasswordError, togglePasswordShowError] = useState<boolean>(false);
	const [regErrorMsg, setRegErrorMsg] = useState<string>('');
	const locale = localStorage.getItem('locale');

	const location = useLocation();
	const locationState: LocationState = location.state;

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (!step1Loaded) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'signup_step_1',
			});
			setStep1Loaded(true);
			setHeaderDisplayed('landing');
		}
	}, []);

	useEffect(() => {
		let currency;
		if (locale === 'se') {
			currency = 'sek';
		} else if (locale === 'no') {
			currency = 'nok';
		} else if (locale === 'dk') {
			currency = 'dkk';
		} else if (
			locale === 'fi' ||
			locale === 'ee' ||
			locale === 'lv' ||
			locale === 'lt' ||
			locale === 'ax'
		) {
			currency = 'eur';
		}
		setFormData({ ...formData, currency });
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();
		toggleEmailError(false);
		togglePasswordShowError(false);
		setDisableButton(true);

		let customerId: string;
		let stripeId: string;
		if (createGoogleUser) {
			const googleData = await googleHandler();
			customerId = googleData?.customerId;
			stripeId = googleData?.stripeId;
		} else {
			const accountData = await createAccount();
			customerId = accountData?.customerId;
			stripeId = accountData?.stripeId;
		}
		if (customerId && stripeId) {
			// PARTNER PRICE BABY
			if (locationState?.priceId) {
				setFormData({
					...formData,
					partnerPrice: locationState?.priceId,
				});
				setStep1Complete(true);
				setStep(2);
			} else if (locationState?.nickname === 'Member') {
				// hardcoded ID's hell yes!
				let memberPriceId = 'price_1LziufFJBmxpVNzMINThILNh';
				try {
					var config = {
						method: 'post',
						url: `${api_url}/api/signup/createfreemium`,
						headers: {
							'Content-Type': 'application/json',
						},
						data: {
							customerId: customerId,
							stripeId: stripeId,
							priceId: memberPriceId,
							currency: formData.currency,
						},
					};
					const freemiumResponse = await axios(config);

					if (freemiumResponse.status === 200) {
						if (!createGoogleUser) {
							signIn(formData.email, formData.password);
						}
						setStep(4);
					} else {
						setRegErrorMsg('Something went wrong...');
					}
				} catch (error) {
					setRegErrorMsg('Something went wrong...');
					console.log(error);
				}
			} else {
				setStep1Complete(true);
				setStep(2);
			}
		} else {
			return;
		}
	};

	const googleHandler = async () => {
		setDisableButton(true);
		var config = {
			method: 'post',
			url: `${api_url}/api/auth/google-create`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				name: formData.name,
				email: formData.email,
				country: locale,
			},
		};

		const res = await axios(config);

		if (res.status === 201) {
			setAccessToken(res.data.tokens.accessToken);
			setRefreshToken(res.data.tokens.refreshToken);
			setOldToken(res.data.tokens.oldToken);
			setTempCustomerId(res.data?.userId);
			setTempStripeId(res.data?.stripeId);
		} else {
			setDisableButton(false);
		}
		return {
			customerId: res.data.userId,
			stripeId: res.data.stripeId,
		};
	};

	const createAccount = async () => {
		const response = await fetch(`${api_url}/api/signup/createaccount`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...formData,
				paymentType: 'stripe',
				country: locale,
			}),
		});

		const createAccountData = await response.json();

		if (response.status === 400) {
			if (createAccountData.msg === 'Password is too short') {
				togglePasswordShowError(true);
				setRegErrorMsg(createAccountData.msg);
				setFormData({ ...formData, password: '' });
			} else {
				toggleEmailError(true);
				setRegErrorMsg(createAccountData.msg);
			}
			setDisableButton(false);
			return;
		}
		setTempCustomerId(createAccountData?.userId);
		setTempStripeId(createAccountData?.stripeId);
		return {
			customerId: createAccountData.userId,
			stripeId: createAccountData.stripeId,
		};
	};

	return (
		<div className="registration__form-wrapper">
			<h2 className="section-heading--light">{language.accountDetails}</h2>
			{!createGoogleUser && (
				<>
					<div style={{ marginTop: '20px' }}>
						<GoogleLogin nightwish={false} />
					</div>
					<span className="registration__form-divider">or</span>
				</>
			)}

			<form className="registration__form" onSubmit={onSubmit}>
				<div className="registration__input-wrapper">
					<input
						className="registration__form-input"
						type="text"
						id="name"
						name="name"
						required
						value={name}
						disabled={step1Complete}
						onChange={(e) => onInputChange(e)}
					/>
					<label className="registration__form-label" htmlFor="name">
						{language.name}
					</label>
				</div>
				<div className="registration__input-wrapper">
					<input
						type="email"
						id="email"
						name="email"
						required
						value={email}
						disabled={step1Complete}
						onChange={(e) => onInputChange(e)}
						className={
							showEmailError
								? 'registration__form-input--error'
								: 'registration__form-input'
						}
					/>
					<label className="registration__form-label" htmlFor="email">
						{language.email}
					</label>
				</div>

				{!createGoogleUser && (
					<div className="registration__input-wrapper">
						<input
							type="password"
							id="password"
							name="password"
							required
							disabled={step1Complete}
							value={password}
							onChange={(e) => onInputChange(e)}
							className={
								showPasswordError
									? 'registration__form-input--error'
									: 'registration__form-input'
							}
						/>
						<label className="registration__form-label" htmlFor="password">
							{language.password}
						</label>
					</div>
				)}

				{showEmailError ? (
					<p className="registration__error-message">{regErrorMsg}</p>
				) : showPasswordError ? (
					<p className="registration__error-message">{regErrorMsg}</p>
				) : null}

				<div
					className={'registration__terms-wrapper'}
					style={{ marginBottom: '2rem' }}
				>
					<div
						role="checkbox"
						tabIndex={0}
						className="registration__terms-checkbox"
						onClick={() => {
							setTermsAccepted(!termsAccepted);
						}}
						aria-checked={termsAccepted}
						aria-label={'accept terms'}
					>
						{termsAccepted ? '✓' : ''}
					</div>

					{parse(
						`<span class="registration__terms-span" style="margin-left: 20px">${language.acceptTerms}</span>`
					)}
				</div>
				{createGoogleUser ? (
					<button
						type="submit"
						className="registration__submit-btn"
						disabled={!name || !email || !termsAccepted || disableButton}
					>
						{disableButton ? (
							<div className="loading__spinner"></div>
						) : (
							language.createAccountButton
						)}
					</button>
				) : (
					<button
						type="submit"
						className="registration__submit-btn"
						disabled={
							!name || !email || !password || !termsAccepted || disableButton
						}
					>
						{disableButton ? (
							<div className="loading__spinner"></div>
						) : (
							language.createAccountButton
						)}
					</button>
				)}
			</form>
		</div>
	);
};

export default RegistrationStep1;
