import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import RegistrationStep1 from './RegistrationStep1';
import RegistrationStep2 from './RegistrationStep2';
import RegistrationStep3 from './RegistrationStep3';
import RegistrationStep4 from './RegistrationStep4';
import { LanguageContext } from '../../Context/language';
import bgRegister from '../../Assets/Images/hero-bg_old.jpg';
import NotAvailable from './NotAvailable/NotAvailable';
import Loader from '../../Components/Loader/Loader';
import { PlansContext } from '../../Context/PlansContext';
import parse from 'html-react-parser';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { RegisterContext } from '../../Context/RegisterContext';

interface LocationState {
	priceId?: string;
	collabPrices?: [string];
	discountPrice?: string;
	discountCoupon?: string;
}

const Register: React.FC = (): JSX.Element => {
	const { language, access, country } = useContext(LanguageContext);
	const { stripePricesLoading, getPartnerPrice, getCollabPrices } =
		useContext(PlansContext);
	const { accessToken, userProfile, stripeCustomerId } =
		useContext(TempAuthContext);
	const { formData, setFormData, createGoogleUser } =
		useContext(RegisterContext);

	const [step1Complete, setStep1Complete] = useState<boolean>(false);
	const [step1Loaded, setStep1Loaded] = useState<boolean>(false);
	const [step2Loaded, setStep2Loaded] = useState<boolean>(false);
	const [step3Loaded, setStep3Loaded] = useState<boolean>(false);
	const [step4Loaded, setStep4Loaded] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);

	const [tempCustomerId, setTempCustomerId] = useState<string>('');
	const [tempStripeId, setTempStripeId] = useState<string>('');

	const location = useLocation();
	const locationState: LocationState = location.state;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [step]);

	useEffect(() => {
		// finding partner & collab price
		if (locationState?.priceId) {
			getPartnerPrice(locationState?.priceId, country);
		} else if (locationState?.collabPrices) {
			getCollabPrices(locationState?.collabPrices, country);
		} else if (locationState?.discountPrice) {
			getPartnerPrice(locationState?.discountPrice, country);
		}
	}, []);

	useEffect(() => {
		if (
			accessToken &&
			userProfile &&
			stripeCustomerId &&
			step !== 4 &&
			!createGoogleUser
		) {
			setFormData({
				...formData,
				customerId: userProfile.customerId,
				stripeId: stripeCustomerId,
			});
			setStep(2);
		}
	}, [userProfile]);

	let displayStep;
	switch (step) {
		case 1:
			displayStep = (
				<RegistrationStep1
					setStep={setStep}
					step={step}
					setStep1Complete={setStep1Complete}
					step1Complete={step1Complete}
					setStep1Loaded={setStep1Loaded}
					step1Loaded={step1Loaded}
					setTempCustomerId={setTempCustomerId}
					setTempStripeId={setTempStripeId}
				/>
			);
			break;

		case 2:
			displayStep = (
				<RegistrationStep2
					step={step}
					setStep={setStep}
					step2Loaded={step2Loaded}
					setStep2Loaded={setStep2Loaded}
					tempCustomerId={tempCustomerId}
					tempStripeId={tempStripeId}
				/>
			);
			break;

		case 3:
			displayStep = (
				<RegistrationStep3
					setStep={setStep}
					step={step}
					step3Loaded={step3Loaded}
					setStep3Loaded={setStep3Loaded}
				/>
			);
			break;

		case 4:
			displayStep = (
				<RegistrationStep4
					step4Loaded={step4Loaded}
					setStep4Loaded={setStep4Loaded}
				/>
			);
			break;
	}

	if (stripePricesLoading) {
		return (
			<div
				style={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#191919',
					display: 'flex',
					alignContent: 'center',
					justifyContent: 'center',
					alignItems: 'center',
					background: `linear-gradient(110deg, rgba(0, 0, 0, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
          rgba(0, 0, 0, 0.85) 85%), url(${bgRegister}) no-repeat center center/cover`,
				}}
			>
				<Loader />
			</div>
		);
	}

	if (!access) {
		return <NotAvailable />;
	}

	return (
		<section className="registration">
			<div className="registration__left">
				<h2 className="section-heading--light">
					{parse(`${language.headline}`)}
				</h2>
				<h3 className="section-subheading--light">
					{parse(`${language.subHeadline}`)}
				</h3>
			</div>
			<div className="registration__right">{displayStep}</div>
		</section>
	);
};

export default Register;
