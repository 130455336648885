import { Link } from 'react-router-dom';
import { Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

interface FeaturedTitlesRowProps {
	staffPicks: StaffPick;
	fromX: number;
	toX: number;
}

const FeaturedTitlesRow: React.FC<FeaturedTitlesRowProps> = ({
	staffPicks,
	fromX,
	toX,
}) => {
	return (
		<Scene duration="200%" triggerElement="#featured-titles" triggerHook={1}>
			<Timeline>
				<Tween from={{ x: `${fromX}%` }} to={{ x: `${toX}%` }}>
					<div className="featured-titles__row bg-dark">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								width: '180%',
							}}
						>
							{staffPicks?.titles?.map((title) => (
								<div
									key={title._id}
									className="featured-titles__title-wrapper"
									style={{
										maxWidth: '40%',
										margin: 10,
									}}
								>
									<Link to={`/title/${title._id}`}>
										{title.graphics.landscape ? (
											<img
												className="dvdShadow featured-titles__title-image"
												src={`https://www.staccs.com/cdn-cgi/image/quality=85,h=300/${title.graphics.landscape}`}
												alt={title.displayName}
											/>
										) : (
											<img
												className="dvdShadow featured-titles__title-image"
												src={`https://www.staccs.com/cdn-cgi/image/quality=85,h=300/${title.graphics.dvd}`}
												alt={title.displayName}
											/>
										)}
									</Link>
								</div>
							))}
						</div>
					</div>
				</Tween>
			</Timeline>
		</Scene>
	);
};

export default FeaturedTitlesRow;
