import { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../Context/language";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";

const PasswordReset: React.FC = (): JSX.Element => {
    const [resetToken, setResetToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [feedbackError, setFeedbackError] = useState("");
    const [passwordIsReset, setPasswordIsReset] = useState(false);
    const [loading, isLoading] = useState(true);
    const [tokenValid, setTokenValid] = useState(false);
    const { language } = useContext(LanguageContext);
    let locale = localStorage.getItem("locale");
    const navigate = useNavigate();


    useEffect(() => {
        const incomingResetToken = window.location.search.split('=')[1]
        setFeedbackError("");
        if (incomingResetToken) {
            setResetToken(incomingResetToken)
            const checkResetToken = async () => {
                const res = await fetch(
                    `https://api.svc.staccs.se/api/user/check-reset-token`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                          },
                        method: "POST",
                        body: JSON.stringify({ token: incomingResetToken }),
                    }
                );
                const tokenIsValid = await res.json();
                setTokenValid(tokenIsValid);
                isLoading(false);
            }
            checkResetToken();
        }
        else {
            isLoading(false);
            navigate("/account");
        }
    }, [])

    const resetPassword = async (e) => {
        e.preventDefault();
        if (newPassword.length < 8) {
            setFeedbackError("Password is too short, at least 8 characters.")
        } else if (newPassword === confirmNewPassword) {
            try {
                const res = await fetch(
                    `https://api.svc.staccs.se/api/user/reset-password`,
                    {
                        method: "POST",
                        body: JSON.stringify({ password: newPassword }),
                        headers: {
                            Authorization: "Bearer " + resetToken,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const passwordReset = await res.json();

                if (res.status !== 200) {
                    setFeedbackError("Something went wrong, your password is unchanged.");
                } else {
                    setFeedbackError("Your password has been updated!");
                    setPasswordIsReset(true);
                    const timer = setTimeout(() => {
                        navigate("/account");
                      }, 2000);
                      return () => clearTimeout(timer);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            setFeedbackError("Passwords did not match");
        }

    }
    if (loading) {
        return (
            <div
                style={{
                    height: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Loader />
            </div>
        )
    }

    if (!tokenValid) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    height: "90vh"
                }}
            >
                <h2 style={{ color: "#121212", fontSize: "3rem" }}>This reset link has expired.</h2>
            </div>
        )
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                height: "90vh"
            }}
        >
            <h2
                style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: "25px",
                    textAlign: "center",
                    marginBottom: "10px",
                    color: "#121212"
                }}
            >
                {language.resetPassword}
            </h2>
            <form
                onSubmit={(e) => resetPassword(e)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                    maxWidth: "226px",
                    width: "100%",
                }}
            >
                <TextField
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="outlined-basic"
                    label={locale === "se" ? "Nytt lösenord" : "New password"}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{ style: { fontSize: "12px"}}}
                    InputProps={{ style: { fontSize: "12px" }}}
                    type="password"
                    autoFocus={true}
                />

                <TextField
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className="outlined-basic"
                    label={locale === "se" ? "Bekräfta lösenord" : "Confirm password"}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{ style: { fontSize: "12px"}}}
                    InputProps={{ style: { fontSize: "12px" }}}
                    type="password"
                />
                {feedbackError && (
                    <p style={{ fontSize: "1.3rem", color: "black" }}>{feedbackError}</p>
                )}
                <Button
                    variant="contained"
                    style={{
                        maxWidth: "300px",
                        width: "100%",
                        height: "45px",
                        backgroundColor: "#ffc65a",
                        fontSize: "13px",
                    }}
                    disabled={!newPassword || !confirmNewPassword || passwordIsReset}
                    type="submit"
                >
                    {locale === "se" ? "Uppdatera lösenord" : "Update password"}
                </Button>

            </form>
        </div>
    )
}

export default PasswordReset;

