import { useState, useEffect } from 'react';
import { Controller } from 'react-scrollmagic';
import FeaturedTitlesRow from './FeaturedTitlesRow/FeaturedTitlesRow';
import { api_url } from '../../Constants/constants';

const FeaturedTitles: React.FC = (): JSX.Element => {
	const [staffPicks, setStaffPicks] = useState<StaffPick>();
	const [staffPicks2, setStaffPicks2] = useState<StaffPick>();

	useEffect(() => {
		const getStaff = async () => {
			const getStaffPicks = await fetch(`${api_url}/api/features/topTen`);
			const staffData = await getStaffPicks.json();
			setStaffPicks(await staffData);
			const getStaffPicks2 = await fetch(
				`${api_url}/api/features/documentaries`
			);
			const staffData2 = await getStaffPicks2.json();
			setStaffPicks2(await staffData2);
		};
		getStaff();
	}, []);

	return (
		<section className="section featured-titles bg-dark" id="featured-titles">
			<Controller>
				<FeaturedTitlesRow staffPicks={staffPicks} fromX={0} toX={-20} />
				<FeaturedTitlesRow staffPicks={staffPicks2} fromX={-40} toX={0} />
			</Controller>
		</section>
	);
};

export default FeaturedTitles;
