import React from "react";

import Card from "./Card";

function ShortBar({ items }) {
  return (
    <div style={{ backgroundColor: "black", padding: "2px 0px" }}>
      <div
        style={{ textAlign: "left", paddingLeft: "7px", fontWeight: "bold" }}
      >
        Shorts
      </div>
      <div
        className="noscroll"
        style={{
          overflowX: "scroll",
          maxWidth: "100%",
          backgroundColor: "black",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "fit-content",
          }}
        >
          {items.map((item) => (
            <Card item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShortBar;
