import { useContext, useEffect, useCallback, useState, useRef } from 'react';
import isMobile from 'is-mobile';
import { LanguageContext } from '../../Context/language';
import logo from '../../logo.svg';
import logoMobile from '../../logo.svg';
import CtaButton from '../Buttons/CTA/CtaButton';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { HeaderContext } from '../../Context/HeaderContext';
import { DialogContext } from '../../Context/DialogContext';
import { elementToggle } from '../../Utils/helpers/dom.helpers';
import Drawer from './Drawer';
import { useLocation } from 'react-router-dom';

const Header: React.FC = (): JSX.Element => {
	const { language, access } = useContext(LanguageContext);
	const { accessToken, appRedirectToken, createAccountToken, logout } =
		useContext<any>(TempAuthContext);
	const { headerDisplayed } = useContext(HeaderContext);
	const { setView, toggleSignupDialogOpen } = useContext(DialogContext);
	const [onMobile, setOnMobile] = useState(false);

	const openSignInModal = () => {
		toggleSignupDialogOpen(true);
		setView('SIGNIN');
	};

	const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
	const navbarRef = useRef(null);

	// HIDE HEADER
	const handleScroll = useCallback(() => {
		const currentScrollPos = window.pageYOffset;
		if (currentScrollPos === 0) {
			navbarRef.current.style.backgroundColor = 'transparent';
		}

		if (window.pageYOffset > 300 && window.location.pathname !== '/nightwish') {
			if (prevScrollPos > currentScrollPos) {
				elementToggle(navbarRef.current, 'top', '0');
				navbarRef.current.ariaHidden = 'false';
			} else {
				elementToggle(navbarRef.current, 'top', '-150px');
				navbarRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
				navbarRef.current.ariaHidden = 'true';
			}
		}
		setPrevScrollPos(currentScrollPos);
	}, [prevScrollPos]);

	useEffect(() => {
		setOnMobile(isMobile());
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos]);

	useEffect(() => {
		if (accessToken) {
			createAccountToken();
		}
	}, [accessToken]);

	console.log(window.location.pathname);

	return (
		<>
			{!window.location.pathname.includes('/partners') && (
				<header>
					<div className="header__nav-wrapper" ref={navbarRef}>
						<a href="/">
							<img
								src={onMobile ? logoMobile : logo}
								alt="Staccs"
								className="logo"
							/>
						</a>
						{onMobile ? (
							<Drawer />
						) : (
							<nav className="header__nav">
								<ul id="main-menu" className="header__main-menu">
									{headerDisplayed === 'landing' && access && (
										<li className="main-menu__li">
											{accessToken ? (
												<CtaButton
													text={language.streamNow}
													textColor="black"
													link={`https://app.staccs.com?token=${appRedirectToken}`}
													boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
													bgColor="#ffd85b"
													internLink={false}
												/>
											) : (
												<CtaButton
													text={language.signUp}
													textColor="black"
													link="register"
													bgColor="#ffd85b"
													boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
													internLink={true}
												/>
											)}
										</li>
									)}

									<li className="main-menu__li">
										{accessToken && access ? (
											<CtaButton
												text={language.account}
												link="account"
												internLink={true}
												boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
											/>
										) : accessToken && !access ? (
											<button
												onClick={logout}
												className="cta-button"
												style={{
													alignSelf: 'center',
													padding: '9.5px 20px',
													fontFamily: 'Poppins',
													justifySelf: 'center',
													margin: 'auto',
													boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
												}}
											>
												{language.signOut}
											</button>
										) : (
											<button
												onClick={() => openSignInModal()}
												className="cta-button"
												style={{
													alignSelf: 'center',
													padding: '9.5px 20px',
													fontFamily: 'Poppins',
													justifySelf: 'center',
													margin: 'auto',
													boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
												}}
											>
												{language.signIn}
											</button>
										)}
									</li>
								</ul>
							</nav>
						)}
					</div>
				</header>
			)}
		</>
	);
};

export default Header;
