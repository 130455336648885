import React from "react";

import { useInView } from "react-intersection-observer";

function Card({ item }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <div
      ref={ref}
      style={{
        // backgroundColor: "red",
        width: "150px",
        height: "200px",

        // height: "250px",
        // padding: "10px",
        padding: "0px",
        borderRadius: "5px",
        margin: "5px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {inView ? (
        <>
          <img src={item.imgUrl} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              alignItems: "center",
              height: "30px",
              backgroundColor: "rgba(20,20,20,0.7)",
              color: "white",
              position: "absolute",
              width: "100%",
              bottom: "0px",
              borderRadius: "0px 0px 5px 5px",
            }}
          >
            The Nights
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Card;
