import React, { useContext } from 'react';
import CtaButton from '../Buttons/CTA/CtaButton';
import backgroundImage from '../../Assets/Images/david.jpeg'
import { LanguageContext } from '../../Context/language';

interface PaymentCompleteProps {
    buttonLink: string;
    buttonText: string;
    internLink: boolean;
}

const PaymentComplete: React.FC<PaymentCompleteProps> = ({ buttonLink, buttonText, internLink }): JSX.Element => {

    const { language } = useContext(LanguageContext);

    return (
        <div className="discount bg-dark-matte" style={{
            minHeight: "100vh",
            backgroundImage: `url(${backgroundImage
                })`,
        }}>
            <div style={{ backgroundColor: "rgba(1, 1, 1, 0.2)", padding: "15px 50px 15px 50px", borderRadius: "10px"}}>
                <h2 className="section-heading--light" style={{ fontSize: "40px", margin: 0 }}>{language.step4Headline}</h2>
                <p className="discount-text" style={{ marginBottom: "20px" }}>
                    {language.step4row1}
                </p>
                <CtaButton bgColor={"#FFC65A"} text={buttonText} internLink={internLink} link={buttonLink} />
                <div className="registration__app-links">
                    <a
                        href="https://apps.apple.com/us/app/staccs/id1566144790"
                        target="_blank"
                        rel="noreferrer"
                        className="registration__app-link"
                    >
                        <img
                            src="/images/appstore.svg"
                            alt={language.footerDownload}
                            className="registration__app-link-icon"
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.staccs"
                        target="_blank"
                        rel="noreferrer"
                        className="registration__app-link"
                    >
                        <img
                            src="/images/googleplay.png"
                            alt={language.footerDownload}
                            className="registration__app-link-icon"
                        />
                    </a>
                </div>
                <p
                    className="discount-text"
                >
                    {language.step4row3} <br />
                    Staccs
                </p>
            </div>
        </div>
    )
}

export default PaymentComplete;