// DEPENDENCIES
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// COMPONENTS
import Features from "../../Components/Features/Features";
import ArtistHero from "./ArtistHero/ArtistHero";
import ArtistInfo from "./ArtistInfo/ArtistInfo";
import Loader from "../../Components/Loader/Loader";
import ArtistTitles from "./ArtistTitles/ArtistTitles";

// CONTEXT
import { LanguageContext } from "../../Context/language";
import { ArtistCollabContext } from "../../Context/artistCollabContext";
import CtaContainer from "./CtaContainer/CtaContainer";

const ArtistCollaboration: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { language, country } = useContext(LanguageContext);
  const { artistCollabs } = useContext(ArtistCollabContext);
  const locale = window.localStorage.getItem("locale");
  const [loading, setLoading] = useState<boolean>(true);
  const [artistCollabCtaValue, setArtistCollabCtaValue] = useState<string>("");
  const [artistCollabLanguage, setArtistCollabLanguage] = useState<string>("");

  const pathName: string = location.pathname.split("/")[1];
  const artistCollab: ArtistCollab = artistCollabs?.artistCollabs?.filter(
    (collab) => collab.route == pathName
  )[0];
  const navigate = useNavigate();

  useEffect(() => {
    if (country !== "se") {
      setArtistCollabLanguage("en");
    } else {
      setArtistCollabLanguage("se");
    }
    artistCollab?.titles?.map((title) => {
      return setArtistCollabCtaValue(title?.texts?.[artistCollabLanguage]?.cta);
    });
    setLoading(false);
  }, [loading]);

  function submit(e) {
    e.preventDefault();
    const data = {
      collabPrices: artistCollab.priceId
    }
    if (data.collabPrices) {
      navigate("/register", {
        state: data
      });
    } else {
      navigate("/register");
    }
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: pathName,
    });
    if (window.location.search === "?staccs") {
      document.cookie = "campaign=staccs; max-age=604800; path=/";
    } else {
      document.cookie = `campaign=${pathName}; max-age=604800; path=/`;
    }
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        {/* Hero */}
        <ArtistHero
          artistCollab={artistCollab}
          artistCollabLanguage={artistCollabLanguage}
          ctaText={artistCollabCtaValue}
          pathName={pathName}
        />

        {/* Artist presentation */}
        <ArtistInfo
          artistCollab={artistCollab}
          artistCollabLanguage={artistCollabLanguage}
        />

        {/* CTA container */}
        <CtaContainer
          bgColor={artistCollab?.backgroundColor}
          fontColor={artistCollab?.bodyFontColor}
          buttonColor={artistCollab?.buttonColor}
          buttonTextColor={artistCollab?.buttonTextColor}
          onClick={submit}
          ctaText={artistCollabCtaValue}
          ctaBtnText={
            locale === "se" ? "Använd erbjudandet" : "Claim offer"
          }
        />

        {/* Title presentation */}
        <ArtistTitles
          artistCollab={artistCollab}
          artistCollabLanguage={artistCollabLanguage}
        />
        <Features />
      </>
    );
  }
};

export default ArtistCollaboration;
