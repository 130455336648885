import CtaButton from '../../../Buttons/CTA/CtaButton';

function HomeCard() {
	return (
		<section
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<CtaButton
				text={'Enter Staccs'}
				textColor="black"
				link="/home"
				boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
				bgColor="#ffd85b"
				internLink
			/>
		</section>
	);
}

export default HomeCard;
