import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { LanguageContext } from "../../../Context/language";

/**
 * Renders page Hero for artist collaboration pages
 * @param   {Object}            artistCollab    Artist collab object(LangContext)
 * @returns {HTMLDivElement}                    Hero element
 */
const ArtistHero = ({ artistCollab, artistCollabLanguage, pathName, ctaText }) => {
  // CONTEXT
  const { language, country } = useContext(LanguageContext);
  const locale = window.localStorage.getItem("locale");
  // COMPONENT STATE
  const [offsetY, setOffsetY] = useState(0);
  const [useHeroLogoImage, setUseHeroLogoImage] = useState(false);
  const [heroTextFirstName, setHeroTextFirstName] = useState("");
  const [heroTextLastName, setHeroTextLastName] = useState("");
  const [heroTextName, setHeroTextName] = useState("");
  const [heroTextTitle, setHeroTextTitle] = useState("");

  const offsetValues = [0.8, 0.6, 0.2];
  const navigate = useNavigate();

  // Handle scroll parallax effect
  const handleScroll = () => setOffsetY(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setUseHeroLogoImage(artistCollab?.useHeroLogoImage);
    setHeroTextTitle(
      artistCollab?.texts?.[artistCollabLanguage]?.heroTextTitle
    );

    const splitHeroName = () => {
      let heroTextNameInput =
        artistCollab?.texts?.[artistCollabLanguage]?.heroTextName;
      if (heroTextNameInput !== undefined) {
        let splitHeroTextName = heroTextNameInput.split(" ");
        if (splitHeroTextName[0].length < 4 || splitHeroTextName.length === 1) {
          setHeroTextName(heroTextNameInput);
        } else {
          setHeroTextFirstName(splitHeroTextName[0]);
          setHeroTextLastName(splitHeroTextName[1]);
        }
      }
    };
    splitHeroName();
  }, [artistCollab?.texts?.[artistCollabLanguage]?.heroTextName]);

  function submit(e) {
    e.preventDefault();
    const data = {
      collabPrices: artistCollab.priceId
    }
    if (data.collabPrices) {
      navigate("/register", {
        state: data
      });
    } else {
      navigate("/register");
    }
  }

  return (
    <div className="hero artist-hero">
      <div
        className="artist-hero__overlay"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0) 40%, ${artistCollab?.backgroundColor})`,
          backgroundColor: "rgba(0, 0, 0, 0.15)"
        }}
      ></div>
      <div
        className="hero__content artist-hero__content"
        style={{
          transform: `translateY(${offsetY * 0.1}px)`,
        }}
      >
        <div className="artist-hero__content-wrapper">
          {useHeroLogoImage && (
            <img
              src={artistCollab?.heroTextImage}
              alt={artistCollab.titles[0].title}
              hidden={artistCollab?.useHeroLogoImage ? false : true}
              className="artist-hero__text-img"
              draggable={false}
            />
          )}
          {heroTextName && (
            <div
              className="artist-hero__text-wrapper"
              hidden={artistCollab?.useHeroLogoImage ? true : false}
            >
              <h1
                className="hero-text-inline-header1"
                style={{ color: `${artistCollab?.heroTextColor}` }}
              >
                {heroTextName}
              </h1>
              <h2
                className="hero-text-title-header"
                style={{ color: `${artistCollab?.heroTextColor}` }}
              >
                {heroTextTitle}
              </h2>
            </div>
          )}
          {heroTextName === "" && (
            <div
              className="artist-hero__splitted-text-wrapper"
              hidden={artistCollab?.useHeroLogoImage ? true : false}
            >
              <h1
                className="hero-text-splitted-header1"
                style={{ color: `${artistCollab?.heroTextColor}` }}
              >
                {heroTextFirstName}
              </h1>
              <h1
                className="hero-text-splitted-header2"
                style={{ color: `${artistCollab?.heroTextColor}` }}
              >
                {heroTextLastName}
              </h1>
              <h2
                className="hero-text-title-header__splitted"
                style={{ color: `${artistCollab?.heroTextColor}` }}
              >
                {heroTextTitle}
              </h2>
            </div>
          )}
          <p className="cta-container__text" style={{ color: `${artistCollab?.buttonColor}`}}>
            {`${ctaText}`}
          </p>
          <button
            className="artist-collab-btn"
            onClick={submit}
            style={{
              alignSelf: `${artistCollab?.heroCtaButtonAlign}`,
              backgroundColor: `${artistCollab?.buttonColor}`,
              color: `${artistCollab?.buttonTextColor}`
            }}
          >
            {
              locale === "se" ? "Använd erbjudandet" : "Claim offer"
            }
          </button>
        </div>
      </div>

      {artistCollab?.heroImages?.map((img, index) => (
        <img
          src={img}
          alt={""}
          key={index}
          className={`artist-hero__parallax-img artist-hero__parallax-img-${index}`}
          draggable={false}
          style={{
            maxWidth: "100%",
            transform: `translateY(${offsetY * offsetValues[index]}px)`,
          }}
        />
      ))}
    </div>
  );
};

ArtistHero.propTypes = {
  artistCollab: PropTypes.object.isRequired,
};

export default ArtistHero;
