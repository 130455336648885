import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { api_url } from '../../Constants/constants';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { LanguageContext } from '../../Context/language';
import { DialogContext } from '../../Context/DialogContext';

const Account: React.FC = (): JSX.Element => {
	const urlParams = useLocation().search;
	const userToken = new URLSearchParams(urlParams).get('token');
	const appOrigin = new URLSearchParams(urlParams).get('apporigin');
	const { user, getAccount, logout, getAccessFromUrlToken, accessToken } =
		useContext<any>(TempAuthContext);
	const [loading, setLoading] = useState<boolean>(true);
	// const accessToken = localStorage.getItem('access_token');
	const { language } = useContext(LanguageContext);
	const { setView, toggleSignupDialogOpen } = useContext(DialogContext);
	let locale = window.localStorage.getItem('locale');
	const [openedViaApp, setOpenedViaApp] = useState<string>(appOrigin);
	const navigate = useNavigate();

	useEffect(() => {
		const getAccess = async () => {
			if (userToken) {
				await getAccessFromUrlToken(userToken);
				navigate('/account');
			}
		};
		getAccess();
	}, []);

	useEffect(() => {
		toggleSignupDialogOpen(false);
		if (accessToken && !user) {
			getAccount(accessToken);
		}
		if (accessToken && user) {
			setLoading(false);
		} else if (!accessToken) {
			toggleSignupDialogOpen(true);
			setView('SIGNIN');
		}
	}, [accessToken, user]);

	const removeCreditCard = (): void => {
		let confirm = window.confirm('Do you want to remove your credit card?');

		if (confirm) {
			fetch(`${api_url}/api/account/remove-card`, {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: 'Bearer ' + accessToken,
				},
				body: JSON.stringify({
					stripeId: user?.stripeId,
					creditCard: user?.userCard?.id,
				}),
			})
				.then((res) => {
					if (res.status === 200) {
						window.location.reload();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	if (!accessToken) {
		return (
			<div
				style={{
					backgroundColor: '#121212',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			></div>
		);
	}

	return (
		<>
			{loading ? (
				<div
					style={{
						backgroundColor: '#121212',
						height: '80vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Loader />
				</div>
			) : (
				<div className="account">
					<div className="account__wrap">
						<div className="account-nav__section">
							<button
								onClick={() => {
									window.history.go(-1);
								}}
								className="account__btn"
							>
								{language.backToApp}
							</button>
							<button className="account__btn" onClick={() => logout()}>
								{language.signOut}
							</button>
						</div>

						<h1 className="account__heading">{language.accountDetails}</h1>
						<div className="account__section">
							<h3 className="account-sub__heading">{language.profile}</h3>
							<div className="account-profile-sub__section">
								<p className="account-title__text">{language.name}</p>
								<p className="account-value__text">
									{user?.profile?.name || '-'}
								</p>
							</div>
							<div className="account-profile-sub__section">
								<p className="account-title__text">{language.email}</p>
								<p className="account-value__text">{user?.profile?.username}</p>
							</div>
							<div className="account-profile-sub__section">
								<p className="account-title__text">{language.password}</p>
								<p className="account-value__text">********</p>
							</div>
							<div className="account-profile-sub__section">
								<p className="account-title__text">{language.memberSince}</p>
								<p className="account-value__text">
									{new Date(user?.profile?.createdAt).toLocaleDateString(
										'en-GB',
										{
											dateStyle: 'long',
										}
									)}
								</p>
							</div>
							<div className="account-bottom__section">
								<Link to="profile">
									<button className="account__btn">
										{locale === 'se' ? 'Uppdatera profil' : 'Update profile'}
									</button>
								</Link>
							</div>
						</div>

						<div className="account__section">
							<h3 className="account-sub__heading">{language.subscription}</h3>
							{user?.profile?.paymentType === 'appleIAP' ? (
								<div>
									<div className="account-subscription-sub__section">
										<p className="account-title__text">
											You have a Subscription via Apple.
										</p>
										<p className="account-value__text">
											Go to your Apple Account to manage your subscription.
										</p>
									</div>
									<div className="account-bottom__section">
										<a
											href="https://support.apple.com/en-us/HT204939"
											target="_blank"
											rel="noopener noreferrer"
											className="account__btn"
										>
											Instructions for Apple
										</a>
									</div>
								</div>
							) : (
								<div>
									<div className="account-subscription-sub__section">
										<p className="account-title__text">Status</p>
										{user?.subscription?.comp ? (
											<p className="account-value__text">Complimentary</p>
										) : (
											<p
												className="account-value__text"
												style={{
													color:
														user?.subscription?.cancel_at_period_end &&
														'#ea3342',
												}}
											>
												{user?.subscription?.cancel_at_period_end
													? 'Cancelled'
													: user?.subscription?.status !== undefined
													? user?.subscription?.status[0].toUpperCase() +
													  user?.subscription?.status.slice(1)
													: 'Inactive'}
											</p>
										)}
									</div>
									<div className="account-subscription-sub__section">
										<p className="account-title__text">
											{language.subscription}
										</p>
										<p className="account-value__text">
											{user?.subscription?.plan?.nickname || '-'}
										</p>
									</div>
									<div className="account-subscription-sub__section">
										<p className="account-title__text">{language.amount}</p>
										<p className="account-value__text">
											{user?.subscription?.plan?.amount !== undefined
												? user?.subscription?.plan?.amount / 100 +
												  ' ' +
												  user?.subscription?.plan?.currency.toUpperCase()
												: '-'}
										</p>
									</div>
									{user?.subscription?.trial_end >= new Date() ? (
										<div className="account-subscription-sub__section">
											<p className="account-title__text">
												{language.trialUntil}
											</p>
											<p className="account-value__text">
												{new Intl.DateTimeFormat('en-GB', {
													dateStyle: 'long',
												}).format(user?.subscription?.trial_end * 1000)}
											</p>
										</div>
									) : user?.subscription?.current_period_end !== undefined ? (
										<div className="account-subscription-sub__section">
											<p className="account-title__text">
												{language.validUntil}
											</p>
											<p className="account-value__text">
												{new Intl.DateTimeFormat('en-GB', {
													dateStyle: 'long',
												}).format(
													user?.subscription?.current_period_end * 1000
												)}
											</p>
										</div>
									) : (
										<div className="account-subscription-sub__section">
											<p className="account-title__text">
												{language.validUntil}
											</p>
											<p className="account-value__text">
												{user?.subscription?.compExpiryDate
													? new Intl.DateTimeFormat('en-GB', {
															dateStyle: 'long',
													  }).format(user?.subscription?.compExpiryDate)
													: '-'}
											</p>
										</div>
									)}

									<div className="account-bottom__section">
										{!user?.subscription?.comp ? (
											<Link
												to={{
													pathname: `/upgrade`,
												}}
											>
												<button className="account__btn">
													{locale === 'se'
														? 'Uppgradera abonnemang'
														: 'Upgrade subscription'}
												</button>
											</Link>
										) : null}

										{!user?.subscription?.comp &&
										user?.subscription?.cancel_at_period_end === false ? (
											<>
												<Link
													to={{
														pathname: `/account/subscription`,
													}}
												>
													<button className="account__btn">
														{locale === 'se'
															? 'Avsluta abonnemang'
															: 'Cancel subscription'}
													</button>
												</Link>
											</>
										) : null}
									</div>
								</div>
							)}
						</div>

						{user?.profile?.paymentType !== 'appleIAP' &&
						user?.userCard?.card ? (
							<div className="account__section">
								<h3 className="account-sub__heading">
									{language.step3Headline}
								</h3>
								<div className="account-card__section">
									<p className="account-payment-title__text">
										{user.userCard.card.brand === 'visa' ? (
											<img
												src="/images/visa.png"
												alt=""
												className="account-card-icon"
											/>
										) : user.userCard.card.brand === 'mastercard' ? (
											<img
												src="/images/mastercard.png"
												alt=""
												className="account-card-icon"
											/>
										) : (
											<img
												src="/images/credit_card.png"
												alt=""
												className="account-card-icon"
											/>
										)}
										{'**** **** **** '} {user.userCard.card.last4}{' '}
										{user.userCard.card.exp_month < 10
											? '0' + user.userCard.card.exp_month
											: user.userCard.card.exp_month}
										/{user.userCard.card.exp_year.toString().substr(-2)}
									</p>
									<p>
										<button
											className="account__btn"
											onClick={() => removeCreditCard()}
										>
											{locale === 'se' ? 'Ta bort kort' : 'Remove card'}
										</button>
									</p>
								</div>
								<div
									className="account-bottom__section"
									style={{ justifyContent: 'space-between' }}
								>
									{user?.subscription?.invoices?.length >= 1 ? (
										<div>
											{user?.subscription?.invoices?.map((invoice) => (
												<a href={invoice} key={invoice}>
													<button className="account__btn">
														{locale === 'se'
															? 'Hämta kvitton'
															: 'Download invoices'}
													</button>
												</a>
											))}
										</div>
									) : (
										<button className="account__btn" disabled>
											{locale === 'se' ? 'Inga kvitton' : 'No invoices'}
										</button>
									)}
									<Link to="card">
										<button className="account__btn">
											{locale === 'se' ? 'Byt kort' : 'Change card'}
										</button>
									</Link>
								</div>
							</div>
						) : (
							<div
								className="account__section"
								style={{ alignItems: 'flex-start' }}
							>
								<h3 className="account-sub__heading">
									{language.step3Headline}
								</h3>
								<div className="account-card__section">
									<p className="account-payment-title__text">
										{language.creditCard}
									</p>

									<div className="invoices">
										{user?.subscription?.invoices?.length >= 1 ? (
											<ul>
												{user?.subscription?.invoices?.map(
													(invoice: string) => (
														<li key={invoice}>
															<a href={invoice}>Kvitto</a>
														</li>
													)
												)}
											</ul>
										) : (
											<button className="account__btn" disabled>
												{locale === 'se' ? 'Inga kvitton' : 'No invoices'}
											</button>
										)}
									</div>
								</div>

								{openedViaApp !== 'ios' ? (
									<div className="account-bottom__section">
										<Link to="card">
											<button className="account__btn">
												{locale === 'se'
													? 'Lägg till betalkort'
													: 'Add new card'}
											</button>
										</Link>
									</div>
								) : null}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Account;
