import { Routes, Route, Navigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

// ROUTES
import App from './App';
import Home from './Routes/Home';
import About from './Routes/About';
import Partners from './Routes/Partners';
import Register from './Routes/Register/Register';
import FourOhFour from './Routes/FourOhFour';
import Privacy from './Routes/Privacy';
import Terms from './Routes/Terms';
import Title from './Routes/Title';
import Artist from './Routes/Artist';
import Account from './Routes/Account/Account';
import Profile from './Routes/Account/Profile';
import Churn from './Routes/Account/Churn';
import AddCard from './Routes/Account/AddCard';
import ActivateTV from './Routes/ActivateTV';
import ArtistCollaboration from './Routes/ArtistCollaboration/ArtistCollaboration';
import Discount from './Routes/Discount/Discount';
import DiscountCheckout from './Routes/Discount/DiscountCheckout';
import Discovery from './Components/discovery/Discovery';
import PlayScreen from './Components/discovery/play-screen/PlayScreen';
import Upgrade from './Routes/Upgrade/Upgrade';
import UpgradePayment from './Routes/Upgrade/Pay/UpgradePayment';
import PasswordReset from './Routes/Account/PasswordReset';
import Support from './Routes/Support';

// CONTEXT
import { ArtistCollabContext } from './Context/artistCollabContext';
import { LanguageContext } from './Context/language';
import Library from './Components/Library/Library';
import LibrarySignup from './Components/Library/LibrarySignup/LibrarySignup';
import { PlansContext } from './Context/PlansContext';
import NotAvailable from './Routes/Register/NotAvailable/NotAvailable';
import NewHero from './Components/Hero/NewHero';

const Router = () => {
	const { artistCollabs } = useContext(ArtistCollabContext);
	const { access, country } = useContext(LanguageContext);
	const { getPlans } = useContext(PlansContext);

	useEffect(() => {
		if (country && country !== 'x') {
			getPlans(country);
		}
	}, [country]);

	if (access) {
		return (
			<Routes>
				<Route path="/" element={<App />}>
					<Route path="/" element={<Home />} />
					{/* <Route path="/home" element={<Home />} /> */}

					<Route path="NewHero" element={<NewHero />} />

					<Route path="/register" element={<Register />} />
					<Route path="/title/:slug" element={<Title />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/about" element={<About />} />
					<Route path="/support" element={<Support />} />
					<Route path="/artist/:slug" element={<Artist />} />

					<Route path="/discovery" element={<Discovery />} />
					<Route path="/play/:slug" element={<PlayScreen />} />

					{/* <Route path="/nightwish" element={<Library />} />
					<Route path="/nightwish/signup" element={<LibrarySignup />} /> */}

					{/* DYNAMIC ARTIST COLLABORATION ROUTES */}
					{artistCollabs?.artistCollabs
						?.filter((collab: ArtistCollab) => collab.active === true)
						.map((collab: ArtistCollab) => {
							return (
								<Route
									key={collab._id}
									path={`/${collab.route}`}
									element={<ArtistCollaboration />}
								/>
							);
						})}

					{/* 404 - Page Not Found */}
					<Route path="*" element={<FourOhFour />} />

					{/* PARTNERS */}
					<Route path="/originals/:slug" element={<Partners />} />
					<Route path="/partners/:slug" element={<Partners />} />
					<Route path="/campaign/:slug" element={<Partners />} />
					<Route
						path="/poddelipodd"
						element={<Navigate replace to="/partners/poddelipodd" />}
					></Route>
					<Route
						path="/strage"
						element={<Navigate replace to="/partners/strage" />}
					></Route>
					<Route
						path="/hundaren"
						element={<Navigate replace to="/partners/hundaren" />}
					></Route>

					{/* <Route path="/:lang" element={Landing} /> */}

					{/* UPGRADE PLAN */}
					<Route path="/upgrade" element={<Upgrade />} />
					<Route path="/upgrade/pay" element={<UpgradePayment />} />
					{/* ACCOUNT */}
					<Route path="/account" element={<Account />} />
					<Route path="/account/profile" element={<Profile />} />
					<Route path="/account/subscription" element={<Churn />} />
					<Route path="/account/customer-campaign" element={<Discount />} />
					<Route path="/account/card" element={<AddCard />} />
					<Route path="/account/checkout" element={<DiscountCheckout />} />
					<Route path="/account/reset" element={<PasswordReset />} />

					{/* ACTIVATE TV */}
					<Route path="/activate" element={<ActivateTV />} />
				</Route>
			</Routes>
		);
	} else if (country === 'ru') {
		return (
			<Routes>
				<Route path="*" element={<h1 style={{ color: 'black' }}>No war</h1>} />
			</Routes>
		);
	} else {
		return (
			<Routes>
				<Route path="*" element={<NotAvailable />}>
					{/* <Route path="/" element={<Navigate replace to="/nightwish" />} />
					<Route path="/nightwish" element={<Library />} />
					<Route path="/nightwish/signup" element={<LibrarySignup />} />

					<Route path="/about" element={<About />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="*" element={<Navigate replace to="/nightwish" />} /> */}
				</Route>
			</Routes>
		);
	}
};

export default Router;
