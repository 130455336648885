import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TempAuthContext } from "../../Context/tempAuthContext";
import { api_url } from "../../Constants/constants";

const Churn: React.FC = () => {
  const [freeForm, setFreeForm] = useState<string>("");
  const [optionSelected, setOptionSelected] = useState<string>("");
  const { user, accessToken } = useContext<any>(TempAuthContext);
  const navigate = useNavigate();

  const options = [
    "I expected more content.",
    "I experienced technical issues when watching concerts.",
    "I experienced issues with casting.",
    "I experienced technical issues with my subscription and/or while managing my account.",
    "I find the service too expensive.",
    `I don't have time to watch the service.`,
    "Other",
  ];

  useEffect(() => {
    if (!optionSelected) {
      window.scrollTo(0, 0);
    }
  }, []);

  const goBack = (): void => {
    navigate("/account");
  };

  const cancelUserSubscription = (): void => {
    let confirm = window.confirm("Do you want to cancel your subscription?");
    if (confirm) {
      const cancelSubUrl = `${api_url}/api/account/cancel-user-sub`;
      const userChurnUrl = `${api_url}/api/account/user-churn`;

      const cancelSubscription = axios.post(
        cancelSubUrl,
        { subId: user.subscription.id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const postChurn = axios.post(
        userChurnUrl,
        {
          answer: optionSelected,
          freeForm,
          email: user.profile.username,
          uuid: user.profile.uuid,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      axios
        .all([cancelSubscription, postChurn])
        .then(
          axios.spread((cancelSubscription) => {
            if (cancelSubscription.status === 200) {
              alert("Your subscription is now cancelled.");
              goBack();
            }
          })
        )
        .catch((err) => {
          console.log(err);
          alert(
            "We're sorry, we are having difficulty cancelling your subscription. Please contact the Staccs Support team."
          );
        });
    }
  };

  const renewUserSubscription = (): void => {
    fetch(`${api_url}/api/account/renew-user-sub`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        subId: user.subscription.id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          alert("Your account is now reactivated.");
          goBack();
        }
      })
      .catch((err) => {
        console.error(err);
        alert(
          "We're sorry, we are having difficulty renewing your subscription. Please contact the Staccs Support team."
        );
      });
  };

  return (
    <div className="churn">
      <h2 className="churn__heading">
        {" "}
        {user?.subscription?.cancel_at_period_end === true
          ? "Reactivate your account"
          : "Cancel your subscription"}
      </h2>
      <div className="churn-options__section">
        <div>
          {user?.subscription?.cancel_at_period_end ? (
            <>
              <p className="churn__text">
                Your subscription is cancelled. <br />
                You will have access to Staccs until:{" "}
                {new Intl.DateTimeFormat("en-GB", {
                  dateStyle: "full",
                }).format(user?.subscription?.current_period_end * 1000)}
              </p>
            </>
          ) : null}
          {!user?.subscription?.cancel_at_period_end ? (
            <>
              <h3 className="churn-sub__heading">
                How would you best describe the reason for terminating your
                Staccs subscription?
              </h3>
              {options.map((option) => {
                return (
                  <>
                    <div
                      key={option}
                      className={
                        optionSelected === option
                          ? "churn__option churn-selected__option"
                          : "churn__option"
                      }
                      onClick={() => {
                        setOptionSelected(option);
                      }}
                    >
                      <p className="churn__text">{option}</p>
                    </div>
                  </>
                );
              })}

              <p className="churn__text">
                Your feedback helps us improve Staccs.
              </p>
              <p className="churn__text">
                Kindly elaborate below why you are terminating your subscription
                at Staccs.
              </p>
              <form className="churn-comments__form">
                <textarea
                  onChange={(e) => {
                    setFreeForm(e.target.value);
                  }}
                />
              </form>
            </>
          ) : null}
        </div>
        <div className="churn-btn_section">
          {user?.subscription?.cancel_at_period_end}
          {user?.subscription?.cancel_at_period_end ? (
            <button
              className="churn__btn"
              onClick={() => renewUserSubscription()}
            >
              Renew subscription
            </button>
          ) : (
            <button
              className="churn__btn"
              disabled={!optionSelected ? true : null}
              onClick={() => cancelUserSubscription()}
            >
              Cancel subscription
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Churn;
