import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Components/Loader/Loader";

const Artist: React.FC = (): JSX.Element => {
  let { slug } = useParams();
  const [artistData, setArtistData] = useState<Artist>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    fetch(`https://devapi.svc.staccs.se/api/content/artist/${slug}`, {
      signal: controller.signal,
    })
      .then((res) => res.json())
      .then((data) => {
        setArtistData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    return () => controller.abort();
  }, [slug]);

  if (loading) {
    return (
      <div
        style={{
          backgroundColor: "#121212",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <main className="main">
        <div className="artist bg-dark-matte">
          <div className="artist__wrapper">
            {/* HEADER */}
            <div
              className="artist__header bg-dark-matte"
              style={{
                background: `linear-gradient(rgba(0,0,0, 0.1), #121212 99%), url('${artistData?.graphics?.bannerImageUrl}')`,
              }}
            >
              <div className="artist__header-wrapper">
                <span className="artist__featured-span">Featured Artist</span>
                <h1 className="artist__name">{artistData?.name}</h1>
              </div>
            </div>

            {/* CONTENT - FEATURED CONCERTS & ABOUT */}
            <div className="artist__content">
              {/* FEATURED CONCERTS */}
              <section className="artist__featured-concerts">
                <h3 className="artist__content-subheading">Featured content</h3>
                <div className="artist__featured-concerts-wrapper">
                  {[
                    ...artistData?.content?.concerts,
                    ...artistData?.content?.documentaries,
                    ...artistData?.content?.sessions,
                  ].map((title) => (
                    <a
                      className="artist__featured-concert"
                      href={`https://app.staccs.com/title/${title?._id}`}
                      target="_blank"
                      rel="noreferrer"
                      key={title?._id}
                    >
                      <img
                        className="artist__featured-concert-img"
                        src={title?.graphics?.dvd}
                        // src={`https://www.staccs.com/cdn-cgi/image/quality=75,w=130/${titleƒ?.graphics.dvd}`}
                        alt={`${title?.name} cover`}
                      />

                      <div className="artist__featured-info-wrapper">
                        <p className="artist__featured-content-type">
                          {title?.contentType}
                        </p>

                        <p className="artist__featured-title-name">
                          {title?.name}
                        </p>

                        <p className="artist__featured-title-year">
                          {title?.recordedDate}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </section>

              {/* ABOUT */}
              <section className="artist__about">
                <h3 className="artist__content-subheading">About</h3>
                {artistData?.biography?.split("\n").map((paragraph, index) => (
                  <p className="artist__about-text" key={index}>
                    {paragraph}
                  </p>
                ))}
              </section>
            </div>
          </div>
        </div>
      </main>
    );
  }
};

export default Artist;
