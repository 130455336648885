import React, { useEffect, useState } from 'react';
import { se, en } from './languageCopy';
import { api_url } from '../Constants/constants';

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {
	const [country, setCountry] = useState(window.localStorage.getItem('locale'));
	const [access, setAccess] = useState(true);
	const [language, setLanguage] = useState({});

	const getCountryCodeFromAPI = async () => {
		const staccsGeoRes = await fetch(`${api_url}/api/country`);
		const staccsGeoData = await staccsGeoRes.json();
		const countryCode = staccsGeoData.country_code?.toLowerCase() || 'x';
		return countryCode;
	};

	useEffect(() => {
		const getCountry = async () => {
			const locale = window.localStorage.getItem('locale');
			if (!locale || locale === 'x') {
				try {
					const geoRes = await fetch('https://geolocation-db.com/json/');
					const geoData = await geoRes.json();
					console.log('first countrycode: ', geoData.country_code);
					let countryCode = geoData.country_code?.toLowerCase() || 'x';
					if (countryCode === 'x') {
						countryCode = await getCountryCodeFromAPI();
						console.log('second countrycode: ', countryCode);
					}
					getAccess(countryCode);
					window.localStorage.setItem('locale', countryCode);

					setCountry(countryCode);
				} catch (error) {
					const countryCode = await getCountryCodeFromAPI();
					console.log('error countrycode: ', countryCode);
					getAccess(countryCode);

					window.localStorage.setItem('locale', countryCode);

					setCountry(countryCode);
				}
			}
		};

		getCountry();
	}, []);

	useEffect(() => {
		if (country === 'se') {
			setLanguage(se);
		} else {
			setLanguage(en);
		}
	}, [country]);

	const getAccess = (countryCode) => {
		if (countryCode === 'se') {
			setLanguage(se);
		} else {
			setLanguage(en);
		}

		if (
			['se', 'fi', 'ee', 'en', 'no', 'dk', 'lv', 'lt', 'ax'].includes(
				countryCode
			)
		) {
			setAccess(true);
		} else {
			setAccess(false);
			setLanguage(en);
		}
	};

	return (
		<LanguageContext.Provider
			value={{ language, setLanguage, country, access }}
		>
			{props.children}
		</LanguageContext.Provider>
	);
};
