import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { UpgradeContext } from '../../Context/UpgradeContext';
import { DialogContext } from '../../Context/DialogContext';

// COMPONENTS
import UpgradePlanList from '../../Components/Pricing/PlanList/UpgradePlanList';
import Loader from '../../Components/Loader/Loader';
import { PlansContext } from '../../Context/PlansContext';
import { LanguageContext } from '../../Context/language';

interface LocationState {
	priceId?: string;
	collabPrices?: [string];
	discountPrice?: string;
	discountCoupon?: string;
	partnerName?: string;
}

const Upgrade: React.FC = (): JSX.Element => {
	const { user, getAccount, getAccessFromUrlToken } =
		useContext(TempAuthContext);
	const { country } = useContext(LanguageContext);
	const { getPartnerPrice, getCollabPrices } = useContext(PlansContext);
	const { setPaymentMethodId } = useContext(UpgradeContext);
	const { setView, toggleSignupDialogOpen } = useContext(DialogContext);
	const accessToken = localStorage.getItem('access_token');
	const [loading, isLoading] = useState(true);

	const navigate = useNavigate();

	const location = useLocation();
	const locationState: LocationState = location.state;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		toggleSignupDialogOpen(false);

		// finding partner & collab price
		if (locationState?.priceId) {
			getPartnerPrice(locationState?.priceId, country);
		} else if (locationState?.collabPrices) {
			getCollabPrices(locationState?.collabPrices, country);
		} else if (locationState?.discountPrice) {
			getPartnerPrice(locationState?.discountPrice, country);
		}

		const getAccess = async () => {
			const incomingToken = window.location.search.split('=')[1];

			if (incomingToken) {
				await getAccessFromUrlToken(incomingToken);
				navigate('/upgrade');
			}
		};
		getAccess();

		if (accessToken && !user) {
			getAccount(accessToken);
		}
		if (!accessToken) {
			toggleSignupDialogOpen(true);
			setView('SIGNIN');
		}
		if (user) {
			isLoading(false);
		}
	}, [accessToken, user]);

	useEffect(() => {
		setPaymentMethodId(user?.userCard?.id);
	}, [user]);

	if (loading) {
		return (
			<section className="upgrade bg-dark-matte">
				<Loader />
			</section>
		);
	} else if (user)
		return (
			<section className="upgrade bg-dark-matte">
				<h1 className="upgrade__heading">UPGRADE YOUR PLAN</h1>
				<UpgradePlanList
					discountCoupon={locationState?.discountCoupon}
					discountPrice={locationState?.discountPrice}
					partnerName={locationState?.partnerName}
				/>
			</section>
		);
};

export default Upgrade;
