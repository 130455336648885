/**
 * Formatter functions
 *
 * Assorted functions to format strings, e.g time
 * strings, long strings, and capitalization of strings
 */

/**
 * Format time strings
 *
 * Formats and returns seconds to format hours:minutes:seconds, e.g 01:04:20
 *
 * @param {number} seconds
 * @returns {string} Formatted time
 */
export function timeFormatter(seconds) {
	seconds = Number(seconds);

	const h = Math.floor(seconds / 3600);
	const m = Math.floor((seconds % 3600) / 60);
	const s = Math.floor((seconds % 3600) % 60);

	const hDisplay =
		h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '0';
	const mDisplay =
		m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
	const sDisplay =
		s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : '00';

	if (h == 0) return `${mDisplay}:${sDisplay}`;
	return `${hDisplay}:${mDisplay}:${sDisplay}`;
}

/**
 * Takes a long-ass string, e.g a title name, and makes it shorter with an ellipsis of '...'
 *
 * @param {string} string The string to make shorter
 * @param {number} maxLength Maximal amount of letter to be shown
 * @returns {sting} Formatted string
 */
export const longStringFormatter = (string, maxLength) =>
	string && string.length > maxLength
		? string.substring(0, maxLength) + '...'
		: string;

/**
 * Capitalizes first letter of string
 *
 * @param {string} string String to capitalize first letter in
 * @returns {string} Formatted string
 */
export const capitalizeFirstLetter = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

export function deluxeTimeFormatter(seconds) {
	if (isNaN(seconds)) return '...';
	const hours = Math.floor(seconds / 3600);
	let minutes = Math.floor((seconds / 60) % 60);
	seconds = Math.floor(seconds % 60);
	if (seconds < 10) seconds = '0' + seconds;
	if (hours > 0) {
		if (minutes < 10) minutes = '0' + minutes;
		return `${hours}:${minutes}:${seconds}`;
	} else {
		return `${minutes}:${seconds}`;
	}
}
