import { useState, useRef } from "react";
import videojs from "video.js";
import Loader from "../../../../Loader/Loader";
import VideoPlayer from "./video-player/VideoPlayer";

const PlayScreen = () => {
  const playerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [videoOptions, setVideoOptions] = useState({
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "https://world-of-staccs-staccs-tv-1-us.ono.wurl.tv/playlist.m3u8",
      },
    ],
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
        alignItems: "center",
        backgroundColor: "black",
        maxWidth: "560px",
        height: "100%",
        width: "100%",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="staccs-tv__player-wrapper">
          <VideoPlayer options={videoOptions} onReady={handlePlayerReady} />
        </div>
      )}
    </div>
  );
};

export default PlayScreen;
