import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { api_url } from '../../Constants/constants';
import Loader from '../../Components/Loader/Loader';
import { LanguageContext } from '../../Context/language';

const Profile: React.FC = (): JSX.Element => {
	const { user, getAccount } = useContext<any>(TempAuthContext);
	const [updateProfile, setUpdateProfile] = useState<any>();
	const accessToken = localStorage.getItem('access_token');
	const [profileFeedback, setProfileFeedback] = useState<string>('');
	const [passwordFeedback, setPasswordFeedback] = useState<string>('');
	const [updatePassword, setUpdatePassword] = useState<any>();
	const [passwordConfirm, setPasswordConfirm] = useState<string>('');
	const [oldPasswordValid, setOldPasswordValid] = useState<string>('');
	const [newPasswordValid, setNewPasswordValid] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();
	const { language } = useContext(LanguageContext);

	let locale = window.localStorage.getItem('locale');

	useEffect(() => {
		if (accessToken) {
			getAccount(accessToken);
		}
		if (user) {
			setLoading(false);
		}
	}, [accessToken, user]);

	const updateUserProfile = (e: React.FormEvent): void => {
		e.preventDefault();
		fetch(`${api_url}/api/account/profile`, {
			method: 'PUT',
			headers: {
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + accessToken,
			},
			body: JSON.stringify({
				updateProfile,
				uuid: user.profile.uuid,
			}),
		})
			.then((res) => {
				if (res.status === 200) {
					setProfileFeedback('Your profile has been updated.');
				}
			})
			.catch((err) => {
				console.log(err);
				setProfileFeedback('Something went wrong');
			});
	};

	const updateUserPassword = (e: React.FormEvent): void => {
		e.preventDefault();
		if (updatePassword?.password === passwordConfirm) {
			setNewPasswordValid('');
			setOldPasswordValid('');
			fetch(`${api_url}/api/account/change-password`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: 'Bearer ' + accessToken,
				},
				body: JSON.stringify({
					password: updatePassword.password,
					oldPassword: updatePassword.oldPassword,
				}),
			})
				.then((res) => {
					if (res.status === 200) {
						setPasswordFeedback('Your password has been changed.');
					} else if (res.status === 400) {
						setPasswordFeedback('Your old password is incorrect.');
						setOldPasswordValid('red');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setPasswordFeedback('New passwords do not match.');
			setNewPasswordValid('red');
		}
	};

	if (loading) return <Loader />;
	return (
		<div className="profile">
			<div className="profile-nav__section">
				<button className="profile__btn" onClick={() => navigate(-1)}>
					{locale === 'se' ? 'Tillbaka till konto' : 'Back to account'}
				</button>
			</div>
			<h2 className="profile__heading">{language.updateProfile}</h2>
			<div className="profile__section">
				<h3 className="profile-sub__heading">{language.profile}</h3>
				<form className="profile__form">
					<div className="profile-sub__section">
						<label className="profile__label" htmlFor="name">
							{language.name}
						</label>
						<input
							type="name"
							id="name"
							name="name"
							onChange={(e) => {
								setUpdateProfile({
									...updateProfile,
									name: e.target.value,
								});
							}}
							value={
								updateProfile?.name ? updateProfile?.name : user?.profile?.name
							}
						/>
					</div>
					<div className="profile-sub__section">
						<label className="profile__label" htmlFor="email">
							{language.email}
						</label>
						<input
							type="email"
							id="email"
							name="email"
							disabled
							style={{ backgroundColor: '#eee' }}
							onChange={(e) => {
								setUpdateProfile({
									...updateProfile,
									username: e.target.value,
								});
							}}
							value={user?.profile?.email}
						/>
					</div>
					<p className="profile-feedback__text">{profileFeedback}</p>
					<div className="profile-bottom__section">
						<button
							className="profile__btn"
							onClick={(e) => updateUserProfile(e)}
						>
							{language.updateProfile}
						</button>
					</div>
				</form>
				<h3 className="profile-sub__heading">
					{locale === 'se' ? 'Ändra lösenord' : 'Change password'}
				</h3>
				<form className="profile__form">
					<div className="profile-sub__section">
						<label className="profile__label" htmlFor="old-password">
							{locale === 'se' ? 'Nuvarande lösenord' : 'Current password'}
						</label>
						<input
							type="password"
							id="old-password"
							name="old-password"
							style={{ borderColor: oldPasswordValid }}
							onChange={(e) => {
								setUpdatePassword({
									...updatePassword,
									oldPassword: e.target.value,
								});
							}}
						/>
					</div>
					<div className="profile-sub__section">
						<label className="profile__label" htmlFor="new-password">
							{locale === 'se' ? 'Nytt lösenord' : 'New password'}
						</label>
						<input
							type="password"
							id="password"
							name="password"
							style={{ borderColor: newPasswordValid }}
							onChange={(e) => {
								setUpdatePassword({
									...updatePassword,
									password: e.target.value,
								});
							}}
						/>
						<p>
							{newPasswordValid
								? ''
								: 'Password has to be at least 8 characters'}
						</p>
					</div>
					<div className="profile-sub__section">
						<label className="profile__label" htmlFor="confirm-password">
							{locale === 'se' ? 'Bekräfta lösenord' : 'Confirm password'}
						</label>
						<input
							type="password"
							id="confirm-password"
							name="confirm-password"
							style={{ borderColor: newPasswordValid }}
							onChange={(e) => {
								setPasswordConfirm(e.target.value);
							}}
						/>
					</div>
					<p className="profile-feedback__text">{passwordFeedback}</p>
					<div className="profile-bottom__section">
						<button
							className="profile__btn"
							onClick={(e) => updateUserPassword(e)}
						>
							{locale === 'se' ? 'Uppdatera lösenord' : 'Update password'}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Profile;
