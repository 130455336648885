import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CtaButton from '../Components/Buttons/CTA/CtaButton';

const Title: React.FC = (): JSX.Element => {
	const [titleData, setTitleData] = useState<Title>();
	const [loading, setLoading] = useState<boolean>(true);
	const [artistsOnStage, setArtistsOnStage] = useState<Array<Artist>>([]);

	let { slug } = useParams();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		const getTitleData = async () => {
			const getPartner = await fetch(
				//`http://localhost:5000/api/title/${slug}`
				`https://staccs.com/api/title/${slug}`
			);
			const data = await getPartner.json();
			setTitleData(await data);
			setLoading(false);

			if (data.titleArtists && data.titleArtists.length > 0) {
				const resArtistsOnStageData = await fetch(
					`https://staccs.com/api/artist/denom_artist`,
					{
						method: 'POST',
						body: JSON.stringify({ artistId: data.titleArtists }),
						headers: {
							'Content-Type': 'application/json',
						},
					}
				);
				const artistsOnStageData = await resArtistsOnStageData.json();

				setArtistsOnStage(await artistsOnStageData);
			}
		};
		getTitleData();

		// Data Layer is an object used by Google Tag Manager and gtag.js to pass information to tags
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'page_view',
		});
	}, []);

	if (loading) {
		return (
			<div className="HeadWrapper" style={{ height: '100vh' }}>
				<div className="headercontent">
					<div className="spinner"></div>
				</div>
			</div>
		);
	}

	return (
		<main className="main">
			<div className="title">
				{/* TITLE HEADER */}
				<div
					className="title__header"
					style={{
						backgroundImage: `url(${titleData.graphics.dvd})`,
					}}
				>
					<div className="title__header-content-wrapper">
						<div className="title__header-content">
							{/* TITLE DVD */}
							<img
								src={`https://www.staccs.com/cdn-cgi/image/quality=75,w=200/${titleData?.graphics.dvd}`}
								alt={titleData?.name}
								className="title__header-dvd"
							/>

							<h2 className="title__header-title">
								<span className="title__header-artist-name">
									{titleData?.artistName}
								</span>

								{titleData?.name}
							</h2>

							{/* WATCH NOW BUTTON */}
							<CtaButton
								link={`https://app.staccs.com/title/${titleData?._id}`}
								text="Watch Now"
								internLink={false}
							/>
						</div>
					</div>
				</div>

				{/* TITLE CONTENT - TITLE INFO AND TRACKS */}
				<div className="title__content bg-dark">
					<div className="title__content-wrapper titleWrapper">
						<section className="title__info" id="title-info">
							{/* LEFT - TITLE INFO */}
							<div className="title__info-left">
								<h3 className="subheading">Reliving {titleData?.name} </h3>
								<p>{titleData?.summary}</p>
							</div>

							{/* RIGHT - TITLE ARTISTS */}
							<div className="title__info-right">
								<h3 className="subheading">On stage</h3>
								{artistsOnStage.map((artist) => (
									<a
										key={artist._id}
										href={`https://www.staccs.com/artist/${artist._id}`}
										className="title__artist"
									>
										<img
											src={`https://www.staccs.com/cdn-cgi/image/quality=75,w=50/${artist.graphics.avatarImageUrl}`}
											alt={artist.name}
											className="title__artist-avatar"
										/>
										<p className="title__artist-name">{artist.name}</p>
									</a>
								))}
							</div>
						</section>

						{/* TRACKS */}
						{titleData?.tracks?.length && (
							<section className="title__tracks" id="title-tracks">
								<h3 className="subheading">Tracks</h3>

								{titleData?.tracks?.map((track, index) => (
									<div className="title__tracks-wrapper">
										<a
											href={`https://app.staccs.com/title/${titleData._id}`}
											target="_blank"
											rel="noreferrer"
											className="title__tracks-row"
										>
											<div className="title__tracks-row-left">
												<span className="title__tracks-number">
													{index + 1}
												</span>
												<img
													className="title__tracks-image"
													src={`https://www.staccs.com/cdn-cgi/image/quality=75,w=80/${track.graphics.coverImageUrl}`}
													alt={track.name}
													// src={track.graphics.coverImageUrl}
												/>
												<span
													className="title__tracks-name"
													style={{
														fontKerning: 'auto',
														letterSpacing: '0.05em',
													}}
												>
													{track.name}
												</span>
											</div>
											<div className="title__tracks-row-right">
												<span className="title__tracks-duration">
													{track.duration_M_S.mins < 10
														? '0' + track.duration_M_S.mins
														: track.duration_M_S.mins}
													:
													{track.duration_M_S.secs < 10
														? '0' + track.duration_M_S.secs
														: track.duration_M_S.secs}
													{console.log(`${track.duration_M_S.secs.length}`)}
												</span>
											</div>
										</a>
									</div>
								))}
							</section>
						)}
					</div>
				</div>
			</div>
		</main>
	);
};

export default Title;
