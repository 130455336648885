import React, {
	MutableRefObject,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import { deluxeTimeFormatter } from '../../../../Utils/helpers';
import axios from 'axios';
import { TempAuthContext } from '../../../../Context/tempAuthContext';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PlayIcon from '@mui/icons-material/PlayArrowSharp';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import { VideoControllerContext } from '../../../../Context/videoContext';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeMuted from '@mui/icons-material/VolumeMuteSharp';
import Slider from '@mui/material/Slider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import sendPlayerStats from '../../../../Utils/helpers/playerStats';

interface VideoPlayerProps {
	thumbnail: string;
	videoDuration: string;
	titleId: string;
	onReady: any;
	HLS: string;
	videoTitle: string;
	title: Partial<Title>;
	isFastTV?: boolean;
}

const windowObject = window as any;
const isIos =
	/iPad|iPhone|iPod/.test(navigator.userAgent) && !windowObject.MSStream;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
	onReady,
	thumbnail,
	titleId,
	HLS,
	videoDuration,
	videoTitle,
	title,
	isFastTV,
}): JSX.Element => {
	const {
		videoVolume,
		setVideoVolume,
		videoMuted,
		setVideoMuted,
		titlePlaying,
		setTitlePlaying,
	} = useContext(VideoControllerContext);
	const { accessToken } = useContext(TempAuthContext);
	const [videoPlaying, setVideoPlaying] = useState<boolean>(true);
	const [showVolume, setShowVolume] = useState(false);
	const [videoTime, setVideoTime] = useState<any>(0);
	const [showControls, setShowControls] = useState(false);
	const [fullscreen, setFullscreen] = useState(false);
	const [sliderValue, setSliderValue] = useState(0);
	const [currentTime, setCurrentTime] = useState<string>('');
	const [showPlay, setShowPlay] = useState<boolean>(true);
	const [iosUnit, setIosUnit] = useState<boolean>(false);
	const [androidUnit, setAndroidUnit] = useState<boolean>(false);

	const [showControlsTimeout, setShowControlsTimeout] = useState<
		NodeJS.Timeout | number
	>(null);

	const [hlsData, setHls] = useState<string | null>(null);
	const [videoOptions, setVideoOptions] = useState({
		autoplay: true,
		controls: false,
		responsive: true,
		fluid: true,
		sources: [{ src: HLS }],
	});

	const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null);
	const playerRef = useRef<videojs.Player | null>(null);

	let timeout = null;

	const api = 'https://devapi.svc.staccs.se/api';

	useEffect(() => {
		const windowObject = window as any;
		let isAndroid = /Android|windows phone/.test(navigator.userAgent);
		let isIos =
			/iPad|iPhone|iPod/.test(navigator.userAgent) && !windowObject.MSStream;
		if (isIos) {
			setIosUnit(true);
			setShowControls(false);
		}
		if (isAndroid) {
			setAndroidUnit(true);
		}
		if (playerRef.current) {
			playerRef.current.autoplay(videoOptions.autoplay);
			playerRef.current.src([{ src: hlsData!, type: 'application/x-mpegURL' }]);
		} else {
			try {
				const videoElement = videoRef?.current as HTMLVideoElement;

				console.log('creating player');
				const player = (playerRef.current = videojs(
					videoElement!,
					videoOptions,

					() => {
						videojs.log('player is ready');
						onReady && onReady(player);
					}
					// player.autoplay(videoOptions.autoplay);
					// player.src([{ src: hlsData!, type: 'application/x-mpegURL' }]);¥¥
				));
			} catch (error) {
				console.log(error);
			}
		}
	}, [videoRef]);

	const exitHandler = () => {
		const theDoc = document as any;
		if (
			!theDoc.webkitIsFullscreen &&
			!theDoc.mozFullScreen &&
			!theDoc.msFullscreenElement
		) {
			setFullscreen(!fullscreen);
		}
	};

	/// CONTROLLER FUNCTIONS ///
	const handleShowControls = () => {
		const windowobject = window as any;
		if (
			!(/iPad|iPhone|iPod/.test(navigator.userAgent) && !windowobject.MSStream)
		) {
			clearTimeout(timeout);
			setShowControls(true);

			timeout = setTimeout(() => setShowControls(false), 2500);
		}
	};

	const handlePlayPause = () => {
		// setShowPlay(false);
		playerRef?.current?.volume(videoVolume / 100);
		if (videoPlaying === true) {
			playerRef.current?.pause();
			setVideoPlaying(false);
		} else {
			setTitlePlaying(titleId);
			playerRef.current?.play();
			setVideoPlaying(true);
		}
	};

	useEffect(() => {
		playerRef?.current?.volume(videoVolume / 100);
	}, [videoPlaying]);

	const handleFullscreen = () => {
		!fullscreen
			? playerRef?.current?.requestFullscreen()
			: playerRef?.current?.exitFullscreen();
	};

	const handleMute = () => {
		if (!videoMuted) {
			playerRef.current?.volume(0);
		} else {
			playerRef.current?.volume(videoVolume / 100);
		}
		setVideoMuted((current) => !current);
	};

	const handleVolume = (e) => {
		setVideoVolume(e.target.value);
		playerRef.current.volume(e.target.value / 100);
		setVideoMuted(false);
		if (e.target.value === 0) {
			setVideoMuted(true);
		}
	};

	/// functionality for fast-forward and rewind with slider ///
	const changeSliderValue = (e) => {
		setHls(hlsData);
		setSliderValue(e.target.value);
		const currentRef = playerRef.current as any;
		const videoDuration = currentRef.cache_?.duration;
		playerRef.current.currentTime(
			Math.floor((videoDuration / 100) * e.target.value)
		);
	};

	/// functionality for making the slider move as the video progresses ///
	const handleTimeUpdate = () => {
		const currentRef = playerRef.current as any;

		let sliderTracker =
			(currentRef?.cache_?.currentTime / currentRef?.cache_?.duration) * 100;

		const timePlayed = deluxeTimeFormatter(currentRef?.cache_?.currentTime);
		setCurrentTime(timePlayed);
		const totalVideoTime = deluxeTimeFormatter(currentRef?.cache_?.duration);
		setVideoTime(totalVideoTime);
		setSliderValue(sliderTracker);
	};

	/// functionality for handling video player with keyboard ///
	useEffect(() => {
		document.addEventListener('fullscreenchange', exitHandler, false);
		document.addEventListener('mozfullscreenchange', exitHandler, false);
		document.addEventListener('MSFullscreenChange', exitHandler, false);
		document.addEventListener('webkitfullscreenchange', exitHandler, false);

		const handleKeyDown = (e) => {
			const currentRef = playerRef.current as any;

			const currentVideo = videoRef.current;

			let getCurrentTime = currentRef.cache_.currentTime;
			if (e.code === 'Space' && titlePlaying == titleId) {
				if (!currentVideo.paused) {
					currentVideo.pause();
					setVideoPlaying(false);
				} else {
					currentVideo.play();
					setVideoPlaying(true);
				}
			}
			if (e.code === 'ArrowRight' && titlePlaying == titleId) {
				playerRef.current.currentTime(getCurrentTime + 7);
			}
			if (e.code === 'ArrowLeft' && titlePlaying == titleId) {
				playerRef.current.currentTime(getCurrentTime - 7);
			}

			// if (e.code === 'Escape' && fullscreen) {
			// 	handleFullscreen();
			// }
		};

		// videoRef.current.addEventListener('fullscreenchange', () => {
		// 	videoRef.current.focus();
		// });

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('fullscreenchange', exitHandler);
			document.removeEventListener('mozfullscreenchange', exitHandler);
			document.removeEventListener('MSFullscreenChange', exitHandler);
			document.removeEventListener('webkitfullscreenchange', exitHandler);
		};
	}, [titlePlaying, titleId, videoPlaying, fullscreen]);

	/// USE EFFECTS ///
	useEffect(() => {
		playerRef.current.on('mousemove', handleShowControls);
	}, []);

	/// prevent two videos from playing simultaneously ///
	useEffect(() => {
		if (titlePlaying !== titleId && videoRef.current) {
			playerRef.current.pause();
			setVideoPlaying(false);
		}
	}, [titlePlaying]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	//---- TIMER FOR PLAYER STATS & HISTORY ----//
	const [secondsOfMinute, setSecondsOfMinute] = useState(0);
	const sendStatsInterval = process.env.NODE_ENV === 'development' ? 15 : 60;
	const oldToken = localStorage.getItem('old_token');
	const countryCode = localStorage.getItem('locale');

	useEffect(() => {
		const playerStats = {
			token: oldToken,
			contentType: title.contentType,
			titleId: titleId,
			platform: 'web - nightwish',
			artistName: title.artistName,
			name: title.name,
			displayName: title.displayName,
			genre: title.genre,
			countryCode: countryCode,
		};

		const timer = setInterval(() => {
			videoPlaying && setSecondsOfMinute((prev) => prev + 1);

			// Send Player Stats
			if (secondsOfMinute === sendStatsInterval) {
				setSecondsOfMinute(0);
				console.log('SEND STATS');
				sendPlayerStats(
					playerStats.token,
					playerStats.contentType,
					playerStats.titleId,
					playerStats.platform,
					playerStats.artistName,
					playerStats.name,
					playerStats.displayName,
					playerStats.genre,
					playerStats.countryCode
				);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [videoPlaying, secondsOfMinute]);

	return (
		<div
			className="video-and-duration__wrapper"
			style={{
				position: 'relative',
				borderRadius: '8px',
				height: '100%',
			}}
		>
			<div
				onMouseLeave={() => setShowControls(false)}
				className="video-player__wrapper"
				style={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					// height: '200px',
				}}
			>
				<div
					data-vjs-player
					style={{
						position: 'absolute',
						top: '0',
						// display: !showPlay ? 'block' : 'none',
						// minHeight: '100%',
						width: '100%',
					}}
				>
					<div
						className={
							showControls && fullscreen
								? 'fullscreen-title-display__wrapper'
								: 'fullscreen-title-display__wrapper-hide'
						}
					>
						{videoTitle}
					</div>
					<video
						style={{ borderRadius: '8px' }}
						onTouchEnd={() => setShowControls(!showControls)}
						playsInline={iosUnit}
						webkit-playsInline={iosUnit}
						// controls={iosUnit}
						ref={videoRef}
						className="video-js vjs-big-play-centered vjs-fluid"
						onTimeUpdate={handleTimeUpdate}
						onClick={handlePlayPause}
					></video>
					{showControls && (
						<div className="controls__parent" style={{ position: 'absolute' }}>
							{!isFastTV && (
								<div
									className="slider__wrapper"
									style={{ position: 'absolute' }}
								>
									<Slider
										value={sliderValue}
										onChange={(e) => changeSliderValue(e)}
										sx={{
											color: '#FFF',
											'& .MuiSlider-thumb': {
												backgroundColor: '#FFF',
											},
											'& .MuiSlider-track': {
												backgroundColor: '#FFD85B',
												border: 'none',
											},
											'& .MuiSlider-rail': {
												backgroundColor: '#FFF',
											},
										}}
									/>
								</div>
							)}
							<div className="controls__wrapper" style={{}}>
								<div className="play-pause-volume__wrapper">
									{videoPlaying ? (
										<button
											onClick={() => handlePlayPause()}
											style={{ cursor: 'pointer' }}
											className="play-pause__button"
										>
											<PauseIcon />
										</button>
									) : (
										<button
											onClick={() => handlePlayPause()}
											style={{ cursor: 'pointer' }}
										>
											<PlayIcon />
										</button>
									)}
									{!iosUnit && (
										<div className="volume__wrapper">
											<div
												onClick={() => handleMute()}
												onMouseEnter={() => setShowVolume(true)}
												style={{ cursor: 'pointer' }}
											>
												{videoMuted ? <VolumeMuted /> : <VolumeUp />}
											</div>
											<div
												style={{ width: '100px' }}
												className={
													showVolume ? 'display-volume' : 'hide-volume'
												}
											>
												{showVolume ? (
													<Slider
														defaultValue={videoVolume}
														style={{
															width: '100%',
															scale: '0.7',
															color: '#f7f7f7',
														}}
														aria-label="Volume"
														onChange={(e) => handleVolume(e)}
													/>
												) : null}
											</div>
										</div>
									)}
									<div
										style={{
											// minWidth: '100px',
											marginLeft: '6px',
											fontSize: '12px',
											display: 'flex',
											flexWrap: 'wrap',
										}}
									>
										{isFastTV ? 'LIVE' : `${currentTime}/${videoTime}`}
									</div>
								</div>
								<div
									onClick={() => handleFullscreen()}
									style={{ cursor: 'pointer' }}
								>
									{!fullscreen ? <FullscreenIcon /> : <FullscreenExit />}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{!videoPlaying && !showControls && (
				<div className="duration__wrapper">{videoDuration}</div>
			)}
		</div>
	);
};

export default VideoPlayer;
