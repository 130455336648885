import React, { useContext, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import { deluxeTimeFormatter } from '../../../../Utils/helpers';
import VideoPlayer from './VideoPlayer';
import PlayIcon from '@mui/icons-material/PlayArrowSharp';
import { VideoControllerContext } from '../../../../Context/videoContext';

interface PlayScreenProps {
	thumbnail?: string;
	videoDuration?: string;
	titleId?: string;
	HLS?: string;
	videoTitle?: string;
	title?: Partial<Title>;
	isFastTV?: boolean;
}

export const PlayScreen: React.FC<PlayScreenProps> = ({
	thumbnail,
	titleId,
	HLS,
	videoDuration,
	videoTitle,
	title,
	isFastTV,
}): JSX.Element => {
	const {
		titlePlaying,
		setTitlePlaying,
		clickedPartnerPlay,
		videoVolume,
		setVideoVolume,
	} = useContext(VideoControllerContext);
	const playerRef = useRef(null);
	const videoRef = useRef();

	const [clickedPlay, setClickedPlay] = useState<boolean>(false);

	useEffect(() => {
		setClickedPlay(clickedPartnerPlay);
	}, [clickedPartnerPlay]);

	const accessToken = localStorage.getItem('access_token');

	const handlePlayerReady = (player): void => {
		playerRef.current = player;

		player.on('waiting', () => {
			videojs.log('player is waiting');
		});

		player.on('dispose', () => {
			videojs.log('player will dispose');
		});
	};

	return (
		<>
			{clickedPlay ? (
				<VideoPlayer
					thumbnail={thumbnail}
					videoDuration={videoDuration}
					titleId={titleId}
					HLS={HLS}
					onReady={handlePlayerReady}
					videoTitle={videoTitle}
					title={title}
					isFastTV={isFastTV}
				/>
			) : (
				<div
					className="video-and-duration__wrapper"
					onClick={() => {
						if (accessToken) {
							setClickedPlay(true);
							setTitlePlaying(titleId);
						}
					}}
					style={{
						position: 'relative',
						borderRadius: '8px',
					}}
				>
					<div className="duration__wrapper">{videoDuration}</div>

					<PlayIcon
						// onClick={() => handlePlayPause()}
						fontSize="inherit"
						style={{
							borderRadius: '50px',
							backgroundColor: 'rgba(0, 0, 0, 0.85)',
							color: 'white',
							width: '60px',
							height: '60px',
							// fontSize: '30pt',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					/>
					<div className="video-player__poster-play">
						<img
							src={thumbnail}
							alt=""
							style={{
								display: clickedPlay ? 'none' : 'block',
								width: '100%',
								borderRadius: '8px',
							}}
						/>
					</div>
				</div>
			)}
		</>
	);
};
