import { useEffect, useState, useContext } from 'react';
import LongBar from './long-bar/LongBar';
import ShortBar from './short-bar/ShortBar';
import Banner from './banner/Banner';
import BigCard from './big-card/BigCard';
import { FilterContext } from '../../../Context/FilterContext';
import axios from 'axios';
import { api_url } from '../../../Constants/constants';
import StaccsTV from './StaccsTV/StaccsTV';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../Loader/Loader';
import { title } from 'process';
import HomeCard from './home-card/HomeCard';
import StaccsAppCTA from './staccsAppCTA/StaccsAppCTA';

function Content() {
	const { genre } = useContext(FilterContext);
	const [items, setItems] = useState([]);
	const [pagesLoaded, setPagesLoaded] = useState(0);
	const [loadingData, setLoadingData] = useState(false);
	const [loadedIds, setLoadedIds] = useState([]);

	const getItems = async (page) => {
		var config = {
			method: 'post',
			data: { loadedIds },
			url: `${api_url}/api/discovery/feed?page=${page}`,
			headers: {
				'Content-Type': 'application/json',
			},
		};
		if (genre) {
			config.url = config.url + `&genre=${genre}`;
		}

		const response = await axios(config);

		if (response.data) {
			setItems((prevState) => [...prevState, ...response.data]);
			setLoadingData(false);
			setLoadedIds((prevState) => [
				...prevState,
				...response.data.map((title) => title.titleId),
			]);
		}
	};

	useEffect(() => {
		getItems(0);
		console.log(items.length);
	}, [genre]);

	return (
		<>
			<InfiniteScroll
				className="discovery-content"
				dataLength={items.length} //This is important field to render the next data
				next={() => {
					getItems(pagesLoaded + 1);
					setPagesLoaded((prevState) => prevState + 1);
				}}
				hasMore={true}
				loader={
					<div className="loader-wrapper">
						<Loader text={false} />
					</div>
				}
				endMessage={
					<p style={{ textAlign: 'center' }}>
						<b>Yay! You have seen it all</b>
					</p>
				}
				// below props only if you need pull down functionality
				refreshFunction={() => console.log('refresh')}
				pullDownToRefresh={true}
				pullDownToRefreshThreshold={50}
			>
				<StaccsTV />
				<div
					className="discovery-content__ctas"
					style={{
						display: 'flex',
						gap: '2rem',
						marginBottom: '2rem',
						width: '100%',
						justifyContent: 'space-evenly',
					}}
				>
					<HomeCard />
					{/* <StaccsAppCTA /> */}
				</div>

				{loadingData && (
					<div className="loader-wrapper">
						<Loader text={false} />
					</div>
				)}

				{items.map((obj, index) => {
					switch (obj.type) {
						case 'LONG':
							return (
								<LongBar key={`${obj.titleId}-${index}`} items={obj.items} />
							);
						case 'SHORT':
							return (
								<ShortBar key={`${obj.titleId}-${index}`} items={obj.items} />
							);
						case 'BANNER':
							return <Banner key={`${obj.titleId}-${index}`} item={obj} />;
						case 'BIGCARD':
						case 'SHORTFORM':
						case 'LONGFORM':
							return <BigCard key={`${obj.titleId}-${index}`} item={obj} />;

						default:
							return <BigCard key={`${obj.titleId}-${index}`} item={obj} />;
					}
				})}
			</InfiniteScroll>
		</>
	);
}

export default Content;
