import { useContext } from "react";
import { LanguageContext } from "../../Context/language";
import FAQ from "./FAQ/FAQ";

const FAQs: React.FC = (): JSX.Element => {
  const { language } = useContext(LanguageContext);
  const { FAQs } = language;

  return (
    <section id="FAQs" className="FAQs section">
      <div className="FAQs__wrapper">
        <h2 className="faq-heading">{FAQs?.heading}</h2>
        {FAQs?.questions?.map((faq, index) => (
          <FAQ key={index} faq={faq} />
        ))}
      </div>
    </section>
  );
};

export default FAQs;
