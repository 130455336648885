import { useEffect } from 'react';
import FAQs from '../Components/FAQs/FAQs';
import FeaturedTitles from '../Components/FeaturedTitles/FeaturedTitles';
import Features from '../Components/Features/Features';
import Hero from '../Components/Hero/Hero';
import Pricing from '../Components/Pricing/Pricing';
import UserCentric from '../Components/UserCentric/UserCentric';

const Home: React.FC = (): JSX.Element => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<div className="container">
			<Hero />
			<Pricing />
			<FeaturedTitles />
			<Features />
			<UserCentric />
			<FAQs />
		</div>
	);
};

export default Home;
