import React, { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../../../Context/DialogContext';
import { TempAuthContext } from '../../../../Context/tempAuthContext';
import { useInView } from 'react-intersection-observer';
import ShareButton from './share-button/ShareButton';
import './styles.css';
import Player from './player/PlayScreen';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PlayIcon from '@mui/icons-material/PlayArrowSharp';
import VerifiedIcon from '@mui/icons-material/Verified';
import { longStringFormatter } from '../../../../Utils/helpers';
import { ReactComponent as GiftLogo } from './gift/giftLogo.svg';
import { api_url } from '../../../../Constants/constants';
import axios from 'axios';
import { deluxeTimeFormatter } from '../../../../Utils/helpers';
import staccsThumb from '../../../../logo-sharp.png';

interface CardProps {
	item: {
		artistImageUrl: string;
		artistName: string;
		displayName: string;
		description: string;
		duration: number;
		genre: string;
		name: string;
		thumbNail: string;
		titleId: string;
		titleArtist: string;
		type: string;
		envoyActive: boolean;
	};
}

const Card: React.FC<CardProps> = ({ item }): JSX.Element => {
	const { ref, inView } = useInView({
		threshold: 0,
	});
	const { setSignupDialogOpen, setSelectedTitleId, setGift, setGiftLoading } =
		useContext(DialogContext);
	const { role, accessToken } = useContext(TempAuthContext);

	const [showVideo, toggleShowVideo] = useState<boolean>(false);
	const [readMoreExpanded, setReadMoreExpanded] = useState<boolean>(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [userRemainingQuota, setUserRemainingQuota] = useState(0);

	const numFreeViews = +window.localStorage.getItem('_nfv') || 0;

	const [autoPlayingStaccsTV, setAutoPlayingStaccsTV] = useState(false);

	const getUserRemainingGifts = async () => {
		try {
			if (accessToken) {
				const response = await axios.get(
					`https://api.svc.staccs.se/api/envoy/remaining-quota`,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
					}
				);
				setUserRemainingQuota(response.data.userRemainingQuota);
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setDataLoaded(true);
		getUserRemainingGifts();
	}, [dataLoaded]);

	const descrTextLength = 125;

	const onClickHandler = (): void => {
		if (role) {
			switch (role) {
				case 'MEMBER':
					switch (item.type) {
						case 'LONGFORM':
							setSignupDialogOpen(true, 'UPGRADEPREMIUMSTEPONE');
							break;
						case 'SHORTFORM':
							setSelectedTitleId(item.titleId);
							toggleShowVideo(true);
							break;
					}
					break;

				case 'PREMIUM':
					setSelectedTitleId(item.titleId);
					toggleShowVideo(true);
					break;

				// FREE
				default:
					switch (item.type) {
						case 'LONGFORM':
							setSignupDialogOpen(true, 'UPGRADEPREMIUMSTEPONE');
							break;
						case 'SHORTFORM':
							setSignupDialogOpen(true, 'UPGRADEFREE');

							break;
					}
					break;
			}
		} else {
			// IF NO ROLE
			switch (item.type) {
				case 'LONGFORM':
					setSignupDialogOpen(true, 'REGISTERPREMIUMSTEPONE');
					break;
				case 'SHORTFORM':
					if (numFreeViews < 3) {
						setSelectedTitleId(item.titleId);
						toggleShowVideo(true);
					} else {
						setSignupDialogOpen(true, 'REGISTERFREE');
					}
					break;

				default:
					setSignupDialogOpen(true, 'REGISTERFREE');
					break;
			}
		}
	};

	const handleGiftClick = async (titleId: String) => {
		setGiftLoading(true);
		setSignupDialogOpen(true, 'GIFTSONG');

		try {
			const response = await axios.get(
				`https://api.svc.staccs.se/api/envoy/link?titleId=${titleId}`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
				}
			);

			if (response.status === 200) {
				setGift({
					url: response.data.url,
					userRemainingQuota: response.data.userRemainingQuota,
					img: item.thumbNail,
					title: {
						name: item.name,
						artist: item.artistName,
					},
				});
				setGiftLoading(false);
				setUserRemainingQuota(response.data.userRemainingQuota);
			} else {
				setGiftLoading(false);
				setSignupDialogOpen(false);
			}
		} catch (err) {
			setGiftLoading(false);
			setSignupDialogOpen(false);
		}
	};

	const clickAwayHandler = () => {
		toggleShowVideo(false);
		setAutoPlayingStaccsTV(false);
	};

	return (
		<ClickAwayListener onClickAway={clickAwayHandler}>
			<div
				className="discovery-card"
				ref={ref}
				style={{
					height: readMoreExpanded ? 'auto' : '450px',
				}}
			>
				<>
					<div
						className="discovery-card__header"
						style={{ paddingBottom: '20px' }}
					>
						{!autoPlayingStaccsTV ? (
							<>
								<div
									className="discovery-card__artist-avatar"
									style={{
										// backgroundImage: `url('${item.artistImageUrl}')`,
										backgroundImage: `url('https://www.staccs.com/cdn-cgi/image/quality=70,h=90/${item.artistImageUrl}')`,
									}}
								/>

								<span className="discovery-card__artist-name">
									<a
										className="discovery-card__artist-name-link"
										target="_blank"
										rel="noreferrer"
										href={`/artist/${item.titleArtist}`}
									>
										{item.artistName}
									</a>
								</span>
								<VerifiedIcon className="discovery-card__artist-verified" />
							</>
						) : (
							<>
								<div
									className="discovery-card__artist-avatar"
									style={{
										background: `center / contain no-repeat url('https://www.staccs.com/cdn-cgi/image/quality=70,h=45/${staccsThumb}')`,
										// backgroundSize: 'contain',
									}}
								/>
								<span className="discovery-card__artist-name">
									<p className="discovery-card__artist-name-link">Staccs TV</p>
								</span>
							</>
						)}
					</div>
					{showVideo && inView ? (
						<Player
							setAutoPlayingStaccsTV={setAutoPlayingStaccsTV}
							contentType={item.type}
							showVideo={showVideo}
							toggleShowVideo={toggleShowVideo}
						/>
					) : (
						<div
							className="discovery-card__player-placeholder"
							style={{
								// aspectRatio: item.type === "SHORTFORM" ? "1 / 1" : "3 / 2",
								aspectRatio: '3 / 2',
								// background: `no-repeat center/cover url('${item.thumbNail}')`,
								background: `no-repeat center/cover url('https://www.staccs.com/cdn-cgi/image/quality=70,h=400/${item.thumbNail}')`,
							}}
						>
							{/* PREMIUM RIBBON - shown for all non-premium members */}
							{((item.type === 'LONGFORM' && role && role === 'MEMBER') ||
								(item.type === 'LONGFORM' && !role)) && (
								<div className="discovery-card__premium-ribbon">
									<span className="discovery-card__premium-ribbon-text">
										PREMIUM
									</span>
								</div>
							)}

							<div className="duration__wrapper">
								<p className="duration-text">
									{deluxeTimeFormatter(item.duration)}
								</p>
							</div>

							<div
								className="discovery-card__player-overlay"
								onClick={onClickHandler}
							>
								<div
									className="discovery-card__play-button"
									onClick={onClickHandler}
								>
									<PlayIcon fontSize="large" />
								</div>
							</div>
						</div>
					)}

					{/* SUB SECTION */}
					<div className="discovery-card__footer">
						{/* TITLE INFO */}
						{!autoPlayingStaccsTV ? (
							<div className="discovery-card__footer-header">
								{/* TITLE NAME */}
								<span className="discovery-card__footer-title">
									{autoPlayingStaccsTV ? 'STACCS-TV' : item.name}
								</span>
								<div className="discovery-card__footer-buttons">
									{item.envoyActive &&
									accessToken &&
									!autoPlayingStaccsTV &&
									userRemainingQuota > 0 ? (
										<button
											className="discovery-card__gift-button"
											onClick={() => handleGiftClick(item.titleId)}
										>
											<GiftLogo width="15px" />
											Gift
										</button>
									) : (
										<ShareButton item={item} />
									)}
								</div>
							</div>
						) : null}

						{!autoPlayingStaccsTV ? (
							<>
								<p className="discovery-card__footer-descr">
									{readMoreExpanded
										? item?.description
										: longStringFormatter(item?.description, descrTextLength)}
								</p>

								{item?.description?.length > descrTextLength && (
									<button
										className="discovery-card__footer-read-more-btn"
										onClick={() => setReadMoreExpanded((current) => !current)}
									>
										{readMoreExpanded ? 'Read Less' : 'Read More'}
									</button>
								)}
							</>
						) : (
							<p className="discovery-card__footer-descr">
								Can't decide what to watch? Let STACCS-TV keep you company for
								now. It showcases some of our favorite performances and lets you
								discover what's on Staccs.
							</p>
						)}
					</div>
				</>
			</div>
		</ClickAwayListener>
	);
};

export default Card;
