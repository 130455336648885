/**
 * Sends Player Stats to https://playstat.staccs.com
 *
 * @param {string} contentType
 * @param {string} titleId
 * @param {string} platformOs
 * @param {string} artistName
 * @param {string} titleName
 * @param {string} displayName
 * @param {string} genre
 * @param {string} countryCode
 */
type ContentType = 'STACCS-TV' | 'track' | 'concert' | 'documentary' | 'artist';

const sendPlayerStats = (
	token: any,
	contentType: string,
	titleId: string,
	platformOs: string,
	artistName: string,
	titleName: string,
	displayName: string,
	genre: string,
	countryCode: string
) => {
	//Don't send data in development
	if (process.env.NODE_ENV === 'development') {
		console.log('sendPlayerStats > development log out stats');
		console.log({
			token,
			contentType,
			titleId,
			platformOs,
			artistName,
			titleName,
			displayName,
			genre,
			countryCode,
		});
		return;
	}
	console.log('sendPlayerStats > Fetch POST to server');

	if (token) {
		fetch(`https://playstat.staccs.com/api/history/startedminute`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			// body: JSON.stringify({}), // body data type must match "Content-Type" header
			body: JSON.stringify({
				titleType: contentType,
				titleId: titleId,
				app: platformOs,
				artistName: artistName,
				titleName: titleName,
				displayName: displayName,
				genre: genre,
				countryCode: countryCode,
			}),
		})
			.then((res) => console.log('res.status', res.status))
			.catch((error) =>
				console.error(
					`Error ${error.name} when posting player stats. Error Message: ${error.message}`
				)
			);
	}
};
export default sendPlayerStats;
