
interface CtaContainerProps {
  bgColor: string;
  fontColor: string;
  buttonColor: string;
  buttonTextColor: string;
  onClick: () => void;
  ctaText: string;
  ctaBtnText: string;
}

const CtaContainer = ({
  bgColor,
  fontColor,
  buttonColor,
  buttonTextColor,
  onClick,
  ctaText,
  ctaBtnText,
}) => {
  return (
    <div
      className="cta-container"
      style={{
        backgroundColor: `${bgColor}`,
      }}
    >
      <p className="cta-container__text" style={{ color: `${fontColor}` }}>
        {ctaText}
      </p>
      <button
        className="artist-collab-btn"
        style={{ backgroundColor: `${buttonColor}`, color: `${buttonTextColor}` }}
        onClick={onClick}
      >
        {ctaBtnText}
      </button>
    </div>
  );
};

export default CtaContainer;
