interface USPsListProps {
  USPs: Array<IUSPs>;
}

interface IUSPs {
  text: string;
  active: boolean;
}

const USPsList: React.FC<USPsListProps> = ({ USPs }): JSX.Element => {
  return (
    <ul className="plan-card__USPs">
      {USPs?.map((usp, index) => (
        <li key={index} className={usp.active ? `plan-card__USP` : `plan-card__USP--grayed`}>
          <>
          <i className={usp.active ? `fa fa-check` : `fa fa-times`} />
          {usp.text}
          </>
        </li>
      )
      )}
    </ul>
  );
};

export default USPsList;
