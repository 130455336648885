import { useContext, useState } from 'react';
import { DialogContext } from './../../../../Context/DialogContext';
import { TempAuthContext } from './../../../../Context/tempAuthContext';
import { useInView } from 'react-intersection-observer';
import Player from './player/PlayScreen';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PlayIcon from '@mui/icons-material/PlayArrowOutlined';
import staccsTVPlaceholder from '../../../../Assets/Images/staccstv_new_thumbnail-min.png';

const StaccsTV = () => {
	const { ref, inView, entry } = useInView({
		threshold: 0,
	});

	const { setSignupDialogOpen } = useContext(DialogContext);
	const { role } = useContext(TempAuthContext);

	const [showVideo, toggleShowVideo] = useState(false);

	const onClickHandler = () => {
		if (role) {
			switch (role) {
				case 'MEMBER':
				case 'PREMIUM':
					toggleShowVideo(true);
					break;

				default:
					// SHOULD BE UPDATE MEMBERSHIP
					setSignupDialogOpen(true, 'REGISTERFREE');
					break;
			}
		} else {
			setSignupDialogOpen(true, 'REGISTERFREE');
		}
	};

	return (
		<section className="staccs-tv" id="staccs-tv">
			<ClickAwayListener onClickAway={() => toggleShowVideo(false)}>
				<div
					ref={ref}
					className="staccs-tv__wrapper"
					onBlur={() => toggleShowVideo(false)}
				>
					<div className="staccs-tv__content">
						{showVideo ? (
							<Player />
						) : (
							<div className="staccs-tv__placeholder">
								<div
									className="staccs-tv__player-overlay"
									onClick={onClickHandler}
								>
									<div
										className="staccs-tv__play-button"
										onClick={onClickHandler}
									>
										<PlayIcon fontSize="large" />
									</div>
								</div>
								<img
									src={staccsTVPlaceholder}
									// src={StaccsTvPlaceholder}
									alt="Staccs TV"
									className="staccs-tv__placeholder-img"
								/>
							</div>
						)}

						<div className="staccs-tv__footer">
							<h3 className="staccs-tv__footer-heading">Staccs TV</h3>
							<p className="staccs-tv__footer-text">
								Can't decide what to watch? Let STACCS-TV keep you company for
								now. It showcases some of our favorite performances and lets you
								discover what's on Staccs.
							</p>
						</div>
					</div>
				</div>
			</ClickAwayListener>
		</section>
	);
};

export default StaccsTV;
