import PropTypes from "prop-types";

interface ArtistInfoProps {
  artistCollab: ArtistCollab;
  artistCollabLanguage: string;
}

/**
 * Renders Artist Portrait/info section for artist collaboration pages
 * @param   {Object}  artistCollab  Artist collab object(LangContext)
 * @returns {HTMLElement}
 */
const ArtistInfo: React.FC<ArtistInfoProps> = ({
  artistCollab,
  artistCollabLanguage,
}): JSX.Element => {
  return (
    <section
      className="presentation"
      style={{
        backgroundColor: `${artistCollab?.backgroundColor}`,
      }}
    >
      <div className="presentation__wrapper artist">
        <div className="presentation__text">
          <p
            className="presentation__p"
            style={{ color: `${artistCollab?.bodyFontColor}` }}
          >
            {artistCollab?.texts?.[artistCollabLanguage]?.infoText}
          </p>
        </div>

        <img
          src={artistCollab?.infoImage}
          alt={artistCollab?.name}
          className="presentation__artist-portrait"
        />
      </div>
    </section>
  );
};

// Plz finish me
// ArtistInfo.propTypes = {
//   artistCollab: PropTypes.shape({

//   }).isRequired,
//   artistCollabLanguage: PropTypes.string.isRequired,
// };

export default ArtistInfo;
