import React from "react";

import Card from "./Card";

function LongBar({ items }) {
  return (
    <div
      className="noscroll"
      style={{
        overflowX: "scroll",
        maxWidth: "100%",
        backgroundColor: "black",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", width: "fit-content" }}
      >
        {items.map((item) => (
          <Card item={item} />
        ))}
      </div>
    </div>
  );
}

export default LongBar;
