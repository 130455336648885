import { useContext, useEffect, useRef, useState } from 'react';
import { PlansContext } from '../../../Context/PlansContext';
import Loader from '../../Loader/Loader';
import PlanCard from './PlanCard/PlanCard';
import ReactCardCarousel from 'react-card-carousel';

interface Button extends HTMLElement {
	next: () => void;
	prev: () => void;
}
const PlanList = () => {
	const { stripePricesLoading, prices } = useContext(PlansContext);
	const [showCarousel, setShowCarousel] = useState(false);
	const carouselRef = useRef<Button>();
	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);
	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 50;
	const onTouchStart = (e) => {
		setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX);
	};
	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		isLeftSwipe && carouselRef.current.next();
		isRightSwipe && carouselRef.current.prev();
	};
	useEffect(() => {
		window.innerWidth < 685 ? setShowCarousel(true) : setShowCarousel(false);
		const handleResize = () => {
			if (window.innerWidth < 685 && !showCarousel) {
				setShowCarousel(true);
			}
			if (window.innerWidth > 685 && showCarousel) {
				setShowCarousel(false);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [showCarousel]);
	if (stripePricesLoading) return <Loader />;

	return (
		<ul className="pricing__plans-wrapper">
			{prices?.length > 0 ? (
				<>
					{showCarousel ? (
						<div
							className="card-carousel-wrapper"
							onTouchStart={onTouchStart}
							onTouchMove={onTouchMove}
							onTouchEnd={onTouchEnd}
						>
							<ReactCardCarousel
								ref={carouselRef}
								autoplay={false}
								autoplay_speed={2500}
							>
								{prices?.map((price) => (
									<PlanCard key={price.id} price={price} />
								))}
							</ReactCardCarousel>
						</div>
					) : (
						<>
							{prices?.map((price) => (
								<PlanCard key={price.id} price={price} />
							))}
						</>
					)}
				</>
			) : (
				<div>
					<h2 className="section-heading" style={{ color: 'white' }}>
						Staccs is currently not available in your country!
					</h2>
				</div>
			)}
		</ul>
	);
};
export default PlanList;
