import { RWebShare } from "react-web-share";
import "./styles.css";
import { Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

interface ShareButtonProps {
  item: {
    artistName: string;
    displayName: string;
    genre: string;
    name: string;
    thumbNail: string;
    titleId: string;
    type: string;
  };
}

const ShareButton: React.FC<ShareButtonProps> = ({ item }): JSX.Element => {
  return (
    <RWebShare
      data={{
        text: "Staccs",
        url: "https://app.staccs.com/title/" + item.titleId,
        title: item.displayName,
      }}
      onClick={(e) => console.info("share successful!")}
    >
      <Button name="Share" className="discovery-card__share-button">
        <ShareIcon />
      </Button>
    </RWebShare>
  );
};

export default ShareButton;
