import { useContext, useEffect, useState } from 'react';
import { PlansContext } from '../../../Context/PlansContext';
import { TempAuthContext } from '../../../Context/tempAuthContext';
import { LanguageContext } from '../../../Context/language';
import CtaButton from '../../Buttons/CTA/CtaButton';

import Loader from '../../Loader/Loader';
import PlanCard from './PlanCard/PlanCard';
import { api_url } from '../../../Constants/constants';
import axios from 'axios';
import { log } from 'console';

interface Props {
	priceId?: string;
	collabPrices?: [string];
	discountPrice?: string;
	discountCoupon?: string;
	partnerName?: string;
}

const UpgradePlanList = ({
	discountCoupon,
	partnerName,
	discountPrice,
}: Props) => {
	const {
		prices,
		partnerPrice,
		stripeCustomerBalance,
		setStripeCustomerBalance,
	} = useContext(PlansContext);
	const { language } = useContext(LanguageContext);

	const { role, user, accessToken, getAccount } = useContext(TempAuthContext);
	const [loading, setLoading] = useState(true);
	const [loadingDiscount, setLoadingDiscount] = useState<boolean>(false);
	const [noUpgrade, setNoUpgrade] = useState(false);
	const [pricesToRender, setPricesToRender] = useState([]);

	const locale = localStorage.getItem('locale');

	useEffect(() => {
		getAccount();
	}, []);

	useEffect(() => {
		// if (accessToken && !user) {
		// 	getAccount(accessToken);
		// 	console.log('1');
		// }

		if (user?.subscription?.comp) {
			setNoUpgrade(true);
		} else {
			switch (true) {
				case !!partnerPrice:
					setPricesToRender([partnerPrice]);
					break;
				case role === 'MEMBER':
					setPricesToRender(prices);
					break;

				case role === 'PREMIUM':
					if (user?.subscription?.plan?.nickname?.toUpperCase() === 'YEARLY') {
						setNoUpgrade(true);
					}
					if (user?.subscription?.plan?.nickname?.toUpperCase() === '72H') {
						setPricesToRender(
							prices.filter(
								(price) => price?.nickname?.toUpperCase() !== 'MEMBER'
							)
						);
					}

					if (user?.subscription?.plan?.nickname?.toUpperCase() === 'MONTHLY') {
						setPricesToRender(
							prices.filter(
								(price) =>
									price.nickname?.toUpperCase() !== 'MEMBER' &&
									price.nickname?.toUpperCase() !== '72H'
							)
						);
					}
					break;
				case role === 'FREE':
					setPricesToRender(prices);
					break;
				default:
					break;
			}
		}

		setLoading(false);
	}, [user]);

	useEffect(() => {
		if (discountCoupon && stripeCustomerBalance === 0) {
			setLoadingDiscount(true);
			let currency: string;
			if (locale === 'se') {
				currency = 'sek';
			} else if (locale === 'no') {
				currency = 'nok';
			} else if (locale === 'dk') {
				currency = 'dkk';
			} else if (
				locale === 'fi' ||
				locale === 'ee' ||
				locale === 'lv' ||
				locale === 'lt' ||
				locale === 'ax'
			) {
				currency = 'eur';
			}
			let couponId = discountCoupon;

			const giveCustomerDiscount = async () => {
				const createCoupon = {
					method: 'post',
					url: `${api_url}/api/stripe/coupon`,
					headers: {
						'Content-Type': 'application/json',
					},
					data: JSON.stringify({
						stripeId: user?.stripeId,
						couponId,
						currency,
						discountPrice,
					}),
				};
				const response = await axios(createCoupon);
				if (response.status === 200) {
					setStripeCustomerBalance(response.data.amount_off);
					setLoadingDiscount(false);
				}
			};
			giveCustomerDiscount();
		}
	}, []);

	if (loading || loadingDiscount) return <Loader />;

	return (
		<ul className="upgrade__plans-wrapper">
			{pricesToRender?.length > 0 ? (
				<>
					{pricesToRender?.map((price: StripePrice) => (
						<>
							<PlanCard
								key={price.id}
								price={price}
								upgrade
								partnerName={partnerName}
							/>
							{/* <PlanCard key={price.id} price={price} upgrade />{" "} */}
						</>
					))}
				</>
			) : noUpgrade ? (
				<div>
					<h2
						className="section-heading--light"
						style={{
							fontSize: '20px',
							fontWeight: 'initial',
							marginBottom: '20px',
						}}
					>
						{language.fullAccess}
					</h2>
					<CtaButton
						text={language.streamNow}
						textColor="black"
						link="https://app.staccs.com"
						boxShadow="0px 8px 15px rgba(0, 0, 0, 0.1)"
						bgColor="#FFC65A"
						internLink={false}
					/>
				</div>
			) : (
				<Loader />
			)}
		</ul>
	);
};

export default UpgradePlanList;
