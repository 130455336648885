import HeroImgQueen from '../../../Assets/Images/hero-bg.jpg';

const NotAvailable = (props) => {
	return (
		<div className="not-available">
			<div
				className="not-available__content"
				style={{
					background: `linear-gradient(110deg, rgba(23, 136, 141, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
            rgba(160, 78, 49, 0.85) 85%), url(${HeroImgQueen}) no-repeat center center/cover`,
				}}
			>
				<h1 className="not-available__content__heading">
					<span className="staccs">STACCS</span> IS CURRENTLY NOT AVAILABLE IN
					YOUR COUNTRY
				</h1>
				{/* <p>Sign up to get notified when we launch in your country.</p> */}
			</div>
		</div>
	);
};

export default NotAvailable;
