import { Fragment, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import ProfileIcon from '@mui/icons-material/AccountBox';
import { TempAuthContext } from '../../Context/tempAuthContext';
import { DialogContext } from '../../Context/DialogContext';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../Context/language';

export default function TemporaryDrawer() {
	const { logout, accessToken, userProfile } = useContext(TempAuthContext);
	const { setView, toggleSignupDialogOpen } = useContext(DialogContext);
	const { language, access } = useContext(LanguageContext);

	const navigate = useNavigate();

	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor: any, open: any) => (event: any) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const anchor = 'right';

	const openSignInModal = () => {
		toggleSignupDialogOpen(true);
		setView('SIGNIN');
	};

	const menuItems = [
		{
			text: accessToken ? 'Log Out' : 'Log In',
			icon: accessToken ? LogoutIcon : LoginIcon,
			onPress: accessToken ? () => logout() : () => openSignInModal(),
		},
	];

	const aboutStaccsItems = [
		{
			text: language.footerCompany1,
			onPress: () => navigate('/about'),
		},
		{
			text: language.footerCompany2,
			onPress: () => navigate('/terms'),
		},
		{
			text: language.footerCompany3,
			onPress: () => navigate('/privacy'),
		},
		// {
		// 	text: language.footerCompany4,
		// 	onPress: () => (window.location.href = 'https://careers.staccs.com'),
		// },
	];

	const usefulItems = [
		{
			text: language.footerUseFull1,
			onPress: () => navigate('/support'),
		},
	];

	const navigateItems = access
		? [
				{
					text: 'Home',
					onPress: () => navigate('/'),
				},
		  ]
		: [];

	const socialItems = [
		{
			icon: InstagramIcon,
			link: 'https://www.instagram.com/worldofstaccs/',
		},
		{
			icon: FacebookIcon,
			link: 'https://www.facebook.com/worldofstaccs/',
		},
	];

	const downloadAppsItems = [
		{
			img: {
				src: '/images/appstore.svg',
				alt: language.footerDownload,
			},
			link: 'https://apps.apple.com/us/app/staccs/id1566144790',
		},
		{
			img: {
				src: '/images/googleplay.png',
				alt: language.footerDownload,
			},
			link: 'https://play.google.com/store/apps/details?id=com.staccs',
		},
	];

	const list = (anchor) => (
		<Box
			sx={{
				width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
			}}
			role="presentation"
			onClick={() => toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				minHeight: '100%',
				overflowY: 'auto',
				overflowX: 'hidden',
				backgroundColor: '#141414',
				color: 'white',
				paddingBottom: '1rem',
			}}
		>
			<div>
				<div style={{ backgroundColor: '#1c1c1c', paddingBottom: '1rem' }}>
					<List>
						{menuItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton
									onClick={item.onPress}
									sx={{ '&:hover': { backgroundColor: 'grey' } }}
								>
									<ListItemIcon>
										<item.icon sx={{ color: 'white' }} />
									</ListItemIcon>
									<p style={{ fontSize: '1.5rem' }}>{item.text}</p>
								</ListItemButton>
							</ListItem>
						))}
					</List>

					{accessToken && (
						<>
							<ListItem disablePadding>
								<ListItemButton onClick={() => navigate('/account')}>
									<ListItemIcon>
										<ProfileIcon sx={{ color: 'white' }} />
									</ListItemIcon>
									<p style={{ fontSize: '1.5rem' }}>
										{userProfile?.name || userProfile?.email}
									</p>
								</ListItemButton>
							</ListItem>
						</>
					)}
				</div>

				{/* <Divider
					style={{
						backgroundColor: 'grey',
						height: '2px',
						margin: '1rem 0',
						boxShadow: '0 0 3px black',
					}}
				/> */}

				<div style={{}}>
					<List>
						{navigateItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton onClick={item.onPress}>
									<p style={{ fontSize: '1.5rem' }}>{item.text}</p>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</div>
			</div>

			<div>
				<div style={{}}>
					<h3
						style={{
							fontSize: '1.5rem',
							margin: '0 1.5rem',
							color: '#ffca43',
						}}
					>
						{language?.footerCompany?.toUpperCase()}
					</h3>
					<List>
						{aboutStaccsItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton onClick={item.onPress}>
									<p style={{ fontSize: '1.5rem' }}>{item.text}</p>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</div>

				<div style={{}}>
					<h3
						style={{
							fontSize: '1.5rem',
							margin: '0 1.5rem',
							color: '#ffca43',
						}}
					>
						{language?.footerUseFull?.toUpperCase()}
					</h3>
					<List>
						{usefulItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton onClick={item.onPress}>
									<p style={{ fontSize: '1.5rem' }}>{item.text}</p>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</div>
				<div style={{ margin: '0.5rem 1.5rem' }}>
					<h3
						style={{
							fontSize: '1.5rem',
							marginBottom: '1rem',
							color: '#ffca43',
						}}
					>
						{language?.footerConnect?.toUpperCase()}
					</h3>
					{socialItems.map((item, index) => (
						<a
							key={index}
							href={item.link}
							target="_blank"
							rel="noreferrer"
							style={{ marginRight: '1rem' }}
						>
							<item.icon sx={{ color: 'white', fontSize: '2.5em' }} />
						</a>
					))}
				</div>
				<div style={{ margin: '0.5rem 1.5rem' }}>
					<h3
						style={{
							fontSize: '1.5rem',
							marginBottom: '1rem',
							color: '#ffca43',
						}}
					>
						{language?.footerDownload?.toUpperCase()}
					</h3>
					{downloadAppsItems.map((item, index) => (
						<a
							key={index}
							href={item.link}
							target="_blank"
							rel="noreferrer"
							style={{ marginRight: '1rem' }}
						>
							<img
								src={item.img.src}
								alt={item.img.alt}
								style={{ width: '50%' }}
							/>
						</a>
					))}
				</div>
			</div>
		</Box>
	);

	return (
		<Fragment key={anchor}>
			<Button style={{ color: '#ffd85b' }} onClick={toggleDrawer(anchor, true)}>
				<MenuIcon className="menu-icon" style={{ fontSize: '4em' }} />
			</Button>

			<Drawer
				anchor={anchor}
				open={state[anchor]}
				onClose={toggleDrawer(anchor, false)}
				sx={{
					width: 250,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: 250,
						boxSizing: 'border-box',
					},
				}}
			>
				{list(anchor)}
			</Drawer>
		</Fragment>
	);
}
