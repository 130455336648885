import React, { useContext } from "react";

import { DialogContext } from "./../../../../Context/DialogContext";

import { useInView } from "react-intersection-observer";

function Card({ item }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const { signupDialogOpen, setSignupDialogOpen } = useContext(DialogContext);

  const onClickHandler = () => {
    setSignupDialogOpen(true);
  };
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // backgroundColor: "red",
        width: "300px",
        // height: "250px",
        // padding: "10px",
        padding: "0px",
        borderRadius: "5px",
        margin: "5px",
        overflow: "hidden",
      }}
      onClick={onClickHandler}
    >
      {inView ? (
        <>
          <img src={item.imgUrl} />
          <div
            style={{
              display: "flex",

              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              backgroundColor: "rgba(20,20,20,1)",
              //   textAlign: "left",
              padding: "5px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <p>Summer love</p>
              <p>...</p>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Card;
