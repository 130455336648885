import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../../Context/language';
import { RegisterContext } from '../../../Context/RegisterContext';
import USPsList from '../../../Components/Pricing/PlanList/PlanCard/USPsList/USPsList';
import { PlansContext } from '../../../Context/PlansContext';

const PriceCard = ({ price, onClickOption }): JSX.Element => {
	const { formData } = useContext(RegisterContext);
	const { language } = useContext(LanguageContext);
	const { stripeCustomerBalance } = useContext(PlansContext);
	let locale = window.localStorage.getItem('locale');

	interface LocationState {
		priceId?: string;
		partnerName?: string;
		discountPrice?: string;
	}

	const location = useLocation();
	const locationState: LocationState = location.state;

	return (
		<div
			className={
				formData?.priceId === price?.id
					? 'registration__plan--selected'
					: 'registration__plan'
			}
			onClick={() => onClickOption(price)}
		>
			{/* Name and price */}
			<div>
				{/* Partner name */}
				{locationState?.partnerName ? (
					<>
						<h3 className="registration__plan-name">
							{locationState?.partnerName}
						</h3>
						<ul className="registration__plan-USPs">
							{price?.recurring?.interval === 'month' ? (
								<USPsList USPs={language.USPs.monthlySub} />
							) : price?.recurring?.interval === 'year' ? (
								<USPsList USPs={language.USPs.yearlySub} />
							) : null}
						</ul>
					</>
				) : locationState?.discountPrice ? (
					<>
						<h3 className="registration__plan-name">
							{locale === 'se'
								? `Kampanj ${
										price?.recurring?.interval === 'month' ? 'Månad' : 'År'
								  }`
								: `Campaign ${
										price?.recurring?.interval === 'month' ? 'Month' : 'Year'
								  }`}
						</h3>
						<ul className="registration__plan-USPs">
							{price?.recurring?.interval === 'month' ? (
								<USPsList USPs={language.USPs.monthlySub} />
							) : price?.recurring?.interval === 'year' ? (
								<USPsList USPs={language.USPs.yearlySub} />
							) : null}
						</ul>
					</>
				) : (
					<>
						{locale === 'se' ? (
							<h3 className="registration__plan-name">
								{price?.nickname === 'Monthly'
									? 'Månad'
									: price?.nickname === 'Yearly'
									? 'År'
									: price?.nickname === 'Member'
									? 'Medlem'
									: price?.nickname}
							</h3>
						) : (
							<h3 className="registration__plan-name">{price?.nickname}</h3>
						)}
						<ul className="registration__plan-USPs">
							{price?.nickname === 'Monthly' ? (
								<USPsList USPs={language.USPs.monthlySub} />
							) : price?.nickname === 'Yearly' ? (
								<USPsList USPs={language.USPs.yearlySub} />
							) : price?.nickname === 'Member' ? (
								<USPsList USPs={language.USPs.freemium} />
							) : null}
						</ul>
					</>
				)}
			</div>

			{locationState?.discountPrice ? (
				<p className="registration__plan-price">
					{(price?.unit_amount - stripeCustomerBalance) / 100 +
						' ' +
						price?.currency?.toLocaleUpperCase()}
				</p>
			) : price?.nickname !== 'Member' &&
			  price?.recurring?.trial_period_days !== 365 ? (
				<p className="registration__plan-price">
					{(price?.unit_amount / 100).toLocaleString() +
						' ' +
						price?.currency?.toLocaleUpperCase()}
				</p>
			) : price?.nickname === 'Member' ? (
				<>
					{locale === 'se' ? (
						<>
							<p className="registration__plan-price">{'Gratis'}</p>
							<p style={{ fontSize: '13px' }}>
								{/* {`(Värde ${price?.unit_amount * 12 / 100} ${price?.currency})`} */}
								{`${
									price?.unit_amount / 100
								} ${price?.currency.toLocaleUpperCase()} per månad efter 1 år`}
							</p>
						</>
					) : (
						<>
							<p className="registration__plan-price">{'Free'}</p>
							<p style={{ fontSize: '13px' }}>
								{/* {`(Value ${price?.unit_amount * 12 / 100} ${price?.currency})`} */}
								{`${
									price?.unit_amount / 100
								} ${price?.currency.toLocaleUpperCase()} per month after 1 year`}
							</p>
						</>
					)}
				</>
			) : null}
			{/* Test period */}
			{price?.recurring?.trial_period_days && (
				<p className="registration__plan-trial-info">
					{price?.recurring?.trial_period_days} {language.freeTrialDays}
				</p>
			)}
		</div>
	);
};

export default PriceCard;
