import React, { useEffect, useRef, useState, useContext } from 'react';
import videojs from 'video.js';
import PlayIcon from '@mui/icons-material/PlayArrowSharp';
import PauseIcon from '@mui/icons-material/PauseSharp';
import Slider from '@mui/material/Slider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeMuted from '@mui/icons-material/VolumeMuteSharp';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import { VideoControllerContext } from '../../../../../../Context/videoContext';
import 'video.js/dist/video-js.css';
import sendPlayerStats from '../../../../../../Utils/helpers/playerStats';
import { deluxeTimeFormatter } from '../../../../../../Utils/helpers';

const VideoPlayer = ({
	options,
	onReady,
	title,
	toggleShowVideo,
	setAutoPlayingStaccsTV,
}) => {
	const { videoVolume, setVideoVolume, videoMuted, setVideoMuted } = useContext(
		VideoControllerContext
	);
	const playerRef = useRef(null);
	const videoRef = useRef(null);

	const [showPlay, setShowPlay] = useState(true);
	const [showVolume, setShowVolume] = useState(false);
	const [showControls, setShowControls] = useState(true);
	const [fullscreen, toggleFullscreen] = useState(false);
	const [sliderValue, setSliderValue] = useState(0);
	const [videoTime, setVideoTime] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [showControlsTimeout, setShowControlsTimeout] = useState(0);
	const [iosUnit, setIosUnit] = useState(false);
	const [staccsTVPlaying, setStaccsTVPlaying] = useState(false);

	const oldToken = localStorage.getItem('old_token');
	const locale = localStorage.getItem('locale');

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = videoRef.current;

			/// check if device is ios - for different controls ///
			let isIOS =
				/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			if (isIOS) {
				setIosUnit(true);
				setShowControls(false);
			}

			if (!videoElement) return;

			videoElement?.addEventListener(
				'webkitbeginfullscreen',
				handleFullscreen,
				false
			);
			videoElement?.addEventListener(
				'webkitendfullscreen',
				handleFullscreen,
				false
			);
			videoElement?.addEventListener(
				'ended',
				() => {
					const player = playerRef.current;
					player.autoplay(options.autoplay);
					player.src([
						{
							src: 'https://world-of-staccs-staccs-tv-1-us.ono.wurl.tv/playlist.m3u8',
							type: 'application/x-mpegURL',
						},
					]);
					setStaccsTVPlaying(true);
					setAutoPlayingStaccsTV(true);
				},
				false
			);

			const player = (playerRef.current = videojs(videoElement, options, () => {
				videojs.log('player is ready');
				onReady && onReady(player);
			}));
		} else {
			const player = playerRef.current;
			player.autoplay(options.autoplay);
			player.src(options.sources);
		}
	}, [options, videoRef]);

	useEffect(() => {
		let secondsOfMinute = 0;
		let freeSecondsPlayed = 0;

		let deviceType = 'web';
		if (navigator.userAgent.toLowerCase().match(/ipad|iphone/i)) {
			deviceType = 'iOS';
		} else if (navigator.userAgent.toLowerCase().match(/android/i)) {
			deviceType = 'android';
		}

		const freeTimer = setInterval(() => {
			if (!playerRef?.current?.paused()) {
				freeSecondsPlayed++;
			}
			const onePlay = 10;

			if (freeSecondsPlayed === onePlay) {
				const numfreeViews = +window.localStorage.getItem('_nfv') || 0;
				if (numfreeViews < 3) {
					window.localStorage.setItem('_nfv', numfreeViews + 1);
				}
			}
		}, 1000);

		const timer = setInterval(() => {
			if (!playerRef?.current?.paused()) {
				secondsOfMinute++;
			}

			const staccsTVSendStats = [
				oldToken,
				'STACCS-TV',
				'STACCS-TV',
				`discovery - ${deviceType}`,
				'STACCS',
				'STACCS-TV',
				'STACCS - STACCS-TV',
				'rock',
				locale,
			];

			const titleSendStats = [
				oldToken,
				title.contentType,
				title.titleId,
				`discovery - ${deviceType}`,
				title.artistName,
				title.name,
				title.displayName,
				title.genre,
				locale,
			];

			const sendStatsInterval = 60;

			if (secondsOfMinute === sendStatsInterval) {
				secondsOfMinute = 0;
				console.log('staccstvPlaying: ', staccsTVPlaying);
				staccsTVPlaying
					? sendPlayerStats(...staccsTVSendStats)
					: sendPlayerStats(...titleSendStats);
			}
		}, 1000);

		return () => {
			clearInterval(freeTimer);
			clearInterval(timer);
		};
	}, [staccsTVPlaying]);

	useEffect(() => {
		const player = playerRef.current;
		player.volume(videoVolume / 100);
		if (videoMuted) {
			playerRef.current.volume(0);
		}

		return () => {
			if (player) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	useEffect(() => {
		clearTimeout(showControlsTimeout);
	}, [showControls]);

	const handlePlayPause = () => {
		setShowPlay((current) => !current);
		const player = playerRef.current;
		showPlay ? player.pause() : player.play();
	};

	const handleFullscreen = () => {
		!fullscreen
			? playerRef?.current?.requestFullscreen()
			: playerRef?.current?.exitFullscreen();

		toggleFullscreen((current) => !current);
	};

	/// functionality for sliding and setting video time ///
	const changeSliderValue = (e) => {
		setSliderValue(e.target.value);
		const videoDuration = playerRef.current.cache_.duration;
		playerRef.current.currentTime(
			Math.floor((videoDuration / 100) * e.target.value)
		);
	};

	const handleVolume = (e) => {
		setVideoVolume(e.target.value);
		playerRef.current.volume(e.target.value / 100);
		setVideoMuted(false);
		if (e.target.value === 0) {
			setVideoMuted(true);
		}
	};

	const handleMute = () => {
		if (!videoMuted) {
			playerRef.current.volume(0);
		} else {
			playerRef.current.volume(videoVolume / 100);
		}
		setVideoMuted((current) => !current);
	};

	/// functionality for hiding the cursor and controls if idle in fullscreen ///
	const handleIdleCursor = () => {
		if (fullscreen) {
			clearTimeout(showControlsTimeout);
			setShowControlsTimeout(
				setTimeout(() => {
					setShowControls(false);
					console.log('hide controlls');
				}, 2500)
			);
			setShowControls(true);
		}
	};

	/// functionality for making the slider move as the video progresses ///
	const handleTimeUpdate = () => {
		let sliderTracker =
			(playerRef.current.cache_.currentTime /
				playerRef.current.cache_.duration) *
			100;

		const timePlayed = deluxeTimeFormatter(
			playerRef.current.cache_.currentTime
		);
		setCurrentTime(timePlayed);
		const totalVideoTime = deluxeTimeFormatter(
			playerRef?.current?.cache_?.duration
		);
		setVideoTime(totalVideoTime);
		setSliderValue(sliderTracker);
	};

	/// functionality for pause, fast forward and rewind with keyboard ///
	const handleKeyDown = (e) => {
		let getCurrentTime = playerRef.current.cache_.currentTime;
		if (e.code === 'Space') {
			handlePlayPause();
		}
		if (e.code === 'ArrowRight') {
			playerRef.current.currentTime(getCurrentTime + 7);
		}
		if (e.code === 'ArrowLeft') {
			playerRef.current.currentTime(getCurrentTime - 7);
		}
	};

	/// stop playing video when leaving fullscreen on iOS units ///
	useEffect(() => {
		const player = videoRef.current;
		player.addEventListener('webkitendfullscreen', (e) => {
			toggleShowVideo(false);
		});
	}, [fullscreen]);

	return (
		<div
			data-vjs-player
			onMouseEnter={() => !iosUnit && setShowControls(true)}
			onMouseLeave={() => setShowControls(false)}
			onKeyDown={(e) => handleKeyDown(e)}
		>
			{iosUnit ? (
				<video
					playsInline
					controls
					autoPlay
					muted
					ref={videoRef}
					className="video-js vjs-big-play-centered"
				/>
			) : (
				<video
					ref={videoRef}
					className="video-js vjs-big-play-centered"
					onTimeUpdate={handleTimeUpdate}
					onClick={() => {
						handlePlayPause();
					}}
					onTouchEnd={() => (!iosUnit ? setShowControls(!showControls) : null)}
					onMouseMove={() => handleIdleCursor()}
				/>
			)}
			{showControls && (
				<>
					<div
						onMouseMove={() => clearTimeout(showControlsTimeout)}
						onMouseOver={() => setShowControls(true)}
						className="controls__parent"
					>
						{!staccsTVPlaying ? (
							<div className="slider__wrapper">
								<Slider
									style={{
										size: '0.7',
										width: '100%',
										color: '#ffc65a',
										zIndex: '1000',
									}}
									value={sliderValue}
									onChange={(e) => changeSliderValue(e)}
									onMouseEnter={() => clearTimeout(showControlsTimeout)}
								/>
							</div>
						) : null}
						<div
							className="controls__wrapper"
							onMouseLeave={() => setShowVolume(false)}
						>
							<div className="play-pause-volume__wrapper">
								{showPlay ? (
									<button
										onClick={() => handlePlayPause()}
										style={{ cursor: 'pointer' }}
									>
										<PauseIcon />
									</button>
								) : (
									<button
										onClick={() => handlePlayPause()}
										style={{ cursor: 'pointer' }}
									>
										<PlayIcon />
									</button>
								)}
								<div className="volume__wrapper">
									<div
										onClick={() => handleMute()}
										onMouseEnter={() => setShowVolume(true)}
										style={{ cursor: 'pointer' }}
									>
										{videoMuted ? <VolumeMuted /> : <VolumeUp />}
									</div>
									<div
										style={{ width: '100px' }}
										className={showVolume ? 'display-volume' : 'hide-volume'}
									>
										{showVolume ? (
											<Slider
												defaultValue={videoVolume}
												style={{
													width: '100%',
													scale: '0.7',
													color: '#f7f7f7',
												}}
												aria-label="Volume"
												onChange={(e) => handleVolume(e)}
											/>
										) : null}
									</div>
								</div>
								<div
									style={{
										minWidth: '100px',
										display: 'flex',
										flexWrap: 'wrap',
									}}
								>
									{staccsTVPlaying ? 'LIVE' : `${currentTime}/${videoTime}`}
								</div>
							</div>
							<div
								onClick={() => handleFullscreen()}
								style={{ cursor: 'pointer' }}
							>
								{!fullscreen ? <FullscreenIcon /> : <FullscreenExit />}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default VideoPlayer;
