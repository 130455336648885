const Privacy: React.FC = (): JSX.Element => {
	return (
		<section
			// className="section bg-light privacy"
			style={{
				paddingTop: '120px',
				paddingBottom: '80px',
				color: 'black',
				maxWidth: '600px',
				// fontSize: '12px',
				margin: 'auto',
				textAlign: 'left',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<h1 style={{ textAlign: 'center', color: '#121212', fontSize: '24px' }}>
				Privacy Statement
			</h1>

			<p>
				<strong>Last updated:</strong> 21 March 2023
			</p>
			<p>
				<strong>Name: </strong>World of Staccs AB
			</p>
			<p>
				<strong>Registration number: </strong>559135-5473
			</p>
			<p>
				<strong>Address:</strong> Livdjursgatan 4, 121 62 Johanneshov
				(Stockholm), Sweden
			</p>
			<p>
				<strong>Support email: </strong>
				<a href="mailto:support@staccs.com" target="_blank" rel="noopener">
					support@staccs.com
				</a>
			</p>
			<p>
				<strong>
					This Privacy Statement explains our practices, including your choices,
					regarding the collection, use, and disclosure of certain information,
					including your personal information in connection with the Staccs
					service.
				</strong>
			</p>
			<h2>Contacting Us</h2>
			<p>
				If you have general questions about your account or for questions
				specifically about this Privacy Statement, or our use of your personal
				information, cookies or similar technologies, please contact us by email
				at{' '}
				<a href="mailto:support@staccs.com" target="_blank" rel="noopener">
					support@staccs.com
				</a>
				.
			</p>
			<p>
				The data controller of your personal information is World of Staccs AB.
				Please note that if you contact us to assist you, for your safety and
				ours we may need to authenticate your identity before fulfilling your
				request.
			</p>
			<h2>Collection of Information</h2>
			<h3>Information you provide to us</h3>
			<p>We collect information you provide to us which includes:</p>
			<ul>
				<li>your name;&nbsp;</li>
				<li>email address;&nbsp;</li>
				<li>address or postal code;&nbsp;</li>
				<li>payment method(s), and;&nbsp;</li>
				<li>telephone number.&nbsp;</li>
			</ul>
			<p>
				We collect this information in a number of ways, including when you
				enter it while using our service, interact with our customer service, or
				participate in surveys or marketing promotions, and information when you
				choose to provide ratings, taste preferences, account settings
				(including preferences set in the "Account" section of our website), or
				otherwise provide information to us through our service or elsewhere.
			</p>
			<h3>Information we collect automatically</h3>
			<p>
				We collect information about you and your use of our service, your
				interactions with us and our advertising, as well as information
				regarding your network, network devices, and your computer or other
				Staccs capable devices you might use to access our service (such as
				gaming systems, smart TVs, mobile devices, set top boxes, and other
				streaming media devices). This information includes:
			</p>
			<ul>
				<li>
					your activity on the Staccs service, such as title selections, shows
					you have watched, and search queries;
				</li>
				<li>
					your interactions with our emails and texts, and with our messages
					through push and online messaging channels;
				</li>
				<li>
					details of your interactions with our customer service, such as the
					date, time and reason for contacting us, transcripts of any chat
					conversations, and if you call us, your phone number and call
					recordings;
				</li>
				<li>
					device IDs or other unique identifiers, including for your network
					devices, and devices that are Staccs capable on your Wi-Fi network;
				</li>
				<li>
					resettable device identifiers (also known as advertising identifiers),
					such as those on mobile devices, tablets, and streaming media devices
					that include such identifiers (see the "Cookies and Internet
					Advertising" section below for more information);
				</li>
				<li>
					device and software characteristics (such as type and configuration),
					connection information including type (wifi, cellular), statistics on
					page views, referring source (for example, referral URLs), IP address
					(which may tell us your general location), browser and standard web
					server log information;
				</li>
				<li>
					information collected via the use of cookies, web beacons and other
					technologies, including ad data (such as information on the
					availability and delivery of ads, the site URL, as well as the date
					and time). (See our "Cookies and Internet Advertising" section for
					more details.)
				</li>
				<li>Information from partners</li>
			</ul>
			<p>
				We collect information from other companies with whom you have a
				relationship (“Partners”). These Partners might include (depending on
				what services you use):&nbsp;
			</p>
			<ul>
				<li>
					your TV or internet service provider, or other streaming media device
					providers who make our service available on their device;&nbsp;
				</li>
				<li>
					mobile phone carriers or other companies who provide services to you
					and collect payment for the Staccs service for distribution to us or
					provide pre-paid promotions for the Staccs service, and;&nbsp;
				</li>
				<li>
					voice assistant platform providers who enable interaction with our
					service through voice commands.&nbsp;
				</li>
			</ul>
			<p>
				The information Partners provide us varies depending on the nature of
				the Partner services, and may include:
			</p>
			<ul>
				<li>
					search queries and commands applicable to Staccs that you make through
					Partner devices or voice assistant platforms;
				</li>
				<li>
					service activation information such as your email address or other
					contact information;
				</li>
				<li>
					IP addresses, device IDs or other unique identifiers, as well as
					associated pre-paid promotion, billing and user interface information,
					that support user authentication, the Staccs service registration
					experience, Partner payment processing, and the presentation of Staccs
					content to you through portions of the Partner user interface.
				</li>
			</ul>
			<h3>Information from other sources</h3>
			<p>
				We also obtain information from other sources. We protect this
				information according to the practices described in this Privacy
				Statement, plus any additional restrictions imposed by the source of the
				data. These sources vary over time, but could include:
			</p>
			<ul>
				<li>
					service providers that help us determine a location based on your IP
					address in order to customize our service and for other uses
					consistent with this Privacy Statement;
				</li>
				<li>
					security service providers who provide us with information to secure
					our systems, prevent fraud and help us protect the security of Staccs
					accounts;
				</li>
				<li>
					payment service providers who provide us with payment or balance
					information, or updates to that information, based on their
					relationship with you;
				</li>
				<li>
					online and offline data providers, from which we obtain aggregated
					demographic, interest based and online advertising related data;
				</li>
				<li>
					publicly-available sources such as publicly available posts on social
					media platforms and information available through public databases
					associating IP addresses with internet service providers (ISPs).
				</li>
			</ul>
			<h3>Use of Information</h3>
			<p>
				We use information to provide, analyze, administer, enhance and
				personalize our services and marketing efforts, to manage member
				referrals, to process your registration, your orders and your payments,
				and to communicate with you on these and other topics. For example, we
				use such information to:
			</p>
			<ul>
				<li>
					determine your general geographic location, provide localized content,
					provide you with customized and personalized viewing recommendations
					for movies and TV shows we think will be of interest to you, determine
					your ISP to support network troubleshooting for you (we also use
					aggregated ISP information for operational and business purposes), and
					help us quickly and efficiently respond to inquiries and requests;
				</li>
				<li>
					coordinate with Partners on making the Staccs service available to
					members and providing information to non members about the
					availability of the Staccs service, based on the specific relationship
					you have with the Partner;
				</li>
				<li>
					secure our systems, prevent fraud and help us protect the security of
					Staccs accounts;
				</li>
				<li>
					prevent, detect and investigate potentially prohibited or illegal
					activities, including fraud, and to enforce our terms (such as
					determining whether and for which Staccs signup offers you are
					eligible and determining whether a particular device is permitted to
					use the account consistent with our Terms of Use);
				</li>
				<li>
					analyze and understand our audience, improve our service (including
					our user interface experiences and service performance) and optimize
					content selection, recommendation algorithms and delivery;
				</li>
				<li>
					communicate with you concerning our service so that we can send you
					news about Staccs, details about new features and content available on
					Staccs, special offers, promotional announcements, consumer surveys,
					and to assist you with operational requests such as password reset
					requests. These communications may be by various methods, such as
					email, push notifications, text message, online messaging channels,
					and matched identifier communications (described below). Please see
					the "Your Choices" section of this Privacy Statement to learn how to
					set or change your communications preferences.
				</li>
			</ul>
			<h3>Our Legal Basis</h3>
			<p>
				Our legal basis for collecting and using the personal information
				described in this Privacy Statement will depend on the personal
				information concerned and the specific context in which we collect and
				use it.&nbsp;
			</p>
			<p>
				We will normally collect personal information from you where we need the
				personal information to perform a contract with you (for example, to
				provide our services to you), where the processing is in our legitimate
				interests and not overridden by your data protection interests or
				fundamental rights and freedoms (for example, our direct marketing
				activities in accordance with your preferences), or where we have your
				consent to do so (for example, for you to participate in certain
				consumer insights activities like specific surveys and focus
				groups).&nbsp;
			</p>
			<p>
				In some cases, we may also have a legal obligation to collect personal
				information from you or may otherwise need the personal information to
				protect your vital interests or those of another person (for example, to
				prevent payment fraud or confirm your identity). For questions about our
				use of your personal information (including legal bases and transfer
				mechanisms we rely on), cookies or similar technologies, please contact
				us by email at support@staccs.com.
			</p>
			<h3>Disclosure of Information</h3>
			<p>
				We disclose your information for certain purposes and to third parties,
				as described below:
			</p>
			<h3>Service Providers</h3>
			<p>
				We use other companies, agents or contractors ("Service Providers") to
				perform services on our behalf or to assist us with the provision of
				services to you.&nbsp;
			</p>
			<p>
				For example, we engage Service Providers to provide marketing,
				advertising, communications, security, infrastructure and IT services,
				to customize, personalize and optimize our service, to provide bank
				account or balance information, to process credit card transactions or
				other payment methods, to provide customer service, to analyze and
				enhance data (including data about users' interactions with our
				service), and to process and administer consumer surveys. In the course
				of providing such services, these Service Providers may have access to
				your personal or other information. We do not authorize them to use or
				disclose your personal information except in connection with providing
				their services (which includes maintaining and improving their
				services).
			</p>
			<h3>Partners</h3>
			<p>
				As described above, you may have a relationship with one or more of our
				Partners, in which case we may share certain information with them in
				order to coordinate with them on providing the Staccs service to members
				and providing information about the availability of the Staccs service.
				For example, depending on what Partner services you use, we may share
				information:
			</p>
			<ul>
				<li>
					in order to facilitate Partner pre-paid promotions or collection of
					payment for the Staccs service for distribution to us;
				</li>
				<li>
					with Partners who operate voice assistant platforms that allow you to
					interact with our service using voice commands;
				</li>
				<li>
					so that content and features available in the Staccs service can be
					suggested to you in the Partner’s user interface. For members, these
					suggestions are part of the Staccs service and may include customized
					and personalized viewing recommendations.
				</li>
			</ul>
			<h3>Promotional offers</h3>
			<p>
				We may offer joint promotions or programs that, in order for your
				participation, will require us to share your information with third
				parties. In fulfilling these types of promotions, we may share your name
				and other information in connection with fulfilling the incentive.
				Please note that these third parties are responsible for their own
				privacy practices.
			</p>
			<h3>Protection of Staccs and others</h3>
			<p>
				Staccs and its Service Providers may disclose and otherwise use your
				personal and other information where we or they reasonably believe such
				disclosure is needed to (a) satisfy any applicable law, regulation,
				legal process, or governmental request, (b) enforce applicable terms of
				use, including investigation of potential violations thereof, (c)
				detect, prevent, or otherwise address illegal or suspected illegal
				activities (including payment fraud), security or technical issues, or
				(d) protect against harm to the rights, property or safety of Staccs,
				its users or the public, as required or permitted by law.
			</p>
			<h3>Business transfers</h3>
			<p>
				In connection with any reorganization, restructuring, merger or sale, or
				other transfer of assets, we will transfer information, including
				personal information, provided that the receiving party agrees to
				respect your personal information in a manner that is consistent with
				our Privacy Statement.
			</p>
			<p>
				Whenever in the course of sharing information we transfer personal
				information to countries outside of the European Economic Area and other
				regions with comprehensive data protection laws, we will ensure that the
				information is transferred in accordance with this Privacy Statement and
				as permitted by the applicable laws on data protection.
			</p>
			<p>
				You may also choose to disclose your information in the following ways:
			</p>
			<ul>
				<li>
					certain portions of our service may contain a tool which gives you the
					option to share information by email, text message and social or other
					sharing applications, using the clients and applications on your smart
					device;
				</li>
				<li>
					social plugins and similar technologies allow you to share
					information.
				</li>
			</ul>
			<p>
				Social plugins and social applications are operated by the social
				networks themselves, and are subject to their terms of use and privacy
				policies.
			</p>
			<p>Access to Account and Profiles</p>
			<h3>“Ease of access”-function:</h3>
			<p>
				For ease of access to your account, when you log in to the website or
				mobile apps, your login credentials are saved. This function uses
				technology that provides you with direct account access to the Staccs
				service without requiring reentry of any password or other user
				identification when your browser revisits the service.&nbsp;
			</p>
			<h3>Giving others access to your account</h3>
			<p>
				If you share or otherwise allow others to have access to your account,
				they will be able to see shows you have watched, ratings, and account
				information (including your email address or other information in the
				"Account" area of our website). This remains true even if you use our
				profiles feature.
			</p>
			<h3>Profiles</h3>
			<p>
				Profiles allow users to have a distinct, personalized Staccs experience,
				built around the content of interest to them, as well as separate watch
				histories. Please note that profiles are available to everyone who uses
				your Staccs account, so that anyone with access to your Staccs account
				can navigate to and use, edit or delete profiles. You should explain
				this to others with access to your account, and if you do not wish them
				to use or change your profile, be sure to let them know. Profile users
				may be offered the opportunity to add an email address, phone number or
				other information to the profile and will be provided with notice of
				collection and use at the time such information is requested.
			</p>
			<h3>Removing device access to your Staccs account</h3>
			<p>
				To remove access to your Staccs account from your devices: (a) visit the
				"Account" section of our website, choose "Sign out," or (b) delete your
				Staccs settings from your device (steps vary by device and the option is
				not available on all devices). Where possible, users of public or shared
				devices should log out at the completion of each visit. If you sell or
				return a computer or Staccs ready device, you should log out and
				deactivate the device before doing so. If you do not maintain the
				security of your password or device, or fail to log out or deactivate
				your device, subsequent users may be able to access your account,
				including your personal information.
			</p>
			<h3>Your Choices</h3>
			<h4>Email and Text Messages</h4>
			<p>
				If you no longer want to receive certain communications from us via
				email or text message, simply contact us via support@staccs.com.
				Alternatively, click the "unsubscribe" link in the email or reply STOP
				(or as otherwise instructed) to the text message. Please note that you
				cannot unsubscribe from service-related correspondence from us, such as
				messages relating to your account transactions.
			</p>
			<h4>Push Notifications</h4>
			<p>
				We offer push notifications on certain web browsers and mobile apps. If
				you agree to receive those notifications and subsequently decide you no
				longer wish to receive these notifications, you may use your browser or
				device settings to turn them off.
			</p>
			<h4>Interest-Based Ads</h4>
			<p>
				Interest-based ads are online ads that are tailored to your likely
				interests based on your use of various apps and websites across the
				Internet. If you are using a browser, then cookies and web beacons can
				be used to collect information to help determine your likely interests.
				If you are using a mobile device, tablet, or streaming media device that
				includes a resettable device identifier, then that identifier can be
				used to help determine your likely interests. For your choices about
				interest-based ads from Staccs, please see the "Cookies and Internet
				Advertising" section (below).
			</p>
			<h4>Matched Identifier Communications</h4>
			<p>
				Some third party sites and apps allow us to reach our users with online
				promotions about our titles and services by sending a privacy protective
				identifier to the third party. A privacy protective identifier means we
				convert the original information (such as an email address or phone
				number) into a value to keep the original information from being
				revealed. The third party compares the privacy protective identifier to
				identifiers in its database and there will be a match only if you have
				used the same identifier (such as an email address) with Staccs and the
				third party. If there is a match, Staccs can then choose whether or not
				to send a given promotional communication to you on that third party
				site or app, and can optimize and better measure the effectiveness of
				online advertising.&nbsp;
			</p>
			<h4>Your Information and Rights</h4>
			<p>
				You can request access to your personal information, or correct or
				update out-of-date or inaccurate personal information we hold about you.
				You may also request that we delete personal information that we hold
				about you. We keep your personal data for the duration of your account.
				You may contact us at any time requesting the information to be deleted.
				We will then proceed to delete your information from our databases.
			</p>
			<p>
				You may visit the "Account" portion of our website, where you have the
				ability to access and update information about your account, including
				your contact information, your Staccs payment information, and various
				related information about your account (such as the content you have
				viewed and rated). You must be signed in to access the "Account"
				section.
			</p>
			<p>
				For other requests, or if you have a question regarding our privacy
				practices or would like more information about how to access your
				information, including our verification process, please contact us by
				email at support@staccs.com. We respond to all requests we receive from
				individuals wishing to exercise their data protection rights in
				accordance with applicable data protection laws. Please also see the
				"Your Choices" section of this Privacy Statement for additional choices
				regarding your information.
			</p>
			<p>
				You can object to processing of your personal information, ask us to
				restrict processing of your personal information or request portability
				of your personal information; if we have collected and processed your
				personal information with your consent, then you can withdraw your
				consent at any time; withdrawing your consent will not affect the
				lawfulness of any processing we conducted prior to your withdrawal, nor
				will it affect processing of your personal information conducted in
				reliance on lawful processing grounds other than consent; you have the
				right to complain to a data protection authority about our collection
				and use of your personal information. Our main establishment within the
				European Union is in Sweden. Please visit our online help centre at
				https://staccs.com/support for more information about these topics.
			</p>
			<p>
				We may retain information as required or permitted by applicable laws
				and regulations, including to honor your choices, for our billing or
				records purposes and to fulfill the purposes described in this Privacy
				Statement. We take reasonable measures to destroy or de-identify
				personal information in a secure manner when it is no longer required.
			</p>
			<h4>Security</h4>
			<p>
				We use reasonable administrative, logical, physical and managerial
				measures to safeguard your personal information against loss, theft and
				unauthorized access, use and modification. These measures are designed
				to provide a level of security appropriate to the risks of processing
				your personal information.
			</p>
			<h4>Other Websites, Platforms and Applications</h4>
			<p>
				The Staccs service may be provided through and/or utilize features (such
				as voice controls) operated by third party platforms, or contain links
				to sites operated by third parties whose policies regarding the handling
				of information may differ from ours. For example, you may be able to
				access the Staccs service through platforms such as gaming systems,
				smart TVs, mobile devices, set top boxes and a number of other Internet
				connected devices. These websites and platforms have separate and
				independent privacy or data policies, privacy statements, notices and
				terms of use, which we recommend you read carefully. In addition, you
				may encounter third party applications that interact with the Staccs
				service.
			</p>
			<h4>Children</h4>
			<p>
				You must be at least 18 years of age or older to subscribe to the Staccs
				service. Minors may only use the service with the involvement,
				supervision, and approval of a parent or legal guardian.
			</p>
			<h4>Changes to This Privacy Statement</h4>
			<p>
				We will update this Privacy Statement from time to time in response to
				changing legal, regulatory or operational requirements. We will provide
				notice of any such changes (including when they will take effect) in
				accordance with law. Your continued use of the Staccs service after any
				such updates take effect will constitute acknowledgement and (as
				applicable) acceptance of those changes. If you do not wish to
				acknowledge or accept any updates to this Privacy Statement, you may
				cancel your use of the Staccs service. To see when this Privacy
				Statement was last updated, please see the "Last Updated" section below.
			</p>
			<h4>Cookies and Internet Advertising</h4>
			<p>
				We and our Service Providers use cookies and other technologies (such as
				web beacons), as well as resettable device identifiers, for various
				reasons. We want you to be informed about our use of these technologies,
				so our Cookie Policy section explains the types of technologies we use,
				what they do, and your choices regarding their use.
			</p>
		</section>
	);
};

export default Privacy;
