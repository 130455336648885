import React, { useState, useContext, useEffect } from "react";
import { DialogContext } from "../Context/DialogContext";

const ActivateTV: React.FC = (): JSX.Element => {
  const [userCode, setUserCode] = useState<string>("");
  const accessToken = localStorage.getItem("access_token");
  const [message, setMessage] = useState<string>("");
  const { setView, toggleSignupDialogOpen } = useContext(DialogContext);

  useEffect(() => {
    toggleSignupDialogOpen(false);
    if (!accessToken) {
      toggleSignupDialogOpen(true);
      setView("SIGNIN");
    }
  }, []);

  const verifySignIn = (): void => {
    if (userCode.length === 5) {
      fetch(
        `https://dev_api.staccs.com/api/device/activate?userCode=${userCode}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setMessage("Login you in on your TV, please be patient.");
          } else {
            setMessage("Wrong code, please try again");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMessage("Wrong code, please try again");
    }
  };

  if (!accessToken) {
    return (
      <div
        style={{
          backgroundColor: '#121212',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      </div>
    )
  }

  return (
    <div className="activate">
      <h2 className="activate__heading">Enter your code to login on your TV</h2>
      <div className="activate__section">
        <input
          type="text"
          className="activate__input"
          onChange={(e) => {
            setUserCode(e.target.value);
          }}
        />
        <p className="activate-message__text">{message}</p>
        <button className="activate__btn" onClick={() => verifySignIn()}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default ActivateTV;
