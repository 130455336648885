import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

import backgroundImage from '../Assets/Images/gridnewland3.0.jpg';
import Loader from '../Components/Loader/Loader';
import parse from 'html-react-parser';
import { HeaderContext } from '../Context/HeaderContext';
// import logo from '../../../client/src/logo.svg';
import { api_url } from '../Constants/constants';
import { PlayScreen } from '../../src/Components/Library/LibraryContent/VideoPlayer/PlayScreen';
import OriginalsIcon from '../../src/Assets/Images/assets_icons_originals.png';
import { deluxeTimeFormatter } from '../Utils/helpers';
import SignupDialog from '../../src/Components/discovery/content/signup-dialog/SignupDialog';
import { DialogContext } from '../Context/DialogContext';
import { TempAuthContext } from '../Context/tempAuthContext';
import { LanguageContext } from '../Context/language';

const Partners: React.FC = (): JSX.Element => {
	const {
		setView,
		setSignupDialogOpen,
		setSubtitle,
		setPartnerTitle,
		setPartnerName,
	} = useContext(DialogContext);

	const { accessToken } = useContext(TempAuthContext);
	const { setHeaderDisplayed } = useContext(HeaderContext);
	const { country } = useContext(LanguageContext);

	const navigate = useNavigate();

	const [voucher, setVoucher] = useState<string>('');
	const [partnerId, setPartnerId] = useState<string>('');
	let { slug } = useParams<string>();
	const [voucherData, setVoucherData] = useState<any>({});
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [activePartner, setActivePartner] = useState<boolean>(true);
	const [discountCoupon, setDiscountCoupon] = useState<string>('');
	const [language, setLanguage] = useState<string>('se');
	const [trailer, setTrailer] = useState<any>();
	const [trailerHeading, setTrailerHeading] = useState<string>('');

	useEffect(() => {
		const getPartner = (): void => {
			fetch(`${api_url}/api/partners/${slug}`)
				.then((response) => response.json())
				.then((partner) => {
					console.log(partner);
					setTrailer(partner.trailer);
					setTrailerHeading(partner.trailerHeading);
					setPartnerName(partner.partnerName);
					setSubtitle(partner[language].subheadline);
					setPartnerTitle(partner[language].headline);
					setVoucherData(partner);
					setPartnerId(partner?.id);
					setActivePartner(partner?.active);
					if (partner?.prefilled) {
						setVoucher(partner.prefilledCode);
					}
					if (partner?.discountCoupon) {
						setDiscountCoupon(partner?.discountCoupon);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		};
		getPartner();
		setHeaderDisplayed('discover');
	}, [slug, language]);

	useEffect(() => {
		if (country === 'se') {
			setLanguage('se');
		} else {
			setLanguage('en');
		}
	}, [country]);

	useEffect(() => {
		const fetchArtistData = async () => {
			const response = await fetch(`${api_url}/api/artist-xp/nightwish`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			setLoading(false);
		};
		fetchArtistData();
	}, []);

	const onTrailerClickHandler = () => {
		setView('PARTNERCAMPAIGN');
		if (!accessToken) {
			setSignupDialogOpen(true);
		}
	};

	const submit = async (e: React.FormEvent): Promise<void> => {
		e.preventDefault();

		let hasActiveSub = false;
		if (accessToken) {
			const activeSubResponse = await fetch(
				`${api_url}/api/stripe/checkActiveSub`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json',
					},
				}
			);

			const { activeSub } = await activeSubResponse.json();
			hasActiveSub = activeSub;
		}

		let partnerVoucher = JSON.stringify({
			voucherCode: voucher.toLocaleLowerCase(),
			partnerId: partnerId,
		});

		fetch(`https://staccs.com/api/partners/checkvoucher`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: partnerVoucher,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.active === true) {
					// ändra till boolean i partner objekt
					if (discountCoupon) {
						const data = {
							discountPrice: response.priceId,
							discountCoupon: discountCoupon,
						};
						navigate(hasActiveSub ? '/upgrade' : '/register', {
							state: data,
						});
					} else {
						navigate(hasActiveSub ? '/upgrade' : '/register', {
							state: { ...response, ...voucherData },
						});
						return;
					}
				} else {
					setError('Kontrollera din kod');
				}
			})
			.catch((error) => {
				console.log(error);
				setError('Kontrollera din kod');
			});
	};

	if (loading) {
		return <Loader />;
	} else if (!partnerId) {
		return <Navigate to="/404" />;
	}
	return (
		<>
			<div
				className="partner"
				style={{
					position: 'relative',
					backgroundImage: `url(${
						voucherData.backgroundImage
							? voucherData.backgroundImage
							: backgroundImage
					})`,
				}}
			>
				<div className="partner__overlay"></div>

				<div style={{ position: 'relative', height: '100%', width: '100%' }}>
					<SignupDialog />

					<div className="content__container" style={{ height: '50%' }}>
						{voucherData?.logoImage ? (
							<img
								src={voucherData?.logoImage}
								className="partner__logo-image"
								alt="You rock!"
							/>
						) : null}
						{voucherData.staccsOriginal && (
							<div className="originals-icon__container">
								<img src={OriginalsIcon} alt="originals icon" />
							</div>
						)}
						<h1 className="hero__heading">{voucherData[language].headline}</h1>
						<h2 className="hero-sub__heading">
							{voucherData[language].subheadline}
						</h2>
						<div className="voucher__container" style={{ height: '100%' }}>
							<div className="voucher-container__child">
								<p className="voucher-container__text">
									{parse(`${voucherData[language]?.infoText}`)}
								</p>
							</div>
							<form
								className="voucher-container__form"
								method="post"
								onSubmit={(e) => submit(e)}
							>
								<input
									className="voucher-container__text-input"
									placeholder="Din personliga kod"
									value={
										voucherData?.prefilledCode
											? voucherData?.prefilledCode
											: null
									}
									onChange={(e) => setVoucher(e.target.value)}
									required
									hidden={voucherData?.prefilled ? true : false}
								/>

								{activePartner ? (
									<button className="voucher-container__submit-btn action-btn">
										{voucherData?.prefilled
											? voucherData[language].buttonText
											: country === 'se'
											? 'Verifiera kod'
											: 'Verify code'}
									</button>
								) : (
									<h3>This price is inactive</h3>
								)}
							</form>

							<div>
								{error && (
									<p className="voucher-container__error-msg"> {error}</p>
								)}
							</div>
						</div>
					</div>
					<div className="partner-gradient__container">
						{/* <div className="trailer__container"> */}
						{trailer && (
							<>
								<h1
									className="hero__heading"
									style={{
										padding: '0px',
										marginBottom: window.innerWidth > 766 ? '34px' : '8px',
										lineHeight: '59px',
									}}
								>
									{trailerHeading}
								</h1>
								<div
									className="partner-video__container"
									onClick={() => onTrailerClickHandler()}
								>
									<PlayScreen
										thumbnail={trailer.graphics.landscape}
										titleId={trailer._id}
										HLS={trailer.stream.hls}
										videoTitle={trailer.name}
										title={trailer}
									/>
								</div>
							</>
						)}
						{/* </div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Partners;
